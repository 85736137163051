import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import Comments from "../../assets/images/comments.svg";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Image, InputGroup, ListGroup, Modal, Offcanvas, Row } from "react-bootstrap";
import "./DashboardComments.css";
import commentButton from "../../assets/images/Send.svg";
import DeleteIcon from "../../assets/images/Delete.svg";
import editIcon from "../../assets/images/Edit.svg";
import PeopleImage from "../../assets/images/people.png";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import Loader from "../Loader/Loader";

const DashboardComments = ({ registerSignID }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState("");
  const [docCommentsIDs, setDocCommentsIDs] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [commentToDeleteIndex, setCommentToDeleteIndex] = useState(null);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [supportingDocIDs, setSupportingDocIDs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletingSupportingDocID, setDeletingSupportingDocID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [documentPath, setDocumentPath] = useState(null);
  const [isSupported, setIsSupported] = useState();
  const { user } = useUser();

  const signatoryEmail = "z.khalid@macquires.com";
  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  const token = sessionStorage.getItem("token");
  // const registerSignID = localStorage.getItem("registerSignID");
  const navigate = useNavigate();

  const handleComments = () => {
    setShowOffcanvas(true);
  };

  // comment section
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);

    if (e.key === "Enter") {
      if (!e.target.value) {
        setErrorMessage(t("uploadDocFlow.enterComment"));
        return;
      }
      setErrorMessage("");

      addComment(e);
    }
  };

  const addComment = async () => {
    if (!newComment) {
      setErrorMessage(t("uploadDocFlow.enterComment"));
      return;
    }

    setErrorMessage("");
    const requestBody = {
      Comments: newComment,
      AdhockUserID: "3c9a2b68-f820-4fd2-a32a-5eec2e5f00cd",
      CommentPostedBy: user.fullName,
      CreatedBy: user.email,
      MasterDocID: null,
      RegisterSignID: registerSignID,
    };

    setIsLoading(true);

    try {
      const response = await axios.post("/v1.0/api/CreateDocComments", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        // setComments([...comments, response.data.response])
        getComments();
        // setDocCommentsIDs([...docCommentsIDs, response.data.response]);
        setNewComment("");
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentAdded"));
      } else {
        setIsLoading(false);
        toast.error(t("uploadDocFlow.failedToAdd"));
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.failedToAdd"));
      }
    }
  };

  const toggleEdit = (index) => {
    console.log("index", index);
    setEditingIndex(index);
    setEditedText(comments[index].comments);
  };

  const saveEditedComment = async (id) => {
    console.log(id);
    const updatedText = editedText;

    const requestBody = {
      DocCommentsID: id,
      Comments: updatedText,
    };

    console.log(requestBody);
    setIsLoading(true);
    try {
      const response = await axios.put("/v1.0/api/UpdateDocComments", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 200) {
        const updatedComments = [...comments];
        const index = updatedComments.findIndex((comment) => comment.docCommentsID === id);

        if (index !== -1) {
          updatedComments[index].comments = updatedText;
          // updatedComments[index].edited = true;

          setComments(updatedComments);
          setEditingIndex(-1);
        }
        toast.success(t("uploadDocFlow.commentUpdate"));
        setIsLoading(false);
      } else {
        toast.error(t("uploadDocFlow.failedToUpdate"));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.failedToUpdate"));
      }
    }
  };

  const getComments = () => {
    setIsLoading(true);
    try {
      axios
        .get(`/v1.0/api/GetDocComments?RegisterSignID=${registerSignID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setComments(response.data.response);
            // setDocCommentsIDs(responseData.map((comment) => comment.docCommentsID));
          }
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  // comments delete confirmation modal
  const handleShowConfirmModal = (docCommentsID) => {
    setDeletingCommentId(docCommentsID);
    // setCommentToDeleteIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleDeleteComment = async (docCommentsID) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteDocComments",
        { DocCommentsID: docCommentsID },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        const updatedComments = [...comments];
        // updatedComments.splice(index, 1);
        // const updatedDocCommentsIDs = [...docCommentsIDs];
        // updatedDocCommentsIDs.splice(index, 1);

        setComments(updatedComments.filter((comment) => comment.docCommentsID !== docCommentsID));
        // setDocCommentsIDs(updatedDocCommentsIDs);
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentDeleted"));
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      } else {
        toast.error(t("uploadDocFlow.failedTodelete"));
      }
    } finally {
      setShowConfirmModal(false);
    }
  };

  return (
    <>
      {/* {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )} */}

      <div className='d-flex gap-2 align-items-center' onClick={() => handleComments()}>
        <img src={Comments} alt='iconloading' />
        <span>{t("Comments.Comments")}</span>
      </div>

      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement={document.documentElement.dir === "rtl" ? "start" : "end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("Comments.allComments")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='commentsOffcanvas'>
          <div className=''>
            <ListGroup className='comment-list overflow-y-scroll' style={{ height: "75vh" }}>
              {comments.map((comment, index) => (
                <ListGroup.Item key={comment.docCommentsID} className='comment-body'>
                  {editingIndex === index ? (
                    // Editing mode
                    <>
                      <Form.Control type='text' value={editedText} onChange={(e) => setEditedText(e.target.value)} className='comment-save-input' />
                      <Button variant='none' className='py-1 px-2  m-1 applyBtn' onClick={() => saveEditedComment(comment.docCommentsID)}>
                        {t("addComment.save")}
                      </Button>
                    </>
                  ) : (
                    // Viewing mode
                    <>
                      <div className='signature-comment-body'>
                        <div className='comment-header  mb-0 p-0 d-flex align-items-center justify-content-between'>
                          {comment.commentPostedBy || user.fullName}
                          {comment.createdBy === user.email ? (
                            // Not in editing mode
                            <div>
                              <Button variant='none' className='action-button px-1 py-0' onClick={() => toggleEdit(index)}>
                                <img src={editIcon} alt='edit' />
                              </Button>
                              <Button variant='none' className='action-button px-1 py-0' onClick={() => handleShowConfirmModal(comment.docCommentsID)}>
                                <img src={DeleteIcon} alt='delete' />
                              </Button>
                            </div>
                          ) : // In editing mode
                          null}
                        </div>
                        <div className='comment-text text-start m-0'>{comment.comments}</div>
                        <small className='timestamp text-start'>
                          {comment.createdDate}
                          <span className='mx-1'></span>
                          {comment.createdTime}
                          {comment.modifiedOn !== null && <span className='edited-label mx-2 fw-normal'>{t("addComment.edited")}</span>}
                        </small>
                      </div>
                    </>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
              <Modal.Header closeButton>
                <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteComment")}</Modal.Body>
              <Modal.Footer>
                <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                  {t("uploadDocFlow.cancel")}
                </Button>
                <Button variant='none' className='applyBtn' onClick={() => handleDeleteComment(deletingCommentId)}>
                  {t("uploadDocFlow.delete")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
            <InputGroup className='new-comment'>
              <Form.Control type='text' placeholder={t("Comments.addCommentHere")} value={newComment} onChange={handleCommentChange} onKeyPress={handleCommentChange} className='comment-input py-2' />
            </InputGroup>
            <Button onClick={addComment} className='comment-button ms-1 py-2'>
              <img src={commentButton} alt='send button' />
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DashboardComments;
