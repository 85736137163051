import React, { useEffect, useState } from 'react'
import { ResizableBox } from 'react-resizable';
import "react-resizable/css/styles.css";

const ResizableButtons = ({ children, button, onUpdateControlsLocation, signatoryColors, setResizing, selectedImage, ...props }) => {

    const [imgWidth, setImgWidth] = useState(0);
    const [imgHeight, setImgHeight] = useState(0);

    useEffect(() => {
        let img = new Image();
        img.onload = function () {
            setImgWidth(this.naturalWidth);
            setImgHeight(this.naturalHeight);
        };

        img.src = selectedImage;
    }, [selectedImage]);


    const handleButtonResizeStop = (controlLocationID, controlIdentifier, text, x, y, width, height, font, size, color) => {

        const LocationX = x + width;
        const LocationY = y + height;

        const maxLocationX = imgWidth;
        const maxLocationY = imgHeight;

        if (LocationX > maxLocationX || LocationY > maxLocationY) {
            // console.log("outside limit", x, y);
            // console.log("X", LocationX, maxLocationX);
            // console.log("Y", LocationY, maxLocationY);

            onUpdateControlsLocation(controlLocationID, controlIdentifier, text, x, y, button.height, button.width, font, size, color);

        }
        else {
            onUpdateControlsLocation(controlLocationID, controlIdentifier, text, x, y, height, width, font, size, color);
        }

    };

    return (
        <ResizableBox
            className='field-button'
            width={button && button.width}
            height={button && button.height}

            onResizeStart={() => setResizing(true)}
            onResizeStop={(e, data) => {
                setResizing(false);
                handleButtonResizeStop(button.controlLocationID, button.controlIdentifier, button.textEditor, button.position.x, button.position.y, data.size.width, data.size.height, button.fontStyle, button.fontSize, button.fontColor);
            }}
            draggableOpts={{}}
            minConstraints={[110, 40]}
            maxConstraints={[300, 300]}
            style={{
                border: button.label === "QR Code" ? "none" : `2px dotted ${signatoryColors[button.selectedSignatoryID]}`,
                cursor: "grab",
                position: button.label === "Text" ? "relative" : "",
                fontFamily: button.fontStyle || "",
                color: button.fontColor || "black",
                fontSize: button.fontSize || "",
                // userSelect: "none",
            }}
            title={button.email + "    " + button.name}
            {...props}
        >
            {children}
        </ResizableBox>
    );
};

export default ResizableButtons;
