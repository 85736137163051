import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import "./AuditTrail.css";
import logo from "../../assets/images/Web.svg";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import noDocImg from "../../assets/images/NoDocuments (1).png";

import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader/Loader";

export default function AuditTrail({ url }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [auth, setAuth] = useState();
  const [isLoading, setIsLoading] = useState();

  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");

  const idIndex = parts.indexOf("id");

  useEffect(() => {
    if (idIndex !== -1 && idIndex < parts.length - 1) {
      const pathAfterId = parts.slice(idIndex + 1).join("/");
      setAuth(pathAfterId);
      getAuditTrail(pathAfterId);
    }
  }, []);

  const getAuditTrail = async (pathAfterId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          SignAuth: pathAfterId,
        },
      });

      setData(response.data.response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };



  const columns = [
    { label: t("auditTrailScreen.signatoryName"), key: "signatoryName" },
    { label: t("auditTrailScreen.Status"), key: "status" },
    { label: t("auditTrailScreen.date"), key: "dateTime" },
    { label: "Description ", key: "message" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  return (
    <Grid className=' p-3 bg-white'>
      <div className='d-flex align-items-center  justify-content-between mb-3'>
        <img src={logo} alt='logo' style={{ height: "40px" }} />
        <h4>{t("auditTrailScreen.auditTrail")}</h4>
      </div>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='p-3 auditTrail' >
        <TableContainer component={Paper} style={{ height: "max-content" }} >
          <Table style={{ border: "none" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.label} style={{ backgroundColor: "#F0F3F4" }} className='TableHeaderCell'>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {data.length === 0 && (
                <TableRow>
                  <td colSpan='6' className='p-5 text-center'>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <img alt='' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                      {t("dashboardCardStatuses.notDocsFound")}
                    </div>
                  </td>
                </TableRow>
              )}

              {data.map((row, index) => (
                <TableRow key={index}>
                  {/* Table Cells */}

                  <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                    {row.signatoryName}
                  </TableCell>
                  <TableCell className='TableCell'>{row.status}</TableCell>
                  <TableCell className='TableCell'>{row.dateTime ? formatDate(row.dateTime) : ""}</TableCell>
                  <TableCell className='TableCell'>{row.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
}
