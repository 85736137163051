// import React from "react";
// import { FaFileSignature } from "react-icons/fa6";
// import { BsFillCalendarDateFill } from "react-icons/bs";
// import { BiSolidUserRectangle } from "react-icons/bi";
// import { MdEmail, MdOutlineTextFields } from "react-icons/md";
// import { FaStamp } from "react-icons/fa";
// import { PiTextTFill } from "react-icons/pi";
// import { useDrag } from "react-dnd";
// import { IoQrCodeSharp } from "react-icons/io5";
// import { TbNumbers } from "react-icons/tb";
// import { HiBuildingOffice } from "react-icons/hi2";

// const DraggableButton = ({
//   icon,
//   label,
//   data,
//   selectedColorIndex,
//   signatoryColors,
//   selectedSignatoryID,
//   controlID,
//   MasterDocPagesId,
//   index,
//   signatureType,
//   onButtonClick,
// }) => {
//   const iconMapping = {
//     FaFileSignature: FaFileSignature,
//     PiTextTFill: PiTextTFill,
//     BiSolidUserRectangle: BiSolidUserRectangle,
//     GrOrganization: HiBuildingOffice,
//     MdEmail: MdEmail,
//     BsFillCalendarDateFill: BsFillCalendarDateFill,
//     FaStamp: FaStamp,
//     MdOutlineTextFields: MdOutlineTextFields,
//     IoQrCodeSharp: IoQrCodeSharp,
//     TbNumbers: TbNumbers
//   };

//   const currentSignatoryType =
//     selectedSignatoryID && signatureType[selectedSignatoryID];
//   const isSignatureButton = icon === "FaFileSignature";
//   const isStampButton = icon === "FaStamp";
//   const isInitialsButton = icon === "PiTextTFill";
//   const isViewOnlySignatory =
//     currentSignatoryType === "View Only" && (isSignatureButton || isStampButton || isInitialsButton);
//   // console.log(currentSignatoryType);

//   const [, ref] = useDrag({
//     type: "BUTTON",
//     item: {
//       label,
//       data,
//       icon,
//       selectedColorIndex,
//       selectedSignatoryID,
//       controlID,
//       MasterDocPagesId,
//       index,
//     },
//     canDrag: !isViewOnlySignatory,
//   });

//   const IconComponent = iconMapping[icon];

//   const backgroundColor = isViewOnlySignatory
//     ? "#CCCCCC"
//     : signatoryColors[selectedSignatoryID] || "defaultColor";

//   const cursor = isViewOnlySignatory ? "not-allowed" : "pointer";

//   // const handleDraggbleButtonClick = () => {
//   //   console.log("hello")
//   // }

//   return (
//     <div
//       ref={ref}
//       className={`draggable-button ${isViewOnlySignatory ? "view-only" : ""}`}
//       // title={`${isViewOnlySignatory
//       //   ? "Can't drag because signatory has a View Only option"
//       //   : "Hold and drag to place on desire position"
//       //   }`}
//       title={label}
//       style={{
//         cursor,
//         touchAction: "none"
//       }}
//       draggable
//       onClick={() => onButtonClick(label,
//         icon,
//         // selectedColorIndex,
//         // selectedSignatoryID,
//         controlID,
//         // MasterDocPagesId,
//         index,
//         true
//       )}
//     >
//       <span
//         style={{
//           backgroundColor,
//         }}
//         className="icon-background"
//       >
//         {IconComponent && <IconComponent />}
//       </span>
//       {label}
//     </div>

//   );
// };
// export default DraggableButton;


import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { FaFileSignature } from "react-icons/fa6";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { BiSolidUserRectangle } from "react-icons/bi";
import { MdEmail, MdOutlineTextFields } from "react-icons/md";
import { FaStamp } from "react-icons/fa";
import { PiTextTFill } from "react-icons/pi";
import { IoQrCodeSharp } from "react-icons/io5";
import { TbNumbers } from "react-icons/tb";
import { HiBuildingOffice } from "react-icons/hi2";

const DraggableButton = ({
  icon,
  label,
  data,
  signatoryColors,
  selectedSignatoryID,
  controlID,
  MasterDocPagesId,
  index,
  signatureType,
  onButtonClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconMapping = {
    FaFileSignature: FaFileSignature,
    PiTextTFill: PiTextTFill,
    BiSolidUserRectangle: BiSolidUserRectangle,
    GrOrganization: HiBuildingOffice,
    MdEmail: MdEmail,
    BsFillCalendarDateFill: BsFillCalendarDateFill,
    FaStamp: FaStamp,
    MdOutlineTextFields: MdOutlineTextFields,
    IoQrCodeSharp: IoQrCodeSharp,
    TbNumbers: TbNumbers,
  };

  const colorMapping = {
    '#00B2B0': '#00807E',
    '#9F467B': '#6E3055',
    '#E8AC00': '#B28400',
    '#E84600': '#B23600',
    '#29C785': '#219C68',
    '#6F29C7': '#4E1D8C',
    '#C729B7': '#941F88',
    '#E11919': '#AA1313',
    '#84B300': '#577500',
  };

  const currentSignatoryType =
    selectedSignatoryID && signatureType[selectedSignatoryID];
  const isSignatureButton = icon === "FaFileSignature";
  const isStampButton = icon === "FaStamp";
  const isInitialsButton = icon === "PiTextTFill";
  const isViewOnlySignatory =
    currentSignatoryType === "View Only" && (isSignatureButton || isStampButton || isInitialsButton);

  const [, ref] = useDrag({
    type: "BUTTON",
    item: {
      label,
      data,
      icon,
      selectedSignatoryID,
      controlID,
      MasterDocPagesId,
      index,
    },
    canDrag: !isViewOnlySignatory,
  });

  const IconComponent = iconMapping[icon];
  const originalColor = signatoryColors[selectedSignatoryID] || "defaultColor";
  const hoverColor = colorMapping[originalColor];

  const backgroundColor = isViewOnlySignatory
    ? "#CCCCCC"
    : isHovered
      ? hoverColor
      : originalColor;

  const cursor = isViewOnlySignatory ? "not-allowed" : "pointer";

  return (
    <div
      ref={ref}
      className={`draggable-button ${isViewOnlySignatory ? "view-only" : ""}`}
      title={label}
      style={{
        cursor,
        touchAction: "none",
        // backgroundColor,
      }}
      draggable
      onClick={() => onButtonClick(
        label,
        icon,
        controlID,
        index,
        true
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span
        style={{
          backgroundColor,
        }}
        className="icon-background"
      >
        {IconComponent && <IconComponent />}
      </span>
      {label}
    </div>
  );
};

export default DraggableButton;
