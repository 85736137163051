import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "../../Api/api"

const PasswordModal = ({ show, closeModal, auth, signatoryID, signatoryName, passwordProtected, defaultMasterDocID }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [values, setValues] = useState(["", "", "", ""]);
    const [focusedIndex, setFocusedIndex] = useState(0);
    console.log(passwordProtected, signatoryID, signatoryName,);

    const handleChange = (index, value) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);

        if (value === "" && index > 0) {
            setFocusedIndex(index - 1);
            focusInput(index - 1);
        } else if (index < 3 && value !== "") {
            setFocusedIndex(index + 1);
            focusInput(index + 1);
        }
    };

    const focusInput = (index) => {
        const input = document.getElementsByName(`otp${index}`)[0];
        if (input) {
            input.focus();
        }
    };

    const handleKeyPress = (event, index) => {
        if (event.key === "Enter") {
            event.preventDefault();
            index === 3 ? handleSubmitPassword(event) : setFocusedIndex(index + 1);
        } else if (event.key === "Backspace") {
            event.preventDefault();
            index > 0 && handleBackspace(index);
        } else if (event.key === "Delete" && index < 3) {
            event.preventDefault();
            setFocusedIndex(index + 1);
        }
    };

    const handleBackspace = (index) => {
        setValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index - 1] = "";
            return newValues;
        });

        setFocusedIndex(index - 1);
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData("text/plain");
        const pasteValues = pasteData.match(/\d/g) || [];
        const newValues = Array(4).fill("");

        pasteValues.slice(0, 4).forEach((value, index) => {
            newValues[index] = value;
        });

        setValues(newValues);
        setFocusedIndex(0);
    };

    const handleSubmitPassword = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const enteredOTP = values.join("");


        try {
            // if (passwordProtected === "Document") {
            //     const body = {
            //         MasterDocID: defaultMasterDocID,
            //         PasswordIsHere: enteredOTP
            //     }

            //     //console.log('file pswd', body);
            //     const response = await axios.post(
            //         "/v1.0/api/CheckMasterDocPassword",
            //         body,
            //         {
            //             headers: {
            //                 SignAuth: auth,
            //             },
            //         }
            //     );
            //     if (response.data.statusCode === "1") {
            //         closeModal();
            //         // toast.success("password Succesful");
            //     }
            //     if (response.data.statusCode === "7") {
            //         toast.error("Oops! Password is not correct. Try again");
            //         setIsLoading(false)
            //     }

            //     try {
            //         const response = await axios.post(
            //             "/v1.0/api/CreateSignActivityVerification",
            //             {
            //                 Status: "Password Entered ",
            //                 Message: `${signatoryName} entered password `,
            //             },
            //             {
            //                 headers: {
            //                     SignAuth: auth,
            //                 },
            //             }
            //         );
            //     } catch (error) { }
            // }

            if (passwordProtected === "Signatory") {
                const body = {
                    // SignatoriesID: signatoryID,
                    Password: enteredOTP
                };

                console.log('sig pswd', body);
                const response = await axios.post(
                    "/v1.0/api/CheckSignatoryPassword",
                    body,
                    {
                        headers: {
                            SignAuth: auth,
                        },
                    }
                );
                if (response.data.statusCode === "1") {
                    closeModal();
                    // toast.success("password Succesful");
                }
                if (response.data.statusCode === "7") {
                    toast.error("Oops! Password is not correct. Try again");
                    setIsLoading(false)
                }

                try {
                    const response = await axios.post(
                        "/v1.0/api/CreateSignActivityVerification",
                        {
                            Status: "Password Entered ",
                            Message: `${signatoryName} entered password `,
                        },
                        {
                            headers: {
                                SignAuth: auth,
                            },
                        }
                    );
                } catch (error) { }
            }



        } catch (error) {
            toast.error("Something wrong. Try later");
        } finally {
            setIsLoading(false);
        }
    }

    // useEffect(() => {
    //     if (show) {
    //         document.body.classList.add('blur-background');
    //     } else {
    //         document.body.classList.remove('blur-background');
    //     }
    //     return () => {
    //         document.body.classList.remove('blur-background');
    //     };
    // }, [show]);

    return (
        <>
            {isLoading && (
                <div className="loader-overlay">
                    <div className="loader"></div>
                </div>
            )}

            <Modal
                show={show}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                dialogClassName="custom-modal-width"
            // style={{ zIndex: '1000' }}
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title> Password Required</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-3 py-2'>
                    <p>This document is password protected. Please enter password to view the document</p>
                    <div className='d-flex justify-content-center'>
                        {values.map((value, index) => (
                            <input
                                key={index}
                                name={`otp${index}`}
                                type='text'
                                autoComplete='off'
                                className='otpInput'
                                value={value}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyPress={(e) => handleKeyPress(e, index)}
                                onPaste={handlePaste}
                                autoFocus={index === 0}
                                tabIndex={index + 1}
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    margin: "5px",
                                    textAlign: "center",
                                    border: `1px solid ${focusedIndex === index ? "#0C9AD7" : "#C4CED4"}`,
                                }}
                            />
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer closeButton={false}>
                    <Button onClick={handleSubmitPassword}>Verify</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PasswordModal