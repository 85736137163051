import { useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import searchIcon from '../assets/images/Search.svg';
import clearIcon from '../assets/images/close-circle.svg';


function SearchControls({ t, searchTerm, setSearchTerm, searchClicked, setSearchClicked }) {

    const handleSearchClick = () => {
        setSearchClicked(true);
    };

    const handleClearClick = () => {
        setSearchTerm('');
        setSearchClicked(false);
    };

    const handleInputBlur = () => {
        if (searchTerm.trim() === '') {
            setSearchClicked(false);
        }
    };
    
    const handleSearch = () => {
        // console.log('Searching for:', searchTerm);
        setSearchTerm('');
        setSearchClicked(false);
    };

    return (
        <div className='d-flex align-items-center ' style={{
            // borderRadius: "5px",
            borderRight: " 1px solid var(--Grey-300, #C4CED4)",
            background: "var(--Background, #FFF)"
        }}>
            <InputGroup>
                <img src={searchIcon} alt='search' onClick={handleSearchClick} className='mx-3' style={{cursor: "pointer"}} />
                {searchClicked && (
                    <>
                        <Form.Control
                            placeholder={t("uploadDocFlow.searchFields")}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            // onKeyDown={(e) => {
                            //     if (e.key === 'Enter') {
                            //         handleSearch();
                            //     }
                            // }}
                            onBlur={handleInputBlur}
                            style={{ border: "none" }}
                        />
                        <Button onClick={handleClearClick} variant='none' style={{ border: "none" }}>
                            <img src={clearIcon} alt='clear' />
                        </Button>
                    </>
                )}
            </InputGroup>
        </div>
    );
}


export default SearchControls