// import React from "react";
// import { Outlet } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import MenuNavbar from "../Navbar/MenuandNav";
// import DashboardCard from "../DashboardStatuses/DashboardCard";
// import Sidebar from "../Navbar/Sidebar";
// import Header from "../Navbar/Header";

// const Layout = () => {
//   const location = useLocation();

//   const showDashboardCard =
//     // location.pathname.includes("/home") ||
//     location.pathname.includes("/PendingWithMe") ||
//     location.pathname.includes("/PendingWithOthers") ||
//     location.pathname.includes("/SharedwithMe") ||
//     location.pathname.includes("/Sharedwithothers") ||
//     location.pathname.includes("/Drafts") ||
//     location.pathname.includes("/CompleteStatus") ||
//     location.pathname.includes("/InActive") ||
//     location.pathname.includes("/Declined");

//   const hideSidebar = location.pathname.includes("/uploadDoc") || location.pathname.includes("/createTemplates");

//   return (
//     <>
//       {/* <MenuNavbar /> */}
//       {hideSidebar && !showDashboardCard ? (
//         <>
//           <Header />
//           <Outlet />
//         </>
//       ) : (
//         <Sidebar>
//           <Header />
//           {showDashboardCard && <DashboardCard />}
//           <Outlet />
//         </Sidebar>
//       )}
//     </>
//   );
// };

// export default Layout;

import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DashboardCard from "../DashboardStatuses/DashboardCard";
import Sidebar from "../Navbar/Sidebar";
import Header from "../Navbar/Header";

const Layout = () => {
  const location = useLocation();

  const showDashboardCard = ["/PendingWithMe", "/PendingWithOthers", "/SharedwithMe", "/Sharedwithothers", "/Drafts", "/CompleteStatus", "/InActive", "/Declined", "/RecallDocs"].some((path) =>
    location.pathname.includes(path)
  );

  const hideSidebar = location.pathname.includes("/uploadDoc") || location.pathname.includes("/createTemplates") || location.pathname.includes("/editTemplates");

  return (
    <>
      {hideSidebar && !showDashboardCard ? (
        <div style={{ background: "rgb(238, 238, 238)" }}>
          <Header />
          <Outlet />
        </div>
      ) : (
        <Sidebar>
          <Header />
          {showDashboardCard && (
            <div className="container">
            <DashboardCard />
             </div>
          )}
          <Outlet />
        </Sidebar>
      )}
    </>
  );
};

export default Layout;
