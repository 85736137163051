import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend/dist";

// -------------------

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}



// Configure backend options for touch devices
const backendOptions = {
  enableMouseEvents: true, // Enable mouse events so the backend works with both touch and mouse
  delayTouchStart: 200,    // Delay in ms before recognizing the start of a touch as a drag
};

const backend = isTouchDevice() ? TouchBackend : HTML5Backend;



// -------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DndProvider backend={backend}>
    <App />
  </DndProvider>
);
reportWebVitals();
