import React, { useState, useEffect } from "react";
import "./FirstStep.css";
import { BiErrorCircle } from "react-icons/bi";
import { Button, Col, Container, Form, Modal, Row, Dropdown, Accordion } from "react-bootstrap";
import AddRecipient from "../AddRecipient/AddRecipient";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReferenceNumber from "./ReferenceNumber";
import Description from "./Description";
import SignatoryOrientation from "./SignatoryOrientation";
import Dropbox from "./Dropbox";
import UploadFromGoogleDrive from "./GoogleDrive";
import { useTranslation } from "react-i18next";
import { FcTemplate } from "react-icons/fc";
import { useUser } from "../../Context/UserContext";
import systemIcon from "../../assets/images/monitorUpload.svg";
import pdfFileIcon from "../../assets/images/pdfFile.svg";
import UploadDocIcon from "../../assets/images/Paper Plus.svg";
import ViewFileIcon from "../../assets/images/Show.png";
import DeleteIcon from "../../assets/images/Delete.svg";
import DocInfoIcon from "../../assets/images/DocumentInfo.svg";
import templateIcon from "../../assets/images/applyTemplates.svg";
import removeTemplate from "../../assets/images/removeTemplate.svg";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CustomToggle from "../../Components/CustomDots/CustomToggle";
import ListOfDocuments from "../../Integrations/OpenAI/ListOfDocuments";
import ManualDosList from "../../Integrations/ManuallyGenerate/ManualDosList";
import Loader from "../Loader/Loader";

const FirstStep = ({ setActiveStep, setCompletedSteps }) => {
  const { t } = useTranslation();

  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [recipientFormsValid, setRecipientFormsValid] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [recipientErrorMessage, setRecipientErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileToggleStates, setFileToggleStates] = useState([]);
  const [isTemplateApplied, setIsTemplateApplied] = useState([]);
  const [registerSignID, setRegisterSignID] = useState(null);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [fetchedData, setFetchedData] = useState({ primaryDocs: [] });
  const [masterDocIDs, setMasterDocIDs] = useState([]);
  const [docName, setDocName] = useState("");
  const [documentPages, setDocumentPages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingMasterDocID, setDeletingMasterDocID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [recipientFormsLength, setRecipientFormsLength] = useState(0);
  const [isSerial, setIsSerial] = useState(true);
  const navigate = useNavigate();
  const [templatesModalShow, setTemplatesModalShow] = useState(false);
  const [selectedFileSource, setSelectedFileSource] = useState("");
  const [showFileSelector, setShowFileSelector] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedMasterDocID, setSelectedMasterDocID] = useState();
  const [isOnlySigner, setIsOnlySigner] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [childRender, setChildRender] = useState(false);
  const [appliedTemplates, setAppliedTemplates] = useState({});
  const { user, userAccess, loading } = useUser("");
  const [totalPages, setTotalPages] = useState([]);
  const [templatesAppliedModal, setTemplatesAppliedModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [matchingTemplates, setMatchingTemplates] = useState();

  const storedRegisterSignID = localStorage.getItem("registerSignID");
  const token = sessionStorage.getItem("token");
  const [viewMode, setViewMode] = useState("allTemplates");

  const handleAllTemplates = () => {
    setViewMode("allTemplates");
  };

  const handleMatchingTemplates = () => {
    setViewMode("matchingTemplates");
  };

  const handleUploadButtonClick = () => {
    setShowFileSelector(!showFileSelector);
  };

  const handleFileSourceChange = (event) => {
    setSelectedFileSource(event.target.value);
  };

  const handleDropboxFileSelected = (fileUrl) => {
    setSelectedFileUrl(fileUrl);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetRegisterSignData?RegisterSignID=${storedRegisterSignID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        setIsLoading(false);


        setFetchedData(response.data.response);
        setIsOnlySigner(response.data.response.isOnlySigner);

        const recipientFormsLength = response.data.response.signatories.length;
        // console.log("length", recipientFormsLength);
        setRecipientFormsLength(recipientFormsLength);

        // Ensure primaryDocs is an array and not undefined
        if (Array.isArray(response.data.response.primaryDocs)) {
          const uploadedDocs = response.data.response.primaryDocs.map((doc) => doc.docName);
          setUploadedFiles(uploadedDocs);

          const initialFileToggleStates = uploadedDocs.map(() => false);
          setFileToggleStates(initialFileToggleStates);

          setRegisterSignID(response.data.response.registerSignID);
          setReferenceNumber(response.data.response.referenceNumber);
          setDescription(response.data.response.description);
          setIsSerial(response.data.response.isSerial);

          setFormData(response.data.response);

          const masterDocIDs = response.data.response.primaryDocs.map((doc) => doc.masterDocID);
          setMasterDocIDs(masterDocIDs);

          setTotalPages(response.data.response.primaryDocs.map((doc) => doc.masterDocPagesCount));

          response.data.response.primaryDocs.forEach((doc) => {
            const { masterDocID, templateID } = doc;

            setAppliedTemplates((prevTemplates) => ({
              ...prevTemplates,
              [masterDocID]: templateID,
            }));
          });

          const newToggleStates = response.data.response.primaryDocs.map((doc) => doc.isPasswordProtected);
          setFileToggleStates(newToggleStates);

          const newTemplateApplied = response.data.response.primaryDocs.map((doc) => doc.isTemplateApplied);
          setIsTemplateApplied(newTemplateApplied);
        }
      }
    } catch (error) {
      if (error.response.data.statusCode === "2" || error.response.data.statusCode === "4") {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (storedRegisterSignID !== null) {
      fetchData();
    }
  }, [storedRegisterSignID, userAccess]);

  const handleOrientationChange = (serial) => {
    // console.log(">>>", serial);
    setIsSerial(serial);
  };

  const handleFileUpload = async (event) => {
    setIsLoading(true);
    const newFiles = Array.from(event.target.files);

    // console.log("new files", newFiles);

    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/vnd.ms-powerpoint",
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));

    if (validFiles.length === 0) {
      // setUploadErrorMessage(t("uploadDocFlow.formatNotAllow"));
      toast.error("Only pdf and docs are allowed");
      setIsLoading(false);
    } else {
      setUploadErrorMessage("");
      const base64Files = [];

      for (const file of validFiles) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const base64Data = e.target.result.split(",")[1];
          base64Files.push({
            name: file.name,
            base64: base64Data,
            MasterDocID: null,
          });

          if (base64Files.length === validFiles.length) {
            const uploadedFileNames = uploadedFiles.map((fileName) => fileName);

            const duplicateFiles = base64Files.filter((uploadedFile) => uploadedFileNames.includes(uploadedFile.name));

            if (duplicateFiles.length > 0) {
              setUploadErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
              toast.error(t("uploadDocFlow.filewithSameNameExits"));
              setIsLoading(false);
            } else {
              const body = {
                ReferenceNumber: referenceNumber,
                Description: description,
                CreatedBy: user.email,
                IsSerial: true,
                isOnlySigner: false,
                registerSignID: registerSignID,
                PrimaryDocs: base64Files.map((uploadedFile) => {
                  const fileExtension = uploadedFile.name.split(".").pop();

                  return {
                    DocName: uploadedFile.name,
                    DocExtension: fileExtension,
                    DocFile: uploadedFile.base64,
                    IsPasswordProtected: false,
                  };
                }),
              };

              try {
                const response = await axios.post("/v1.0/api/RegisterDocs", body, {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                });

                if (response.data.statusCode === "1") {
                  setRegisterSignID(response.data.response.registerSignID);
                  localStorage.setItem("registerSignID", response.data.response.registerSignID);

                  const MasterDocId = response.data.response.masterDocs[0].masterDocIDs;
                  setMasterDocIDs((prevIDs) => [...prevIDs, MasterDocId]);

                  const pages = response.data.response.totalPages;
                  setTotalPages((prevPages) => [...prevPages, pages]);

                  // setTotalPages(response.data.response.totalPages);

                  setUploadedFiles([...uploadedFiles, ...base64Files.map((uploadedFile) => uploadedFile.name)]);

                  setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

                  setIsTemplateApplied((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

                  setUploadErrorMessage("");
                  setSelectedFile(null);
                  toast.success(t("uploadDocFlow.fileuploadSuccessfully"));
                  // setTemplatesAppliedModal(true);
                  handleGetTemplate(MasterDocId, pages, 0, false);

                  setIsLoading(false);
                } else {
                  // setUploadErrorMessage(t("uploadDocFlow.apireqFailed"));
                  // setIsLoading(false);
                }
              } catch (error) {
                setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
                toast.error(t("uploadDocFlow.errorUploadFile"), {
                  position: "top-right",
                });
                //console.log('file upload error', error);
                setIsLoading(false);
              }
            }
          }
        };

        reader.readAsDataURL(file);

        reader.onerror = (error) => {
          isLoading(false);
        };
      }
    }
  };

  const handleFileview = async (masterDocID, fileName) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetMasterDocPages?MasterDocID=${masterDocID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        const pages = response.data.response;
        const pagesMap = {};
        pages.forEach((page) => {
          pagesMap[page.pagesName] = page;
        });
        setDocumentPages(pagesMap);
        setDocName(fileName);
        setShowModal(true);
        setIsLoading(false);
      } else {
        // console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.filecantView"));
      }
    }
    setIsLoading(false);
  };

  const handlePasswordProtected = async (newChecked, index, masterDoCID) => {
    const updatedToggleStates = [...fileToggleStates];
    updatedToggleStates[index] = newChecked;
    setFileToggleStates(updatedToggleStates);

    try {
      const response = await axios.put(
        "/v1.0/api/IsPasswordProtected",
        {
          MasterDocID: masterDoCID,
          IsPasswordProtected: newChecked,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // confirmation modal
  const handleShowConfirmModal = (masterDocID, index) => {
    setDeletingMasterDocID(masterDocID);
    setDeletingIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleFileDelete = async (masterDocID, index) => {
    //console.log(masterDocID, index);
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteMasterDoc",
        {
          MasterDocID: masterDocID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles.splice(index, 1);
        setUploadedFiles(updatedUploadedFiles);

        const updatedMasterDocIDs = [...masterDocIDs];
        updatedMasterDocIDs.splice(index, 1);
        setMasterDocIDs(updatedMasterDocIDs);

        setIsLoading(false);
      }

      toast.success(t("uploadDocFlow.fileDeleted"));
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errorDeletingFiles"));
      }
      setIsLoading(false);
    } finally {
      handleCloseConfirmModal();
      setIsLoading(false);
    }
  };

  const searchedTemplates = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetTemplates", {
        headers: {
          Authorization: `Bearer ${token} `,
        },
        params: {
          TemplateName: templateName,
        },
      });
      if (response.data.successStatus) {
        setAllTemplates(response.data.response);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // const handleGetTemplate = async (masterDoCID, totalDocPages, index) => {
  //   //console.log('get at: ', index)
  //   //console.log('pagesssssss', totalDocPages)
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get("/v1.0/api/GetTemplates", {
  //       headers: {
  //         Authorization: `Bearer ${token} `,
  //       },
  //     });

  //     if (response.data.successStatus) {
  //       const templatesWithSamePages = response.data.response.filter((template) => {
  //         return template.masterDocPagesCount === totalDocPages;
  //       });

  //       if (viewMode === "allTemplates") {
  //         setAllTemplates(response.data.response);
  //       } else if (viewMode === "matchingTemplates" || (templatesWithSamePages && templatesWithSamePages.length > 0)) {
  //         setTemplatesAppliedModal(true);
  //         setMatchingTemplates(templatesWithSamePages);
  //       }

  //       // console.log("same pages", templatesWithSamePages);
  //       // setAllTemplates(templatesWithSamePages);
  //       // setAllTemplates(response.data.response);
  //       // if (templatesWithSamePages && templatesWithSamePages.length > 0) {
  //       //   setTemplatesAppliedModal(true);
  //       //   setMatchingTemplates(templatesWithSamePages);
  //       // }

  //       // setAllTemplates(response.data.response);

  //       setTemplatesAppliedModal(true);
  //       // setMatchingTemplates(templatesWithSamePages);

  //       if (applyTemplate) {
  //         setTemplatesModalShow(true);
  //         setApplyTemplate(false);
  //       }
  //       // setTemplatesModalShow(true);
  //       setSelectedMasterDocID(masterDoCID);
  //       setSelectedIndex(index);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsLoading(false);
  //   }
  // };

  const handleGetTemplate = async (masterDocID, totalDocPages, index, applyTemplate) => {
    console.log("get template called");
    console.log(masterDocID, totalDocPages, index);
    console.log("apply template", applyTemplate);
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetTemplates", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.successStatus) {
        const templates = response.data.response;
        const templatesWithSamePages = templates.filter((template) => template.masterDocPagesCount === totalDocPages);

        if (templatesWithSamePages && templatesWithSamePages.length > 0 && !applyTemplate) {
          console.log("matching templates ");
          setTemplatesAppliedModal(true);
          setMatchingTemplates(templatesWithSamePages);
        } else {
          setTemplatesAppliedModal(false);
          console.log("no matching templates ");
        }

        if (applyTemplate) {
          console.log("click on apply");
          setTemplatesModalShow(true);
          setMatchingTemplates(templatesWithSamePages);
        }

        if (viewMode === "allTemplates") {
          console.log("all templates ");
          setAllTemplates(templates);
        } else if (viewMode === "matchingTemplates") {
          setMatchingTemplates(templatesWithSamePages);
          console.log("all templates ");
        }

        // setTemplatesAppliedModal(true);
        // setMatchingTemplates(templatesWithSamePages);

        setSelectedMasterDocID(masterDocID);
        setSelectedIndex(index);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleApplyTemplate = async (templateID) => {
    setAppliedTemplates((prevTemplates) => ({
      ...prevTemplates,
      [selectedMasterDocID]: templateID,
    }));

    setChildRender(false);
    setIsLoading(true);

    const body = {
      TemplateID: templateID,
      RegisterSignID: registerSignID,
      MasterDocID: selectedMasterDocID,
    };

    //console.log('apply', body);
    try {
      const response = await axios.put("/v1.0/api/ApplyTemplate", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.successStatus) {
        setChildRender(true);
        setTemplatesModalShow(false);
        setIsLoading(false);
        toast.success("Template applied successfully");
        const updatedAppliedTemplates = [...isTemplateApplied];
        //console.log('selcted index', selectedIndex);
        updatedAppliedTemplates[selectedIndex] = true;
        //console.log('apply', updatedAppliedTemplates, setSelectedIndex);
        setIsTemplateApplied(updatedAppliedTemplates);
      }
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setTemplatesModalShow(false);
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //console.log(childRender);
  });
  const handleRemoveTemplate = async (masterDoCID, templateID, index) => {
    setChildRender(false);
    setIsLoading(true);
    const body = {
      TemplateID: templateID,
      MasterDocID: masterDoCID,
    };

    //console.log(body);

    try {
      const response = await axios.put("/v1.0/api/RemoveTemplate", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.successStatus) {
        setChildRender(true);
        setIsLoading(false);
        // await fetchData();
        toast.success("Template removed successfully");
        setAppliedTemplates((prevTemplates) => ({
          ...prevTemplates,
          [selectedMasterDocID]: null,
        }));

        const updatedAppliedTemplates = [...isTemplateApplied];
        updatedAppliedTemplates[index] = false;
        setIsTemplateApplied(updatedAppliedTemplates);
        //console.log('remove', updatedAppliedTemplates, index);
      }
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecipientFormsValidation = (isValid) => {
    setRecipientFormsValid(isValid);
  };

  const handleRecipientFormsLength = (length) => {
    setRecipientFormsLength(length);
  };

  console.log("Email being passed:", user.email);

  const workflow = async () => {
    const StepData = {
      SignWorkFlowActivityName: t("uploadDocFlow.placeSignatories"),
      SignWorkFlowActivityRef: "2",
      SignWorkFlowActivityClass: "RED",
      RegisterSignID: registerSignID,
      IsMobile: false,
    };

    try {
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateWorkFlowActivity", StepData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsLoading(false);

      if (response.data.successStatus) {
        // toast.success("Form data submitted successfully");
        setActiveStep(2);
         setCompletedSteps([1]);
      } else {
        setUploadErrorMessage(t("uploadDocFlow.apireqFailed"));
        // toast.error("Data not submitted");
      }
    } catch (error) {
      setIsLoading(false);

      if (error.response.data.statusCode === "2" || error.response.data.statusCode === "4") {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
  };

  const ReferenceDescription = async () => {
    const updatedRegDetails = {
      RegisterSignID: registerSignID,
      ReferenceNumber: referenceNumber,
      Description: description,
      IsSerial: isSerial,
      isOnlySigner: isOnlySigner,
    };

    //console.log(updatedRegDetails);
    try {
      const response = await axios.put("/v1.0/api/UpdateRegisterDetails", updatedRegDetails, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      // toast.success("Data updated successfully");
    } catch (error) {
      // console.error("Error in updating data:", error);
      // toast.error("Error in updating data ");
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
  };

  const handleContinue = async () => {
    if (
      // !recipientFormsValid &&
      recipientFormsLength < 1 &&
      uploadedFiles.length > 0
    ) {
      // setRecipientErrorMessage(t("uploadDocFlow.recipientForm"));
      toast.error("Please add a signatory by clicking on 'Add a new signer' button");
    } else if (recipientFormsLength > 0 && uploadedFiles.length === 0) {
      // setUploadErrorMessage(t("uploadDocFlow.uploadAtleastOneFile"));
      toast.error(t("uploadDocFlow.uploadAtleastOneFile"));
    } else if (recipientFormsLength === 0 && uploadedFiles.length === 0) {
      // setUploadErrorMessage(t("uploadDocFlow.uploadAtleastOneFile"));
      // setRecipientErrorMessage(t("uploadDocFlow.recipientForm"));
      toast.error(t("uploadDocFlow.uploadAtleastOneFile"));
      toast.error("Please add a signatory by clicking on 'Add a new signer' button");
    } else {
      ReferenceDescription();
      workflow();
    }
  };

  const allTemplatesColumns = [
    { label: t("uploadDocFlow.templateName"), key: "templateName" },
    { label: "", key: "templateID" },
  ];
  const matchingTemplatesColumns = [
    { label: t("uploadDocFlow.templateName"), key: "templateName" },
    { label: "", key: "templateID" },
  ];

  return (
    <>
      <Container className='upload-container'>
        {/* <div className='upload_body' id='part_one'> */}
        <div style={{ padding: "15px", backgroundColor: "#FFF", borderRadius: "10px", marginTop: "1rem" }}>
          {/* <h2>{t("uploadDocFlow.documentsToUpload")}</h2> */}
          <div className='d-flex align-items-center gap-3 m-3'>
            <img src={UploadDocIcon} alt='iconLoading' />
            <h4 className='m-0'>{t("uploadDocFlow.documentsToUpload")}</h4>
          </div>

          <Container>
            <Row>
              <Col md={4}>
                <div className='uplad_box'>
                  <Form.Group controlId='file-input-add' className='file-upload_temp'>
                    <div
                      className='upload_trigger_bx'
                      onClick={() => {
                        document.querySelector(".temp_file-input-additional").click();
                      }}
                    >
                      <img alt='iconLoading' src={systemIcon} />
                      <br />
                      <Form.Label>{t("uploadDocFlow.local")}</Form.Label>
                      <Form.Control
                        className='temp_file-input-additional'
                        type='file'
                        name='file'
                        accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
                        onChange={handleFileUpload}
                        multiple
                        style={{ display: "none" }}
                      />
                      <div className='upload_doc_part'>
                        <p>{t("uploadDocFlow.selectFilesToUpload")}</p>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Col>
              {/* {user.isDropbox  && (
                <Col md={4}>
                  <Dropbox
                    onFileSelected={handleDropboxFileSelected}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    registerSignID={registerSignID}
                    setRegisterSignID={setRegisterSignID}
                    email={user.email}
                  />
                </Col>
              )} */}
              {/* {user.isGoogleDrive && userAccess.googDrive && (
                <Col md={4}>
                  <UploadFromGoogleDrive
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    registerSignID={registerSignID}
                    setRegisterSignID={setRegisterSignID}
                    email={user.email}
                  />
                </Col>
              )} */}
              {user.isDropbox &&
                (userAccess.dropbox ? (
                  <Col md={4}>
                    <Dropbox
                      onFileSelected={handleDropboxFileSelected}
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      masterDocIDs={masterDocIDs}
                      setMasterDocIDs={setMasterDocIDs}
                      fileToggleStates={fileToggleStates}
                      setFileToggleStates={setFileToggleStates}
                      registerSignID={registerSignID}
                      setRegisterSignID={setRegisterSignID}
                      email={user.email}
                    />
                  </Col>
                ) : null)}

              {user.isGoogleDrive &&
                (userAccess.googDrive ? (
                  <Col md={4}>
                    <UploadFromGoogleDrive
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      masterDocIDs={masterDocIDs}
                      setMasterDocIDs={setMasterDocIDs}
                      fileToggleStates={fileToggleStates}
                      setFileToggleStates={setFileToggleStates}
                      registerSignID={registerSignID}
                      setRegisterSignID={setRegisterSignID}
                      email={user.email}
                    />
                  </Col>
                ) : null)}

              <div style={{ display: "none" }}>
                <Col md={4}>
                  <ListOfDocuments
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    registerSignID={registerSignID}
                    setRegisterSignID={setRegisterSignID}
                    email={user.email}
                  />
                </Col>
              </div>

              <div style={{ display: "none" }}>
                <Col md={4}>
                  <ManualDosList
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    registerSignID={registerSignID}
                    setRegisterSignID={setRegisterSignID}
                    email={user.email}
                  />
                </Col>
              </div>
            </Row>

            <br />
            {uploadErrorMessage && (
              <p className='error-message'>
                <BiErrorCircle className='mb-1 mx-2' />
                {uploadErrorMessage}
              </p>
            )}

            {loading ||
              (isLoading && (
                <div className='loader-overlay'>
                  <Loader />
                </div>
              ))}

            <div className='upload_file_box' id='upload_file_bx '>
              {uploadedFiles.map((fileName, index, file) => (
                <div key={index} className='uploaded-file d-flex align-items-center justify-content-between px-3 border-bottom'>
                  <p className='m-0 d-flex align-items-center gap-2'>
                    <img src={pdfFileIcon} alt='iconLoading' />
                    {fileName} {isTemplateApplied[index] && <FcTemplate size={18} className='template-hover' />}
                  </p>

                  <div className='d-flex align-items-center'>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu>
                        {!isTemplateApplied[index] && (
                          <Dropdown.Item
                            className='d-flex gap-2 align-items-center'
                            onClick={() => {
                              handleGetTemplate(masterDocIDs[index], totalPages[index], index, true);
                            }}
                          >
                            <img src={templateIcon} alt='iconLoading' />
                            {t("uploadDocFlow.Applytemplate")}
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileview(masterDocIDs[index], fileName)}>
                          <img src={ViewFileIcon} alt='iconLoading' />
                          {t("uploadDocFlow.view")}
                        </Dropdown.Item>

                        <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShowConfirmModal(masterDocIDs[index], index)}>
                          <img src={DeleteIcon} alt='iconLoading' />
                          {t("uploadDocFlow.delete")}
                        </Dropdown.Item>

                        {isTemplateApplied[index] && (
                          <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleRemoveTemplate(masterDocIDs[index], appliedTemplates[masterDocIDs[index]], index)}>
                            <img src={removeTemplate} alt='iconLoading' />
                            {t("uploadDocFlow.removeTemplate")}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Modal show={templatesAppliedModal} onHide={() => setTemplatesAppliedModal(false)} dialogClassName='custom-modal-width'>
                      <Modal.Header closeButton>
                        <Modal.Title>{t("uploadDocFlow.saveTemplate")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.matchingTemplates")}</Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant='none'
                          className='applyBtn'
                          onClick={() => {
                            setTemplatesAppliedModal(false);
                            // handleGetTemplate(
                            //   masterDocIDs[index],
                            //   totalPages[index],
                            //   index
                            // );
                            setTemplatesModalShow(true);
                            // setApplyTemplate(false);
                          }}
                        >
                          {t("uploadDocFlow.yesProceed")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              ))}
            </div>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
              <Modal.Header closeButton>
                <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteFile")}</Modal.Body>
              <Modal.Footer>
                <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                  {t("uploadDocFlow.cancel")}
                </Button>
                <Button variant='none' className='applyBtn' onClick={() => handleFileDelete(deletingMasterDocID, deletingIndex)}>
                  {t("uploadDocFlow.delete")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton className='btn'>
                <Modal.Title>{docName}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='d-flex'>
                <Col>
                  {isLoading ? (
                    <div className='loader d-flex justify-content-center  align-items-center'>Loading...</div>
                  ) : (
                    Object.values(documentPages).map((page, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <img src={page.pagesPath} alt={page.pagesName} style={{ width: "100%", height: "auto" }} />
                        <div className='page-name-footer'>{page.pagesName}</div>
                      </div>
                    ))
                  )}
                </Col>
              </Modal.Body>
            </Modal>

            <Modal show={templatesModalShow} onHide={() => setTemplatesModalShow(false)}>
              <Modal.Header closeButton>
                <Modal.Title>{t("uploadDocFlow.chooseTemplate")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='m-3'>
                  <div className='d-flex gap-3 '>
                    <Button className={`SignButton ${viewMode === "allTemplates" ? "active" : ""}`} onClick={handleAllTemplates}>
                      {t("uploadDocFlow.Applytemplate")}
                    </Button>
                    <Button className={`SignButton ${viewMode === "matchingTemplates" ? "active" : ""}`} onClick={handleMatchingTemplates}>
                      {t("uploadDocFlow.matchTemplates")}
                    </Button>
                  </div>
                </div>
                {viewMode === "allTemplates" && (
                  <TableContainer component={Paper} style={{ height: "66vh" }}>
                    <Table stickyHeader style={{ border: "none" }}>
                      <TableHead>
                        <TableRow>
                          {allTemplatesColumns.map((allTemplatesColumns) => (
                            <TableCell key={allTemplatesColumns.label} style={{ backgroundColor: "#dee2e6" }} className='TableHeaderCell'>
                              {allTemplatesColumns.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {allTemplates.length === 0 && (
                          <TableRow>
                            <td colSpan='6' className='p-5 text-center'>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img alt='iconLoading' src={noDocImg} style={{ height: "150px", width: "200px" }} />
                                {t("dashboardCardStatuses.notDocsFound")}
                              </div>
                            </td>
                          </TableRow>
                        )}

                        {allTemplates.map((row, index) => (
                          <TableRow className='' key={index}>
                            <TableCell className='TableCell'>{row.templateName}</TableCell>
                            <TableCell className='TableCell'>
                              <Button variant='text' className='SignNowbtn' onClick={() => handleApplyTemplate(row.templateID)}>
                                {t("uploadDocFlow.apply")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {viewMode === "matchingTemplates" && (
                  <TableContainer component={Paper} style={{ height: "66vh" }}>
                    <Table stickyHeader style={{ border: "none" }}>
                      <TableHead>
                        <TableRow>
                          {matchingTemplatesColumns &&
                            matchingTemplatesColumns.map((matchingTemplatesColumns) => (
                              <TableCell key={matchingTemplatesColumns.label} style={{ backgroundColor: "#dee2e6" }} className='TableHeaderCell'>
                                {matchingTemplatesColumns.label}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {matchingTemplates && matchingTemplates.length === 0 && (
                          <TableRow>
                            <td colSpan='6' className='p-5 text-center'>
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img alt='iconLoading' src={noDocImg} style={{ height: "150px", width: "200px" }} />
                                {t("dashboardCardStatuses.notDocsFound")}
                              </div>
                            </td>
                          </TableRow>
                        )}

                        {matchingTemplates &&
                          matchingTemplates.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className='TableCell'>{row.templateName}</TableCell>
                              <TableCell className='TableCell'>
                                <Button variant='text' className='SignNowbtn' onClick={() => handleApplyTemplate(row.templateID)}>
                                  {t("uploadDocFlow.apply")}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {/* <Table bordered hover>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#dee2e6" }} className='d-flex justify-content-between align-items-center'>
                        Template Name
                        <div class='p-1 bg-light rounded rounded-pill shadow-sm '>
                          <div class='input-group ms-1'>
                            <input
                              type='search'
                              placeholder='Search...'
                              aria-describedby='button-addon1'
                              className='form-control border-0 bg-light'
                              value={templateName}
                              onChange={(e) => setTemplateName(e.target.value)}
                            />
                            <div class='input-group-append'>
                              <button id='button-addon1' type='submit' className='btn btn-link text-primary' onClick={searchedTemplates}>
                                <IoSearch />
                              </button>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {matchingTemplates.length === 0 && (
                      <td colSpan='6' className='p-5 text-center'>
                        {t("navbar.notDataFound")}
                      </td>
                    )}
                    {matchingTemplates.map((template) => (
                      <tr key={template.templateID}>
                        <td className='d-flex justify-content-between align-items-center px-3'>
                          <>
                            {template.templateName}
                            <Button
                              variant='link'
                              style={{
                                backgroundColor: "#dee2e6",
                                padding: "5px 10px",
                              }}
                              // className="applyBtn "
                              onClick={() => handleApplyTemplate(template.templateID)}
                            >
                              Apply
                            </Button>
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
              </Modal.Body>
            </Modal>
          </Container>
        </div>

        {fetchedData && (
          <div className='container p-0 my-3'>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>
                  <div className='d-flex align-items-center gap-3'>
                    <img src={DocInfoIcon} alt='IconLoading' />
                    <h2>{t("uploadDocFlow.docInfo")}</h2>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='px-0'>
                    <Row>
                      <Col md={6}>
                        <ReferenceNumber fetchedData={fetchedData} setFetchedData={setFetchedData} setReferenceNumber={setReferenceNumber} />
                      </Col>
                      <Col md={6}>
                        <Description fetchedData={fetchedData} setFetchedData={setFetchedData} setDescription={setDescription} />
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}

        <SignatoryOrientation onOrientationChange={handleOrientationChange} isSerial={isSerial} />

        <AddRecipient
          onRecipientFormsValidation={handleRecipientFormsValidation}
          onRecipientFormsLength={handleRecipientFormsLength}
          registerSignID={registerSignID}
          uploadedFiles={uploadedFiles}
          setIsOnlySigner={setIsOnlySigner}
          isOnlySigner={isOnlySigner}
          childRender={childRender}
        />

        {recipientErrorMessage && (
          <p className='error-message'>
            <BiErrorCircle className='mb-1 ms-5 me' />
            {recipientErrorMessage}
          </p>
        )}

        <div className='step_button_holder mt-3'>
          <Button className='mb-3 Continue-btn' id='hide_part_one' onClick={handleContinue}>
            {t("uploadDocFlow.Continue")}
          </Button>
        </div>
      </Container>
    </>
  );
};

export default FirstStep;
