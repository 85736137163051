export const staticToken = "TWFjcXVpcmVzLTg5QDg1OTg6UGFzczk4NUBAJiY=";

//for build
 export const Site_key = "6LdchCApAAAAAGgchZUx8O94vrDOjCkiXXBRdUlu";
//for localhost
//export const Site_key = "6Ld-fw8pAAAAAGNy2A7fZeEbJ7YPBE-AaLmB59X2";

export const API_KEY = "rqqpqwswwxtxbuq";

export const TINYMCE_KEY = "94jsrnbwk2dq2r3nqzcdo5w5glx8ifam1jhnf2iw5ny8yhb3";
