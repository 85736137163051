import React, { useEffect, useState } from "react";
import "./UploadDoc.css";
import { Container, Nav } from "react-bootstrap";
import FirstStep from "../Step1/FirstStep";
import Secondstep from "../Step2/Secondstep";
import { useTranslation } from "react-i18next";
import { UserProvider } from "../../../Context/UserContext";
import Stepper from "../../../Components/Stepper/Stepper";
import { useUser } from "../../../Context/UserContext";
import UserLisence from "../../../Components/UserLisence/UserLisence";

const UploadDoc = () => {
  const { t } = useTranslation();
  const { user, userAccess } = useUser("");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(1);

  return (
    <UserProvider>
      <div className='uploadDoc-container'>
        {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
          <>
            <UserLisence />
          </>
        ) : (
          <>
            <div>
              <Stepper activeStep={activeStep} t={t} completedSteps={completedSteps} />
              <div className='formSteps'>
                {activeStep === 1 && <FirstStep setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
                {activeStep == 2 && <Secondstep setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
              </div>
            </div>
          </>
        )}
      </div>
    </UserProvider>
  );
};

export default UploadDoc;
