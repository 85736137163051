import React, { useEffect, useRef, useState } from "react";
import axios from "../../Api/api";
import { MdCancel } from "react-icons/md";
import "./SignaturePage.css";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Comments from "../../Components/Comments/Comments";
import SignatureModal from "./SignatureModal";
import AuditTrailModal from "../AuditTrail/AuditTrailModal";
import LogoImg from "../../assets/images/stepper-logo.svg";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../Components/Language/LanguageSelector";
import PasswordModal from "./PasswordModal";
import ImageThumbnails from "../../Components/ImageThumbnails/ImageThumbnails";
import downloadIcon from "../../assets/images/download.svg";
import declinedIcon from "../../assets/images/declined.svg";
import AuditTrailIcon from "../../assets/images/auditTrail.svg";
import PageNavigations from "../../Components/PageNavigations";
import ConfirmationModal from "./ConfirmationModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowAnimation from "../../assets/images/Arrow.gif"
import Loader from "../../Components/Loader/Loader";

const SignaturePage = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState();
  const [masterDocIDs, setMasterDocIDs] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMasterDocID, setSelectedMasterDocID] = useState();
  const [selectedSignedDocKey, setSelectedSignedDocKey] = useState();
  const [defaultMasterDocID, setDefaultMasterDocID] = useState(null);
  const [imagesData, setImagesData] = useState([]);
  const [masterDocPagesId, setMasterDocPagesId] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [fullName, setFullName] = useState("");
  const [initials, setInitials] = useState("");
  const [controlData, setControlData] = useState([]);
  const [signatoriesID, setSignatoriesID] = useState();
  const [email, setEmail] = useState();
  const [controlLocationID, setControlLocationID] = useState();
  const [textEditor, setTextEditor] = useState();
  const [isToastDisplayed, setIsToastDisplayed] = useState(false);
  const [registerSignID, setRegisterSignID] = useState();
  const [auth, setAuth] = useState();
  const [signatoryEmail, setSignatoryEmail] = useState();
  const [signatoryName, setSignatoryName] = useState();
  const [signatoryID, setSignatoryID] = useState();
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [controlName, setControlName] = useState();
  const [controlID, setControlID] = useState();
  const [reason, setReason] = useState("");
  const [uploadedSignature, setUploadedSignature] = useState([]);
  const [enableCompletedButton, setEnableCompletedButton] = useState(false);
  const [fileName, setFileName] = useState();
  const [showATModal, setShowATModal] = useState(false);
  const [auditTrailURL, setAuditTrailURL] = useState("");
  const [allControlData, setAllControlData] = useState(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [passwordProtected, setPasswordProtected] = useState("");
  const [viewOnly, setViewOnly] = useState();
  const [textValue, setTextValue] = useState("");
  // const [enableCompletedButton, setEnableCompletedButton] = useState(false);
  const [scrollToCalled, setScrollToCalled] = useState(false);
  const [controlRefs, setControlRefs] = useState([]);
  const [currentControlIndex, setCurrentControlIndex] = useState(0);
  const [sortedControls, setSortedControls] = useState([]);
  const [completedControls, setCompletedControls] = useState(false);
  const [editingStates, setEditingStates] = useState({});
  const [textInput, setTextInput] = useState("");
  const indicatorRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  // const [isBulkSign, setIsBulkSign] = useState(false);

  console.log(editingStates);
  // console.log('current index', currentControlIndex);
  useEffect(() => {
    const sortControlsByPage = () => {
      const filteredControls = controlData.filter((control) => control.controlStatus === "Pending" && ["Signature", "Stamp", "Initial",].includes(control.controlName));
      const sorted = filteredControls.sort((a, b) => {
        // If controls are on different pages, sort by page order
        if (a.pagesOrder !== b.pagesOrder) {
          return a.pagesOrder - b.pagesOrder;
        }
        // If controls are on the same page, sort by vertical position
        const posYA = parseFloat(a.controlLocationY.replace("px", ""));
        const posYB = parseFloat(b.controlLocationY.replace("px", ""));
        return posYA - posYB;
      });

      // console.log("sorted", sorted);
      setSortedControls(sorted);
    };

    sortControlsByPage();

    scrollToControl(0); // Start scrolling at index 0
  }, [controlData]);

  // Function to handle scrolling to the next control

  // ***********************************//
  const waitForElement = async (id) => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error(`Element with ID "${id}" not found within timeout`));
      }, 5000); // Set a timeout (adjust as needed)

      const checkElement = () => {
        const element = document.getElementById(id);
        if (element) {
          clearTimeout(timeout);
          resolve(element);
        } else {
          requestAnimationFrame(checkElement);
        }
      };

      checkElement();
    });
  };

  // const scrollToNextControl = async () => {

  //   console.log("**************************", currentControlIndex)
  //   // setCurrentControlIndex(currentControlIndex + 1);
  //   if (currentControlIndex > sortedControls.length - 1) {
  //     return
  //   }

  //   const nextcontrol = sortedControls[currentControlIndex];
  //   console.log(" nextcontrol control index", currentControlIndex, nextcontrol);
  //   const element = nextcontrol && document.getElementById(nextcontrol.controlLocationID);

  //   if (nextcontrol.masterDocPagesID !== masterDocPagesId) {
  //     console.log(`pageID mismatch`);
  //     try {
  //       setMasterDocPagesId(nextcontrol.masterDocPagesID);
  //       setSelectedImage(nextcontrol.pagesPath); // Load image
  //       await waitForElement(nextcontrol.controlLocationID); // Wait for element
  //       scrollToControl(currentControlIndex); // Scroll after element is ready
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   } else {
  //     scrollToControl(currentControlIndex);
  //   }

  // }

  // const scrollToControl = (index) => {
  //   const control = sortedControls[index];
  //   console.log(" current control index", index, control);

  //   const element = control && document.getElementById(control.controlLocationID);
  //   console.log("element", element)
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     console.log('>>>>>>>>>>>>>>>>>>>>>>>>')
  //     setCurrentControlIndex(index + 1);
  //     indicatorRef.current.style.top = control.controlLocationY;
  //   }
  // };

  // **********************************//

  /*****************scroll on upload********************/
  useEffect(() => {
    if (currentControlIndex !== null && !scrollToCalled) {
      setScrollToCalled(true);
      scrollToNextControl();
    }
  }, [currentControlIndex, scrollToCalled]);

  const scrollToNextControl = async () => {
    // console.log("*********current inside next", currentControlIndex)
    if (currentControlIndex === null) return; // Do nothing if currentControlIndex is null

    let newIndex = currentControlIndex + 1;
    if (newIndex > sortedControls.length - 1) {
      // console.log("before", newIndex)
      newIndex = 0; // Reset to index 0 if reached end
    }

    // console.log("after", newIndex)

    setCurrentControlIndex(newIndex);
    const nextcontrol = sortedControls[newIndex];
    // console.log("control index", newIndex, nextcontrol);

    if (nextcontrol && nextcontrol.masterDocPagesID !== masterDocPagesId) {
      // console.log(`pageID mismatch`);
      try {
        setMasterDocPagesId(nextcontrol.masterDocPagesID);
        setSelectedImage(nextcontrol.pagesPath); // Load image
        await waitForElement(nextcontrol.controlLocationID); // Wait for element
        scrollToControl(newIndex); // Scroll after element is ready
      } catch (error) {
        // console.error("Error:", error);
      }
    } else {
      scrollToControl(newIndex);
    }
  };

  const scrollToControl = async (index) => {
    const control = sortedControls[index];

    const element = control && document.getElementById(control.controlLocationID);
    if (element) {
      // element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      const rect = element.getBoundingClientRect();
      // Calculate the current scroll position
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // Calculate the position to scroll to with padding
      const offsetTop = rect.top + scrollTop - 300;
      const offsetBottom = rect.bottom + scrollTop + 100;

      window.scrollTo({
        top: offsetTop,
        bottom: offsetBottom,
        behavior: "smooth"
      });

      indicatorRef.current.style.top = control.controlLocationY;
    }
  };
  /*************************************/

  const navigate = useNavigate();
  const mainDocumentRef = useRef(null);

  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");

  const idIndex = parts.indexOf("id");

  useEffect(() => {
    if (idIndex !== -1 && idIndex < parts.length - 1) {
      const pathAfterId = parts.slice(idIndex + 1).join("/");
      setAuth(pathAfterId);
    }
  }, []);

  const handleEnterText = (value) => {
    setTextValue(value);
  };

  const handleDocSelect = async (masterDocID, selectedFolderName, signedDocKey) => {
    const truncatedName = selectedFolderName.length > 10 ? selectedFolderName.slice(0, 7) + "..." : selectedFolderName;
    setSelectedFolder(truncatedName);
    setFileName(selectedFolderName);
    setSelectedMasterDocID(masterDocID);
    setSelectedSignedDocKey(signedDocKey);
    setIsLoading(true);
    getMasterDocPages(masterDocID);
    setIsLoading(false);
    try {
      const response = await axios.post(
        "/v1.0/api/CreateSignActivityVerification",
        {
          Status: "Document selected",
          Message: `${signatoryName} selected  ${selectedFolderName} document`,
        },
        {
          headers: {
            signAuth: auth,
          },
        }
      );
    } catch (error) { }
  };

  useEffect(() => {
    if (imagesData && imagesData.length > 0) {
      setSelectedImage(imagesData[0].pagesPath);
      setMasterDocPagesId(imagesData[0].masterDocPagesID);
    }
  }, [imagesData]);

  const handleImageSelect = (image, id) => {
    setMasterDocPagesId(id);
    setSelectedImage(image);
  };

  const openModal = (ControlLocationID, signatoriesID, email, control, controlId, textEditor) => {
    setControlLocationID(ControlLocationID);
    setEmail(email);
    setSignatoriesID(signatoriesID);
    setControlName(control);
    setControlID(controlId);
    setTextEditor(textEditor);
    setShowModal(true);
  };

  useEffect(() => {
    const getControlsData = async () => {
      const files = formData && formData.primaryDocs;
      let accumulatedControlData = [];

      if (Array.isArray(files)) {
        // If there are multiple files
        for (const doc of files) {
          try {
            const response = await axios.get(`/v1.0/api/GetControlLocationVerification?MasterDocID=${doc.masterDocID}`, {
              headers: {
                SignAuth: auth,
              },
            });

            if (response.data.successStatus) {
              accumulatedControlData = accumulatedControlData.concat(response.data.response);
              //console.log("controls", response);
            }
          } catch (error) {
            // console.error("Error fetching control data for doc ID", doc.masterDocID, ":", error);
          }
        }
      } else if (files) {
        // If there is a single file
        try {
          const response = await axios.get(`/v1.0/api/GetControlLocationVerification?MasterDocID=${files.masterDocID}`, {
            headers: {
              SignAuth: auth,
            },
          });

          if (response.data.successStatus) {
            accumulatedControlData = accumulatedControlData.concat(response.data.response);
            //console.log("controls", response);
          }
        } catch (error) {
          // console.error("Error fetching control data for doc ID", files.masterDocID, ":", error);
          // Handle error if needed
        }
      }
      setAllControlData(accumulatedControlData);
      setIsLoading(false);
    };

    getControlsData();
  }, [formData, auth]);

  useEffect(() => {
    setIsLoading(true);
    const getControlsLocation = async () => {
      try {
        const response = await axios.get(`/v1.0/api/GetControlLocationVerification?MasterDocID=${selectedMasterDocID}`, {
          headers: {
            SignAuth: auth,
          },
        });

        if (response.data.successStatus) {
          setControlData(response.data.response);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    if (auth) {
      getControlsLocation();
    }
  }, [selectedMasterDocID, textValue]);

  const getMasterDocPages = async (selectedMasterDocID) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetMasterDocPagesVerification?MasterDocID=${selectedMasterDocID}`, {
        headers: {
          SignAuth: auth,
        },
      });

      setImagesData(response.data.response);

      response.data.response.map((doc) => doc.masterDocPagesID);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const getRegisterSignData = async () => {
      //console.log('register data called')
      try {
        const response = await axios.get("/v1.0/api/GetRegisterSignDataVerification", {
          headers: {
            SignAuth: auth,
          },
        });

        if (response.data.successStatus) {
          //console.log('reg data', response);
          setFormData(response.data.response);

          setRegisterSignID(response.data.response.registerSignID);

          const masterDocIDs = response.data.response.primaryDocs.map((doc) => doc.masterDocID);
          setMasterDocIDs(masterDocIDs);
          setDefaultMasterDocID(masterDocIDs[0]);
          const firstDocument = response.data.response.primaryDocs[0];
          const firstDocName = firstDocument.docName;
          const truncatedName = firstDocName.length > 10 ? firstDocName.slice(0, 7) + ".." : firstDocName;
          setSelectedFolder(truncatedName);

          const firstMasterDocID = firstDocument.masterDocID;
          getMasterDocPages(firstMasterDocID);
          setSelectedMasterDocID(firstMasterDocID);
          const defaultSignedKey = firstDocument.signedDocKey;
          setFileName(firstDocName);
          setSelectedSignedDocKey(defaultSignedKey);

          const pendingSignatory = response.data.response.signatories.find((signatory) => signatory.signStatus === "Pending");

          setSignatoryEmail(pendingSignatory.email);
          setSignatoryName(pendingSignatory.name);
          setViewOnly(pendingSignatory && pendingSignatory.signatureType === "View Only");

          const passwordFile = response.data.response.primaryDocs.some((file) => file.isPasswordProtected);

          //console.log('password file;', passwordFile)

          if (passwordFile) {
            setPasswordProtected("Document");
            setPasswordModal(true);
          }

          const passwordProtectedSignatory = pendingSignatory.isPasswordProtected;

          if (passwordProtectedSignatory) {
            setSignatoryID(pendingSignatory.signatoriesID);
            setPasswordProtected("Signatory");
            setPasswordModal(true);
          }
          // console.log('protected', passwordProtectedSignatory);

          setIsLoading(false);
        }
      } catch (error) {
        // //console.log(error);
        if (error.response && error.response.data && error.response.data.statusCode === "6") {
          navigate("/alreadySigned");
        } else if (error.response && error.response.data && error.response.data.statusCode === "11") {
          navigate("/alreadyDeclined");
        } else if (error.response && error.response.data && error.response.data.statusCode === "13") {
          navigate("/LinkExpired");
        } else if (error.response && error.response.data && error.response.data.statusCode === "14") {
          navigate("/docRecalled");
        }
        setIsLoading(false);
      }
    };
    if (auth) {
      getRegisterSignData();
    }
  }, [auth]);

  useEffect(() => {
    if (isToastDisplayed) {
      toast.info(t("signaturePage.docSignedByOneSignatory"));
    }
  }, [isToastDisplayed]);

  // console.log(viewOnly, signatoryEmail, signatoryName)

  const handleViewAuditTrail = () => {
    const newPdfUrl = `/DownloadAuditTrail/id/${auth}`;
    setAuditTrailURL(newPdfUrl);
    setShowATModal(true);
  };

  const handleFileDownload = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: selectedSignedDocKey,
        },
      });

      if (response.data.statusCode === "1") {
        const response1 = await fetch(response.data.response.document);
        const blob = await response1.blob();
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${fileName}`;
        downloadLink.click();
        setIsLoading(false);
        toast.success(t("signaturePage.fileDownload"));

        try {
          const response = await axios.post(
            "/v1.0/api/CreateSignActivityVerification",
            {
              Status: "file downloaded",
              Message: `${signatoryName} downloaded  ${fileName} file`,
            },
            {
              headers: {
                signAuth: auth,
              },
            }
          );
        } catch (error) { }
      }
    } catch (error) {
      toast.error(t("signaturePage.fileNotDownload"));
      setIsLoading(false);
    }
  };

  const handleFileDecline = async () => {
    if (!reason.trim()) {
      toast.error(t("signaturePage.pleaseEnterDeclineReason"));
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const body = {
      Reason: reason,
    };
    try {
      const response = await axios.put("/v1.0/api/ChangeDocStatusDeclined", body, {
        headers: {
          SignAuth: auth,
        },
      });

      setShowDeclineModal(false);
      navigate("/declinedMessage");

      try {
        const response = await axios.post(
          "/v1.0/api/CreateSignActivityVerification",
          {
            Status: "file declined",
            Message: `${signatoryName} declined ${fileName} file`,
          },
          {
            headers: {
              signAuth: auth,
            },
          }
        );
      } catch (error) { }
    } catch (error) {
      toast.error(t("signaturePage.errDecline"));
    } finally {
      setIsLoading(false);
    }
  };

  const closeDeclineModal = () => {
    setShowDeclineModal(false);
  };

  const handleUploadedSignature = async (uploadedImage, controlLocationID, signatoriesID, controlName, controlID, imageExtension, selectedMasterDocID, isBulkSign) => {
    const existingEntryIndex = uploadedSignature.findIndex((item) => item.controlLocationID === controlLocationID);

    console.log("bulk sign", isBulkSign);
    if (isBulkSign) {
      // Filter the sortedControls array to get only controls of the current type (signature, stamp, initials)
      const filteredControls = sortedControls.filter(item => item.controlName === controlName);

      // Iterate over filtered controls and update corresponding entries in uploadedSignature
      filteredControls.forEach((item) => {
        const bulkEntryIndex = uploadedSignature.findIndex((entry) => entry.controlLocationID === item.controlLocationID);
        const newEntry = {
          controlLocationID: item.controlLocationID,
          uploadedImage,
          signatoriesID,
          controlName: item.controlName,
          controlID: item.controlID,
          imageExtension,
          selectedMasterDocID,
        };

        if (bulkEntryIndex !== -1) {
          uploadedSignature[bulkEntryIndex] = newEntry;
        } else {
          uploadedSignature.push(newEntry);
        }
      });
    } else {
      if (existingEntryIndex !== -1) {
        uploadedSignature[existingEntryIndex] = {
          controlLocationID,
          uploadedImage,
          signatoriesID,
          controlName,
          controlID,
          imageExtension,
          selectedMasterDocID,
        };
      } else {
        uploadedSignature.push({
          controlLocationID,
          uploadedImage,
          signatoriesID,
          controlName,
          controlID,
          imageExtension,
          selectedMasterDocID,
        });
      }
    }

    setUploadedSignature([...uploadedSignature]);

    /*****************scroll on upload********************/
    // Set currentControlIndex if it's null, i.e., on the first upload
    if (currentControlIndex === null) {
      setCurrentControlIndex(sortedControls.findIndex((control) => control.controlLocationID === controlLocationID));
    } else {
      // Reset scrollToCalled to allow scrolling again after each upload
      setScrollToCalled(false);
    }
    /************************************/
  };

  // const handleUploadedSignature = async (uploadedImage, controlLocationID, signatoriesID, controlName, controlID, imageExtension, selectedMasterDocID, isBulkSign) => {
  //   const existingEntryIndex = uploadedSignature.findIndex((item) => item.controlLocationID === controlLocationID);

  //   console.log("bulk sign", isBulkSign);
  //   if (isBulkSign) {
  //     // Filter the sortedControls array to get only "Signature" controls
  //     const signatureControls = sortedControls.filter(item => item.controlName === "Signature");

  //     console.log(">>><<<<<", signatureControls)

  //     // Iterate over filtered signatureControls and update corresponding entries in uploadedSignature
  //     signatureControls.map((item) => {
  //       const bulkEntryIndex = uploadedSignature.findIndex((entry) => entry.controlLocationID === item.controlLocationID);
  //       const newEntry = {
  //         controlLocationID: item.controlLocationID,
  //         uploadedImage,
  //         signatoriesID,
  //         controlName: item.controlName,
  //         controlID: item.controlID,
  //         imageExtension,
  //         selectedMasterDocID,
  //       };

  //       if (bulkEntryIndex !== -1) {
  //         uploadedSignature[bulkEntryIndex] = newEntry;
  //       } else {
  //         uploadedSignature.push(newEntry);
  //       }
  //     });
  //   } else {
  //     if (existingEntryIndex !== -1) {
  //       uploadedSignature[existingEntryIndex] = {
  //         controlLocationID,
  //         uploadedImage,
  //         signatoriesID,
  //         controlName,
  //         controlID,
  //         imageExtension,
  //         selectedMasterDocID,
  //       };
  //     } else {
  //       uploadedSignature.push({
  //         controlLocationID,
  //         uploadedImage,
  //         signatoriesID,
  //         controlName,
  //         controlID,
  //         imageExtension,
  //         selectedMasterDocID,
  //       });
  //     }
  //   }

  //   setUploadedSignature([...uploadedSignature]);

  //   /*****************scroll on upload********************/
  //   // Set currentControlIndex if it's null, i.e., on the first upload
  //   if (currentControlIndex === null) {
  //     setCurrentControlIndex(sortedControls.findIndex((control) => control.controlLocationID === controlLocationID));
  //   } else {
  //     // Reset scrollToCalled to allow scrolling again after each upload
  //     setScrollToCalled(false);
  //   }
  //   /************************************/
  // };




  // const handleUploadedSignature = async (uploadedImage, controlLocationID, signatoriesID, controlName, controlID, imageExtension, selectedMasterDocID) => {
  //   const existingEntryIndex = uploadedSignature.findIndex((item) => item.controlLocationID === controlLocationID);

  //   if (isBulkSign) {
  //     sortedControls.forEach((item, index) => {
  //       if (item.controlName === "Signature") {
  //         uploadedSignature[index] = {
  //           ...item,
  //           uploadedImage,
  //           signatoriesID,
  //           imageExtension,
  //           selectedMasterDocID,
  //         };
  //       }
  //     });
  //   }

  //   if (existingEntryIndex !== -1) {
  //     uploadedSignature[existingEntryIndex] = {
  //       controlLocationID,
  //       uploadedImage,
  //       signatoriesID,
  //       controlName,
  //       controlID,
  //       imageExtension,
  //       selectedMasterDocID,
  //     };
  //   } else {
  //     uploadedSignature.push({
  //       controlLocationID,
  //       uploadedImage,
  //       signatoriesID,
  //       controlName,
  //       controlID,
  //       imageExtension,
  //       selectedMasterDocID,
  //     });
  //   }


  //   setUploadedSignature([...uploadedSignature]);
  //   /*****************scroll on upload********************/
  //   // Set currentControlIndex if it's null, i.e., on the first upload
  //   if (currentControlIndex === null) {
  //     setCurrentControlIndex(sortedControls.findIndex((control) => control.controlLocationID === controlLocationID));
  //   } else {
  //     // Reset scrollToCalled to allow scrolling again after each upload
  //     setScrollToCalled(false);
  //   }
  //   /************************************/
  // };

  useEffect(() => {
    console.log("signatures", uploadedSignature, sortedControls);
  });

  const SignatureModalProps = {
    email,
    fullName,
    setFullName,
    registerSignID,
    controlLocationID,
    signatoriesID,
    selectedMasterDocID,
    showModal,
    setShowModal,
    controlName,
    controlID,
    initials,
    setInitials,
    auth,
    handleUploadedSignature,
    signatoryName,
    handleEnterText,
    textEditor,
    // setIsBulkSign
    // uploadedSignature
  };

  const handleCompleted = async () => {
    setIsLoading(true);
    try {
      if (uploadedSignature.length > 0) {
        const apiEndpoint = "/v1.0/api/UpdateControlLocationSignature";
        const requestBodies = [];

        for (const signatureItem of uploadedSignature) {
          const requestBody = {
            ControlLocationID: signatureItem.controlLocationID,
            MasterDocID: selectedMasterDocID,
            RegisterSignID: registerSignID,
            SignatoriesID: signatureItem.signatoriesID,
            ModifiedBy: email,
            IsSignature: signatureItem.controlName === "Signature" ? true : false,
            IsInitial: signatureItem.controlName === "Initial" ? true : false,
            IsStamp: signatureItem.controlName === "Stamp" ? true : false,
            Signature: signatureItem.uploadedImage,
            SignatureExtention: signatureItem.imageExtension,
          };

          requestBodies.push(requestBody);
        }

        //console.log(requestBodies);
        const response = await axios.put(apiEndpoint, requestBodies, {
          headers: {
            SignAuth: auth,
          },
        });

        if (response.data.successStatus) {
          setIsLoading(false);
          navigate("/signedMessage");
        }
      }

      setIsLoading(false);
    } catch (error) {
      // console.error("Error:", error);
    }
  };

  const handleViewOnly = async () => {
    const body = {
      MasterDocID: selectedMasterDocID,
      ModifiedBy: signatoryEmail,
    };
    // console.log(body)
    try {
      const response = await axios.put("/v1.0/api/UpdateViewedSignatoryStatus", body, {
        headers: {
          SignAuth: auth,
        },
      });

      if (response.data.statusCode === "1") {
        setIsConfirmationModalOpen(false);
        navigate("/viewMessage");
        // console.log("signatory viewed file")

        try {
          const response = await axios.post(
            "/v1.0/api/CreateSignActivityVerification",
            {
              Status: "Confirm View",
              Message: `${signatoryName} has viewed  [${fileName}] file`,
            },
            {
              headers: {
                signAuth: auth,
              },
            }
          );
        } catch (error) { }
      }
    } catch (error) {
      // console.log(error)
      toast.error("Error in proceeding request");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const pendingControls = allControlData && allControlData.filter((control) => control.controlStatus === "Pending");

    // Check if there are pending controls that require upload
    const requiresUpload = pendingControls && pendingControls.some((control) => control.controlName === "Stamp" || control.controlName === "Initial" || control.controlName === "Signature");

    if (!requiresUpload) {
      // If no controls requiring upload are found, disable the completed button
      setEnableCompletedButton(false);
      return; // Exit early
    }

    // Check if all controls that require upload have been uploaded
    const allControlIdsPresent =
      pendingControls &&
      pendingControls.every((control) => {
        if (
          (control.controlName === "Stamp" || control.controlName === "Initial" || control.controlName === "Signature") &&
          !uploadedSignature.some((item) => item.controlLocationID === control.controlLocationID)
        ) {
          // If any required control is not uploaded, return false
          return false;
        }
        return true;
      });

    // Enable or disable the completed button based on the check
    setEnableCompletedButton(allControlIdsPresent);
    setCompletedControls(allControlIdsPresent);
  }, [allControlData, uploadedSignature]);

  const handleCheckCompletedButton = () => {
    if (completedControls) {
      // console.log("completed ??", completedControls)
      setIsConfirmationModalOpen(true);
    } else {
      toast.error("There are pending signatories/initials/ in your document. Please complete them before proceeding.");
    }
  };

  const handleButtonClick = (controlLocationID, text) => {
    setTextInput(text);
    setEditingStates((prevState) => ({
      ...prevState,
      [controlLocationID]: true,
    }));
  };

  const handleInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleTextButton = async (controlLocationID) => {
    setIsLoading(true);
    try {
      const body = {
        ControlLocationID: controlLocationID,
        TextEditor: textInput,
      };

      const response = await axios.put("/v1.0/api/UpdateTextEditor", body, {
        headers: {
          SignAuth: auth,
        },
      });
      handleEnterText(textInput);

      try {
        const response = axios.post(
          "/v1.0/api/CreateSignActivityVerification",
          {
            Status: `Text is added`,
            Message: `${signatoryName} added text [${textInput}] `,
          },
          {
            headers: {
              SignAuth: auth,
            },
          }
        );

        //console.log(response);
      } catch (error) { }
      setShowModal(false);
    } catch (error) {
      toast.error("Something wrong.Try later");
      setShowModal(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDate = (controlLocationID) => {
    setShowDatePicker(true);
    setEditingStates((prevState) => ({
      ...prevState,
      [controlLocationID]: true,
    }));
  };

  const handleDateChange = (date, controlLocationID) => {
    if (date) {
      try {
        const selectedDateWithoutTime = date.toLocaleDateString();
        console.log(">>>", date, selectedDateWithoutTime);
        setSelectedDate(date); // Update selectedDate state with the full date object
        setTextInput(selectedDateWithoutTime);
        setEditingStates((prevState) => ({
          ...prevState,
          [controlLocationID]: false,
        }));
        setShowDatePicker(false);
      } catch (error) {
        console.error("Error occurred while handling date change:", error);
      }
    }
  };

  return (
    <div className='signaturePage'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <div className={`${passwordModal ? "blur-background" : ""}`}>
        <Row className='bg-white m-0 fixed-top '>
          <Col className='d-flex justify-content-between p-3 mx-3'>
            <img src={LogoImg} alt='imageLoading' style={{ height: "30px", width: "100px" }} />
            <LanguageSelector />
          </Col>
        </Row>

        <div className='signaturePage-container' style={{ marginTop: "54px" }}>
          <Row className='main-content px-0 mx-0 pt-2'>
            <Col md={2} className='comments-col p-0 border border-1 bg-white'>
              <Comments signAuth={auth} registerSignID={registerSignID} signatoryEmail={signatoryEmail} signatoryName={signatoryName} />
            </Col>

            <Col md={8} className='image-col p-0' style={{ background: "#eee" }}>
              <Row className='actions-btns ms-0 mb-2'>
                <div className='bg-white d-flex align-items-center justify-content-between px-3 border border-1' style={{ padding: "11px" }}>
                  <div className='signature-actions-tab d-flex gap-4 '>
                    <div className='action' onClick={() => handleFileDownload()}>
                      <img src={downloadIcon} alt='downloadIcon' />
                      <span> {t("signaturePage.download")}</span>
                    </div>

                    <div className='action' onClick={() => handleViewAuditTrail()}>
                      <img src={AuditTrailIcon} alt='AuditTrailIcon' />
                      <span> {t("signaturePage.viewAuditTrail")}</span>
                    </div>

                    <div className='action' onClick={() => setShowDeclineModal(true)}>
                      <img src={declinedIcon} alt='declinedIcon' />
                      <span>{t("signaturePage.declined")}</span>
                    </div>

                    <AuditTrailModal
                      show={showATModal}
                      auditTrailURL={auditTrailURL}
                      handleClose={() => {
                        setShowATModal(false);
                        setAuditTrailURL("");
                      }}
                    />

                    <Modal show={showDeclineModal} onHide={closeDeclineModal} dialogClassName='custom-modal-width'>
                      <Modal.Header closeButton>
                        <Modal.Title>{t("signaturePage.declineDocument")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className='px-5 py-4'>
                        <Form>
                          <Form.Group controlId='reason'>
                            <Form.Label>
                              {t("signaturePage.reasonDecliningDocument")} <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control as='textarea' rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant='none' onClick={closeDeclineModal} className='py-1 modalCancelButton h-45'>
                          {t("signaturePage.close")}
                        </Button>
                        <Button variant='none' className='applyBtn  py-1 text-white fw-normal' onClick={handleFileDecline} disabled={isLoading}>
                          {/* {isLoading ? "Submitting..." : "Submit"} */}
                          {t("signaturePage.submit")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    {/* <PageNavigations imagesData={imagesData} selectedImage={selectedImage} setSelectedImage={setSelectedImage} onImageSelect={handleImageSelect} /> */}
                  </div>

                  <div>
                    <Button variant='none' onClick={scrollToNextControl} className='applyBtn w-100 '>
                      {currentControlIndex === 0 ? "Start" : "Next Step"}
                      {/* {t("signaturePage.next")} */}
                    </Button>
                  </div>

                  <AuditTrailModal
                    show={showATModal}
                    auditTrailURL={auditTrailURL}
                    handleClose={() => {
                      setShowATModal(false);
                      setAuditTrailURL("");
                    }}
                  />

                  <Modal show={showDeclineModal} onHide={closeDeclineModal} dialogClassName='custom-modal-width'>
                    <Modal.Header closeButton>
                      <Modal.Title>{t("signaturePage.declineDocument")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='px-5 py-4'>
                      <Form>
                        <Form.Group controlId='reason'>
                          <Form.Label>
                            {t("signaturePage.reasonDecliningDocument")} <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control as='textarea' rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant='none' onClick={closeDeclineModal} className='py-1 modalCancelButton'>
                        {t("signaturePage.close")}
                      </Button>
                      <Button variant='none' className='applyBtn  py-1 text-white fw-normal' onClick={handleFileDecline} disabled={isLoading}>
                        {/* {isLoading ? "Submitting..." : "Submit"} */}
                        {t("signaturePage.submit")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Row>

              <Row className='mx-0 p-0' style={{ marginTop: "67px" }}>
                <div
                  className='main-document scrollable-content  px-0 px-md-2'
                  ref={mainDocumentRef}
                  style={{
                    position: "relative",
                  }}
                >
                  <div className='large-image' style={{ position: "relative", marginTop: "5px" }}>
                    <div
                      className='doc-image'
                      style={{
                        // width: "100%",
                        position: "relative",
                        height: "auto",
                      }}
                    >
                      {sortedControls.length > 0 && (

                        <div
                          ref={indicatorRef}
                          className='indicator'
                          id='indicator'
                          style={{
                            position: "absolute",
                            top: `${sortedControls[0].controlLocationY}`,
                            left: "-6px",
                          }}
                        >
                          <img src={ArrowAnimation} alt="arrow-animation" style={{ width: "100px", height: "45px" }} />
                        </div>
                      )}

                      <img src={selectedImage} alt='loading' />

                      {controlData.map((control, index) => {
                        return (
                          control.masterDocPagesID === masterDocPagesId &&
                          (control.controlStatus === "Pending" ? (
                            <div
                              key={control.controlLocationID}
                              id={control.controlLocationID}
                              ref={(ref) => {
                                // Store a reference to each control element
                                if (ref && !controlRefs.includes(ref)) {
                                  setControlRefs((prevRefs) => [...prevRefs, ref]);
                                }
                              }}
                              className='draggable-button-container'
                              style={{
                                position: "absolute",
                                left: control.controlLocationX,
                                top: control.controlLocationY,
                              }}
                              title={control.email + " " + control.name}
                            >
                              {control.controlName === "Date Signed" || (control.controlName === "Company" && control.company === "") ? null : (
                                <Button
                                  className='field-button p-0'
                                  variant='none'
                                  style={{
                                    width: control.width,
                                    height: control.height,
                                    border:
                                      (control.signatureColor &&
                                        (control.controlName === "Signature" || control.controlName === "Initial" || control.controlName === "Stamp") &&
                                        !uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID)) ||
                                        (control.controlName !== "Signature" && control.controlName !== "Initial" && control.controlName !== "Stamp" && control.controlName !== "QR Code")
                                        ? `2px dotted ${control.signatureColor}`
                                        : "transparent",
                                    backgroundColor:
                                      ((control.controlName === "Signature" || control.controlName === "Initial" || control.controlName === "Stamp") &&
                                        !uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID)) ||
                                        (control.controlName !== "Signature" && control.controlName !== "Initial" && control.controlName !== "Stamp")
                                        ? "#ffffff"
                                        : "transparent",
                                    cursor:
                                      control.controlName === "Signature" ||
                                        control.controlName === "Initial" ||
                                        control.controlName === "Stamp" ||
                                        control.controlName === "Text" ||
                                        control.controlName === "Number" ||
                                        control.controlName === "Date"
                                        ? "pointer"
                                        : "auto",
                                  }}
                                  onClick={() => {
                                    if (control.controlName === "Signature" || control.controlName === "Initial" || control.controlName === "Stamp") {
                                      if (control.name) {
                                        const nameParts = control.name.split(" ");
                                        const initial = nameParts.map((part) => part.charAt(0)).join("");
                                        setFullName(control.name);
                                        setInitials(initial);
                                      }
                                      openModal(control.controlLocationID, control.signatoriesID, control.email, control.controlName, control.controlID, control.textEditor);
                                    }
                                    if (control.controlName === "Text" || control.controlName === "Number") {
                                      handleButtonClick(control.controlLocationID, control.textEditor);
                                    }
                                    if (control.controlName === "Date") {
                                      handleDate(control.controlLocationID);
                                    }
                                  }}
                                >
                                  {control.controlName === "Signature" ? (
                                    uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID) ? (
                                      <>
                                        <img
                                          src={`data:image/png;base64, ${uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID).uploadedImage}`}
                                          alt='Uploaded Signature Image'
                                          style={{
                                            width: control.width,
                                            height: control.height,
                                            border: "none",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      "Sign Here"
                                    )
                                  ) : control.controlName === "Initial" ? (
                                    uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID) ? (
                                      <>
                                        <img
                                          src={`data:image/png;base64, ${uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID).uploadedImage}`}
                                          alt='Uploaded initials Image'
                                          style={{
                                            width: control.width,
                                            height: control.height,
                                            border: "none",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      "Initials"
                                    )
                                  ) : control.controlName === "Stamp" ? (
                                    uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID) ? (
                                      <>
                                        {" "}
                                        <img
                                          src={`data:image/png;base64, ${uploadedSignature.find((item) => item.controlLocationID === control.controlLocationID).uploadedImage}`}
                                          alt='Uploaded stamp Image'
                                          style={{
                                            width: control.width,
                                            height: control.height,
                                            border: "none",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      "Stamp"
                                    )
                                  ) : (
                                    <>
                                      {control.controlName === "Text" &&
                                        (editingStates[control.controlLocationID] ? (
                                          <textarea
                                            style={{
                                              width: control.width,
                                              height: control.height,
                                              overflow: "auto",
                                              resize: "none",
                                            }}
                                            value={textInput}
                                            onChange={(event) => handleInputChange(event)}
                                            onBlur={() => {
                                              // Clear editing state for this control when blurred
                                              setEditingStates((prevState) => ({
                                                ...prevState,
                                                [control.controlLocationID]: false,
                                              }));
                                              handleTextButton(control.controlLocationID);
                                            }}
                                            autoFocus
                                          />
                                        ) : (
                                          <div style={{ width: control.textEditor ? "100%" : "", height: control.textEditor ? "100%" : "", display: "flex", flexWrap: "wrap" }}>
                                            {control.textEditor ? control.textEditor : "Enter Text"}
                                          </div>
                                        ))}

                                      {control.controlName === "Number" &&
                                        (editingStates[control.controlLocationID] ? (
                                          <input
                                            style={{
                                              width: control.width,
                                              height: control.height,
                                              overflow: "auto",
                                              resize: "none",
                                            }}
                                            type='number'
                                            value={textInput}
                                            onChange={(event) => handleInputChange(event)}
                                            onBlur={() => {
                                              setEditingStates((prevState) => ({
                                                ...prevState,
                                                [control.controlLocationID]: false,
                                              }));
                                              handleTextButton(control.controlLocationID);
                                            }}
                                            autoFocus
                                          />
                                        ) : (
                                          <div style={{ width: control.textEditor ? "100%" : "", height: control.textEditor ? "100%" : "", display: "flex", flexWrap: "wrap" }}>
                                            {control.textEditor ? control.textEditor : "Enter Number"}
                                          </div>
                                        ))}

                                      {control.controlName === "Date" &&
                                        (!showDatePicker && !editingStates[control.controlLocationID] ? (
                                          <div style={{ width: control.textEditor ? "100%" : "", height: control.textEditor ? "100%" : "", display: "flex", flexWrap: "wrap" }}>
                                            {control.textEditor ? control.textEditor : "Date"}
                                          </div>
                                        ) : (
                                          <DatePicker
                                            selected={selectedDate}
                                            onChange={(date) => handleDateChange(date, control.controlLocationID)}
                                            onBlur={() => {
                                              setEditingStates((prevState) => ({
                                                ...prevState,
                                                [control.controlLocationID]: false,
                                              }));
                                              handleTextButton(control.controlLocationID);
                                            }}
                                          />
                                        ))}

                                      {control.controlName === "Name" && control.name}
                                      {control.controlName === "Email" && control.email}
                                      {control.controlName === "Stamp" && "Stamp"}
                                      {control.controlName === "Company" && control.company}
                                      {control.controlName === "QR Code" && control.qrCodePath && (
                                        <img src={`${control.qrCodePath}`} style={{ height: control.height, width: control.width }} alt='QR Code' />
                                      )}
                                    </>
                                  )}
                                </Button>
                              )}
                            </div>
                          ) : (
                            control.controlStatus === "Signed" &&
                            !isToastDisplayed && (
                              <div
                                key={index}
                                className='draggable-button-container'
                                style={{
                                  position: "absolute",
                                  left: control.controlLocationX,
                                  top: control.controlLocationY,
                                }}
                              >
                                {control.controlName === "Company" && control.company === "" ? null : (
                                  <Button
                                    className='field-button'
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "black",
                                      border: "none",
                                      width: control.width,
                                      height: control.height,
                                    }}
                                    title={control.email + " " + control.name}
                                  >
                                    {/* Render button content based on control name */}
                                    {control.controlName === "Signature" && (
                                      <img
                                        src={control.signature}
                                        alt='Signature Image'
                                        style={{
                                          width: control.width,
                                          height: control.height,
                                          border: "none",
                                        }}
                                      />
                                    )}
                                    {control.controlName === "Name" && control.name}
                                    {control.controlName === "Initial" && (
                                      <img
                                        src={control.signature}
                                        alt='Initial Image'
                                        style={{
                                          width: control.width,
                                          height: control.height,
                                          border: "none",
                                        }}
                                      />
                                    )}
                                    {control.controlName === "Email" && control.email}{" "}
                                    {control.controlName === "Stamp" && (
                                      <im
                                        src={control.signature}
                                        alt='Stamp Image'
                                        style={{
                                          width: control.width,
                                          height: control.height,
                                          border: "none",
                                        }}
                                      />
                                    )}
                                    {control.controlName === "Company" && control.company}
                                    {control.controlName === "Date Signed" && control.signDate}
                                    {control.controlName === "Text" && control.textEditor}
                                    {control.controlName === "Number" && control.textEditor}
                                    {control.controlName === "Date" && control.textEditor}
                                    {control.controlName === "QR Code" && control.qrCodePath && (
                                      <img src={`${control.qrCodePath}`} style={{ height: control.height, width: control.width }} alt='QR Code' />
                                    )}
                                  </Button>
                                )}
                              </div>
                            )
                          ))
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Row>
            </Col>

            <Col md={2} className='page-display p-0' >
              <div className='documents-dropdown text-center d-flex align-items-center py-1  m-2'>
                <DropdownButton
                  title={selectedFolder || ""}
                  onSelect={(eventKey) => {
                    const [masterDocID, selectedFolderName, signedDocKey] = eventKey.split(",");
                    handleDocSelect(masterDocID, selectedFolderName, signedDocKey);
                  }}
                  id='doc-dropdown'
                  className='border border-0 w-100'
                  variant='none'
                >
                  {formData &&
                    formData.primaryDocs.map((doc) => {
                      const truncatedName = doc.docName.length > 10 ? doc.docName.slice(0, 7) + "..." : doc.docName;
                      return (
                        <Dropdown.Item key={doc.masterDocID} eventKey={`${doc.masterDocID},${doc.docName},${doc.signedDocKey}`} className='truncate-text'>
                          {doc.docName}
                        </Dropdown.Item>
                      );
                    })}
                </DropdownButton>
              </div>
              <hr className='m-0' />
              <div className='doc-pages d-none d-md-block'>
                <ImageThumbnails
                  controls={allControlData}
                  t={t}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  setMasterDocPagesId={setMasterDocPagesId}
                  imagesData={imagesData}
                  setImagesData={setImagesData}
                  buttons={false}
                />
              </div>

              <div className='completed-btn d-flex justify-content-center align-items-center p-2'>
                {viewOnly ? (
                  <>
                    <div className='signbutton-container'>
                      <div></div>
                      <Button className='Continue-btn' variant='none' onClick={() => setIsConfirmationModalOpen(true)}>
                        {t("signaturePage.confirm")}
                      </Button>
                    </div>

                    <ConfirmationModal show={isConfirmationModalOpen} onHide={() => setIsConfirmationModalOpen(false)} onClick={handleViewOnly} />
                  </>
                ) : (
                  <>
                    <div className='signbutton-container'>
                      <div className='d-flex justify-content-center m-auto'>
                        <PageNavigations imagesData={imagesData} selectedImage={selectedImage} setSelectedImage={setSelectedImage} onImageSelect={handleImageSelect} />
                      </div>

                      <Button className='Continue-btn' variant='none' onClick={handleCheckCompletedButton}>
                        {t("signaturePage.completed")}
                      </Button>
                    </div>
                    <ConfirmationModal show={isConfirmationModalOpen} onHide={() => setIsConfirmationModalOpen(false)} onClick={handleCompleted} />
                  </>
                )}
              </div>
            </Col>
          </Row>

          <SignatureModal {...SignatureModalProps} />
        </div>
      </div>
      {
        passwordModal && (
          <PasswordModal
            show={passwordModal}
            closeModal={() => setPasswordModal(false)}
            auth={auth}
            signatoryID={signatoryID}
            signatoryName={signatoryName}
            passwordProtected={passwordProtected}
            defaultMasterDocID={defaultMasterDocID}
          />
        )
      }
    </div>
  );
};

export default SignaturePage;
