import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import "./LoginCarousel.css";
import UploadPic from "../../assets/images/uplaod-pic.png";
import sl2Image from "../../assets/images/s12.png";
import thumbsUp from "../../assets/images/thumbsUp.png";
import sl3Image from "../../assets/images/s13.png";
import fileImage from "../../assets/images/file.png";
import handshake from "../../assets/images/shakehand.png";
import emailImg from "../../assets/images/email.png";
import PenImg from "../../assets/images/pen.png";

const LoginCarousel = () => {
  const { t } = useTranslation();
  return (
    <Carousel data-bs-theme='dark' controls={false}>
      <Carousel.Item interval={5000}>
        <div className='carousel-content'>
          <div>
            <label className='fw-bolder mt-5 mb-2 carsol-desc'>{t("carousel.uploadDoc")}</label>
            <br />
            <label className='carousel-label mb-2'>{t("carousel.pdfImage")}</label>
            <label className='carousel-paragraph mb-2'>{t("carousel.uplaodImpDocs")}</label>
            <label className='carousel-paragraph'>{t("carousel.getStartedPath")}</label>
          </div>

          <div className='d-flex align-items-center'>
            <div style={{ display: "flex", flexDirection: "column", gap: "6rem" }}>
              <img src={fileImage} alt='' style={{ height: "50px", width: "50px", marginLeft: "-50px", marginRight: "-50px" }} />
              <img src={thumbsUp} alt='' style={{ height: "50px", width: "50px" }} />
            </div>
            <img src={UploadPic} alt='' className='carousel-image' />
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <div className='carousel-content '>
          <div style={{ display: "flex", justifyContent: "end", marginLeft: "-30px", marginRight: "-30px" }}>
            <img src={handshake} alt='' style={{ height: "50px", width: "50px" }} />
          </div>
          <label className='fw-bolder mb-2  mt-2 carsol-desc'>{t("carousel.specifyPart")}</label>
          <br />
          <label className='carousel-label mb-2'>{t("carousel.addSignatoryorSign")}</label>
          <label className='carousel-paragraph mb-2'>{t("carousel.easilySpecify")}</label>
          <label className='carousel-paragraph'>{t("carousel.PlatformEmpowers")}</label>
          <div className='d-flex justify-content-center'>
            <img className='carousel-image-2' src={sl2Image} alt='img' />
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={5000}>
        <div className='carousel-content '>
          <label className='fw-bolder mt-5 mb-3 carsol-desc'>{t("carousel.sendSign")}</label>
          <br />
          <label className='carousel-label mb-3'>{t("carousel.shareSignedDocs")}</label>
          <br />
          <label className='carousel-paragraph mb-2'>{t("carousel.electronicallySigning")}</label>
          <br />
          <label className='carousel-paragraph'>{t("carousel.timelyCollaboration")}</label>

          <div className='d-flex gap-5'>
            <div className=' d-flex align-items-center justify-content-center'>
              <img className='carousel-content-3' src={emailImg} alt='' />
              <img className='carousel-image-arabic' src={sl3Image} alt='' />
            </div>
            <img className='pen-image' src={PenImg} alt='' />
          </div>

          {/* <div className='d-flex gap-5'>
            <div className='carousel-content-3 d-flex align-items-center'>
              <img className='email-image' src={emailImg} alt='img' />
              <img className='carousel-image-arabic' src={sl3Image} alt='' />
            </div>
            <img className='pen-image' src={PenImg} alt='' />
          </div> */}
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default LoginCarousel;
