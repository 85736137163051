import React, { useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { GrDrag } from "react-icons/gr";
import Tooltip from "react-bootstrap/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Col, Container, Form, Row, Accordion, Modal } from "react-bootstrap";
import "./AddRecipient.css";
import axios from "../../../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SignatoryIcon from "../../../../assets/images/path.svg";
import { CiCirclePlus } from "react-icons/ci";
import Loader from "../../../../Components/Loader/Loader";

const AddRecipient = ({ onRecipientFormsValidation, onRecipientFormsLength, TemplateID, uploadedFiles }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [formNumbers, setFormNumbers] = useState([]);
  const [signatoryCreatedStates, setSignatoryCreatedStates] = useState([]);
  const [signatureTypes, setSignatureTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [signatoryColor, setSignatoryColor] = useState([]);
  const [formColorMap, setFormColorMap] = useState({});
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingSignatoryID, setDeletingSignatoryID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [formData, setFormData] = useState();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [recipientForms, setRecipientForms] = useState([
    {
      name: "",
      notificationType: "Email",
      email: "",
      countryCode: "",
      phone: "",
      company: "",
      signOrder: 1,
      signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
      signColor: signatoryColor.length > 0 ? signatoryColor[0] : "#ffe987",
    },
  ]);

  const token = sessionStorage.getItem("token");
  const storedRegisterSignID = localStorage.getItem("registerSignID");
  const storedTemplateID = localStorage.getItem("templateID");

  console.log(recipientForms);

  useEffect(() => {
    const recipientFormsLength = recipientForms.length;
    onRecipientFormsLength(recipientFormsLength);
  });

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=signatureType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const signatureTypeOptions = response.data.response.map((item) => item.name);
          setSignatureTypes(signatureTypeOptions);
          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              signatureType: signatureTypeOptions[0],
            },
          ]);
        }
      })
      .catch((error) => {
        if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=NotificationType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const notificationTypeOptions = response.data.response.map((item) => item.name);
          setNotificationTypes(notificationTypeOptions);

          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              notificationType: "Email",
            },
          ]);
        }
      })
      .catch((error) => {
        if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=CountryCode", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const countryCode = response.data.response.map((code) => code.name);
          setCountryCodes(countryCode);

          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              countryCode: "",
            },
          ]);
        }
      })
      .catch((error) => {
        if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=SignColor", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const formColors = response.data.response.map((color) => color.name);
          setSignatoryColor(formColors);

          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              signColor: formColors[0],
            },
          ]);

          setFormColorMap({ 0: 0 });
        }
      })
      .catch((error) => {
        if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetTemplateData?TemplateID=${storedTemplateID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        setIsLoading(false);
        if (Array.isArray(response.data.response.primaryDocs)) {
          setFormData(response.data.response);
        }


      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (formData && formData.templateSignatory && formData.templateSignatory.length > 0) {
      const signatoryColors = formData.templateSignatory.map((signatory) => signatory.signatureColor);

      const newForms = Array.from({ length: formData.templateSignatory.length }, (_, i) => {
        return {
          ...formData.templateSignatory[i],
          signColor: signatoryColors[i],
        };
      });
      const newColorIndex = newForms.length;
      setCurrentColorIndex(newColorIndex);
      setRecipientForms([
        ...newForms,
        {
          name: "",
          notificationType: "Email",
          email: "",
          countryCode: "",
          phone: "",
          company: "",
          signOrder: formData.templateSignatory.length + 1,
          signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
          signColor: signatoryColor[newColorIndex],
        },
      ]);
    }
    setIsLoading(false);
  }, [formData, setIsLoading]);

  useEffect(() => {
    updateFormNumbers();
  }, [recipientForms]);

  const updateFormNumbers = () => {
    const numbers = recipientForms.map((_, index) => index + 1);
    setFormNumbers(numbers);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {t("uploadDocFlow.onlySignerInstruction")}
    </Tooltip>
  );

  const handleCreateRecipient = async (index) => {
    if (uploadedFiles.length === 0) {
      toast.error("Please upload a file before creating a signatory");

      return;
    }
    setCurrentColorIndex(recipientForms.length - 1);

    setIsLoading(true);

    const requestBody = {
      TemplateID: TemplateID,
      Name: recipientForms[recipientForms.length - 1].name,
      NotificationType: recipientForms[recipientForms.length - 1].notificationType,
      Email: recipientForms[recipientForms.length - 1].email,
      CountryCode: recipientForms[recipientForms.length - 1].countryCode,
      phone: recipientForms[recipientForms.length - 1].phone,
      Company: recipientForms[recipientForms.length - 1].company,
      SignOrder: recipientForms.length,
      SignatureType: recipientForms[recipientForms.length - 1].signatureType,
      SignatureColor: signatoryColor[currentColorIndex],
      ExpiryDate: recipientForms[recipientForms.length - 1].expiryDate,
      isExpiring: recipientForms[recipientForms.length - 1].isExpiring,
    };

    try {
      const response = await axios.post("/v1.0/api/CreateTemplateSignatory", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        fetchData();
      }

      toast.success(t("uploadDocFlow.signatoryCreated"));
      setIsLoading(false);
    } catch (error) {
      console.log("error", error)
      setIsLoading(false);
      if (error?.response?.data?.statusCode === 2 || error?.response?.data?.statusCode === 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errorSignatory"));
      }
      return
    }

    const updatedSignatoryStates = [...signatoryCreatedStates];
    updatedSignatoryStates.push(true);
    setSignatoryCreatedStates(updatedSignatoryStates);

    localStorage.setItem("signatoryCreatedStates", JSON.stringify(updatedSignatoryStates));

    setCurrentColorIndex((currentIndex) => (currentIndex + 1) % signatoryColor.length);
  };

  const handleFormChange = (index, field, value) => {
    const newRecipientForms = [...recipientForms];
    newRecipientForms[index][field] = value;
    setRecipientForms(newRecipientForms);
    const isValid = newRecipientForms.every((form) => form.name !== "" && form.email !== "");

    onRecipientFormsValidation(isValid);
  };

  const handleEditRecipient = async (signatoryID, index) => {
    const updatedFormData = {
      TemplateSignatoryID: signatoryID,
      Name: recipientForms[index].name,
      NotificationType: recipientForms[index].notificationType,
      Email: recipientForms[index].email,
      CountryCode: recipientForms[index].countryCode,
      phone: recipientForms[index].phone,
      Company: recipientForms[index].company,
      SignOrder: recipientForms[index].signOrder,
      SignatureType: recipientForms[index].signatureType,
      SignatureColor: recipientForms[index].signColor,
      ExpiryDate: recipientForms[index].expiryDate,
      isExpiring: recipientForms[index].isExpiring,
    };

    setIsLoading(true);

    try {
      const response = await axios.put("/v1.0/api/UpdateTemplateSignatory", updatedFormData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      toast.success(t("uploadDocFlow.formUpdate"));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errFormUpdate"));
      }
    }
  };

  // confirmation modal
  const handleShowConfirmModal = (templateSignatoryID, index) => {
    setDeletingSignatoryID(templateSignatoryID);
    setDeletingIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination || !recipientForms[result.source.index]) {
      return;
    }

    const reorderedForms = Array.from(recipientForms);
    const [removed] = reorderedForms.splice(result.source.index, 1);
    let emptyFormIndex = result.destination.index;
    while (emptyFormIndex < reorderedForms.length && reorderedForms[emptyFormIndex]) {
      emptyFormIndex++;
    }
    if (result.destination.index < emptyFormIndex) {
      reorderedForms.splice(result.destination.index, 0, removed);

      reorderedForms.forEach(async (form, index) => {
        form.signOrder = index + 1;
      });

      setRecipientForms(reorderedForms);
      updateFormNumbers();

      const updatedSignOrder = recipientForms.map((form, index) => ({
        TemplateSignatoryID: form.templateSignatoryID,
        SignOrder: form.signOrder,
        Name: form.name,
        NotificationType: form.notificationType,
        Email: form.email,
        CountryCode: form.countryCode,
        phone: form.phone,
        Company: form.company,
        SignatureType: form.signatureType,
        SignatureColor: form.signColor,
        ExpiryDate: form.expiryDate,
        IsExpiring: form.IsExpiring,
      }));

      updateSignOrdersInDatabase(updatedSignOrder);
    }
  };

  const updateSignOrdersInDatabase = async (updatedSignOrders) => {
    try {
      const updatedDataPromises = updatedSignOrders.map(async (updatedFormData) => {
        const response = await axios.put("/v1.0/api/UpdateTemplateSignatory", updatedFormData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return response.data;
      });

      // const updatedData = await Promise.all(updatedDataPromises);
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
  };

  // const updateSignOrderAPI = async (updatedSignOrder) => {
  //   try {
  //     const response = await axios.put("/v1.0/api/UpdateTemplateSignatory", updatedSignOrder, {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     });
  //   } catch (error) {
  //     if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
  //       sessionStorage.removeItem("token");
  //       localStorage.clear();
  //       setIsLoading(false);
  //       navigate("/");
  //       toast.info(t("uploadDocFlow.sessionExpire"), {
  //         position: "top-right",
  //       });
  //     }
  //   }
  // };

  const handleDeleteRecipient = async (signatoryID, index) => {
    setIsLoading(true);

    try {
      const response = await axios.put(
        "/v1.0/api/DeleteTemplateSignatory",
        {
          TemplateSignatoryID: signatoryID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        const updatedRecipientForms = [...recipientForms];
        updatedRecipientForms.splice(index, 1);
        setRecipientForms(updatedRecipientForms);

        const updatedSignatoryStates = [...signatoryCreatedStates];
        updatedSignatoryStates.splice(index, 1);
        setSignatoryCreatedStates(updatedSignatoryStates);

        setCurrentColorIndex((currentIndex) => (currentIndex - 1 + signatoryColor.length) % signatoryColor.length);

        toast.success(t("uploadDocFlow.signatoryDeleted"));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.ErrDeletSignatory"));
      }
    } finally {
      handleCloseConfirmModal();
    }
  };

  return (
    <Container className='mb-3 p-0 mt-3'>
      <Accordion defaultActiveKey={["10"]} alwaysOpen className='accordion'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <div className='d-flex align-items-center gap-3'>
              <img alt='IconLoading' src={SignatoryIcon} />
              <h2 className='mb-0'>{t("uploadDocFlow.addSignatory")}</h2>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {isLoading && (
              <div className='loader-overlay'>
                <Loader/>
              </div>
            )}

            <div className='mb-3'></div>
            <div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='droppable' direction='vertical'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {recipientForms &&
                        recipientForms.map((form, index) => {
                          const isSignatoryCreated = localStorage.getItem(signatoryCreatedStates[index]);

                          { console.log(isSignatoryCreated, index) }

                          return (
                            <Draggable key={index} draggableId={index.toString()} index={index} bounds='parent' isDragDisabled={!isSignatoryCreated}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    backgroundColor: snapshot.isDragging ? "lightgray" : "white",
                                  }}
                                >
                                  <Form
                                    className='recipient-form d-flex flex-md-row  justify-content-start'
                                    key={index}
                                    index={index}
                                    style={{
                                      borderLeft: `10px solid ${recipientForms[index].signColor}`,
                                      borderRight: `1px solid ${recipientForms[index].signColor}`,
                                      borderTop: `1px solid ${recipientForms[index].signColor}`,
                                      borderBottom: `1px solid ${recipientForms[index].signColor}`,
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <div>
                                      <div className='d-flex  align-items-center justify-content-between'>
                                        <div className='d-flex gap-3 align-items-center'>
                                          <Col md={1} className='temp-drag-handle  me-2' {...provided.dragHandleProps}>
                                            <GrDrag />
                                          </Col>
                                          <div className='temp-form-number-box'>
                                            {t("uploadDocFlow.signatory")}
                                            {formNumbers[index]}
                                          </div>
                                        </div>

                                        {/* Delete Signatory button */}
                                        {form.templateSignatoryID ? (
                                          <Button variant='' className='deleteBtn m-0' onClick={() => handleShowConfirmModal(form.templateSignatoryID, index)}>
                                            {t("uploadDocFlow.delete")}
                                          </Button>
                                        ) : null}

                                      </div>

                                      <Col md={11} className='mt-3'>
                                        <Row className='form-fields d-flex flex-row align-items-center '>
                                          <Row>
                                            <Col md={4}>
                                              <Form.Group controlId={`nameField-${index}`}>
                                                <Form.Label>{t("uploadDocFlow.name")}</Form.Label>
                                                <Form.Control type='text' value={form.name} onChange={(e) => handleFormChange(index, "name", e.target.value)} />
                                              </Form.Group>
                                            </Col>
                                            <Col md={4} className=''>
                                              <Form.Group controlId={`companyField-${index}`}>
                                                <Form.Label>{t("uploadDocFlow.company")}</Form.Label>
                                                <Form.Control type='text' value={form.company} onChange={(e) => handleFormChange(index, "company", e.target.value)} />
                                              </Form.Group>
                                            </Col>

                                            <Col md={4} className=''>
                                              <Form.Group controlId={`notificationTypeField-${index}`}>
                                                <Form.Label>{t("uploadDocFlow.notificationType")}</Form.Label>
                                                <Form.Select
                                                  name={`notificationType-${index}`}
                                                  value={form.notificationType}
                                                  onChange={(e) => handleFormChange(index, "notificationType", e.target.value)}
                                                >
                                                  {notificationTypes.map((option) => (
                                                    <option key={option} value={option}>
                                                      {option}
                                                    </option>
                                                  ))}
                                                </Form.Select>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            {form.notificationType === "Email" && (
                                              <Col md={4} className='pt-1 mt-2'>
                                                <Form.Group controlId={`emailField-${index}`}>
                                                  <Form.Label>{t("uploadDocFlow.email")}</Form.Label>
                                                  <Form.Control type='email' value={form.email} onChange={(e) => handleFormChange(index, "email", e.target.value)} />
                                                </Form.Group>
                                              </Col>
                                            )}

                                            {form.notificationType === "SMS" && (
                                              <>
                                                <Col md={4} className='pt-1 mt-2'>
                                                  <Form.Group controlId={`countryCodeField-${index}`}>
                                                    <Form.Label>{t("uploadDocFlow.country")}</Form.Label>
                                                    <Form.Control as='select' value={form.countryCode || ""} onChange={(e) => handleFormChange(index, "countryCode", e.target.value)}>
                                                      <option value='' disabled>
                                                        {t("uploadDocFlow.selectCode")}
                                                      </option>
                                                      {countryCodes.map((code) => (
                                                        <option key={code} value={code}>
                                                          {code}
                                                        </option>
                                                      ))}
                                                    </Form.Control>
                                                  </Form.Group>
                                                </Col>
                                                <Col md={4} className='pt-1 mt-2'>
                                                  <Form.Group controlId={`phoneField-${index}`}>
                                                    <Form.Label>{t("uploadDocFlow.Phone")}</Form.Label>
                                                    <Form.Control type='tel' value={form.phone} onChange={(e) => handleFormChange(index, "phone", e.target.value)} />
                                                  </Form.Group>
                                                </Col>
                                              </>
                                            )}
                                            {form.notificationType === "Email" ||
                                              (form.notificationType === "All" && (
                                                <>
                                                  <Col md={4} className='pt-1 mt-2'>
                                                    <Form.Group controlId={`countryCodeField-${index}`}>
                                                      <Form.Label>{t("uploadDocFlow.country")}</Form.Label>
                                                      <Form.Control as='select' value={form.countryCode || ""} onChange={(e) => handleFormChange(index, "countryCode", e.target.value)}>
                                                        <option value='' disabled>
                                                          {t("uploadDocFlow.selectCode")}
                                                        </option>
                                                        {countryCodes.map((code) => (
                                                          <option key={code} value={code}>
                                                            {code}
                                                          </option>
                                                        ))}
                                                      </Form.Control>
                                                    </Form.Group>
                                                  </Col>
                                                  <Col md={4} className='pt-1 mt-2'>
                                                    <Form.Group controlId={`phoneField-${index}`}>
                                                      <Form.Label>{t("uploadDocFlow.Phone")}</Form.Label>
                                                      <Form.Control type='tel' value={form.phone} onChange={(e) => handleFormChange(index, "phone", e.target.value)} />
                                                    </Form.Group>
                                                  </Col>
                                                  <Col md={6} className='pt-1 mt-2'>
                                                    <Form.Group controlId={`emailField-${index}`}>
                                                      <Form.Label>{t("uploadDocFlow.email")}</Form.Label>
                                                      <Form.Control type='email' value={form.email} onChange={(e) => handleFormChange(index, "email", e.target.value)} />
                                                    </Form.Group>
                                                  </Col>
                                                </>
                                              ))}
                                          </Row>
                                          <Row className='mt-3 d-flex align-items-center'>
                                            <Col md={7}>
                                              <div className='radio-group'>
                                                {signatureTypes.map((option) => (
                                                  <label className='particles-checkbox-container' key={option}>
                                                    <input
                                                      type='radio'
                                                      name={`optionField-${index}`}
                                                      value={option}
                                                      checked={recipientForms[index].signatureType === option}
                                                      onChange={() => handleFormChange(index, "signatureType", option)}
                                                      className='temp-particles-checkbox'
                                                    />
                                                    <span>{option}</span>
                                                  </label>
                                                ))}
                                              </div>
                                            </Col>

                                            <Col md={6} className='recipient-form-buttons mt-3 d-flex flex-row '>
                                              {form.templateSignatoryID ? (
                                                <>
                                                  <Button variant='' className='applyBtn m-0 mx-2 px-3' onClick={() => handleEditRecipient(form.templateSignatoryID, index)}>
                                                    {t("uploadDocFlow.save")}
                                                  </Button>
                                                  {/* <Button variant='' className='deleteBtn m-0' onClick={() => handleShowConfirmModal(form.templateSignatoryID, index)}>
                                                    {t("uploadDocFlow.delete")}
                                                  </Button> */}
                                                </>
                                              ) : (
                                                // <Button variant='' onClick={() => handleCreateRecipient(index)} className='saveBtn m-0'>
                                                //   {t("uploadDocFlow.create")}
                                                // </Button>
                                                <></>
                                              )}
                                            </Col>
                                          </Row>
                                        </Row>
                                      </Col>
                                    </div>
                                  </Form>

                                  {/* Create Signatory Button */}

                                  {!form.templateSignatoryID && (
                                    <Button variant='' onClick={() => handleCreateRecipient(index)} className='createBtn m-0'>
                                      <CiCirclePlus size={25} />
                                      {t("uploadDocFlow.AddNewSigner")}
                                    </Button>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            {/* delete signatory Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
              <Modal.Header closeButton>
                <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.sureToDelete")}</Modal.Body>
              <Modal.Footer>
                <Button variant='' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                  {t("uploadDocFlow.cancel")}
                </Button>
                <Button className='applyBtn' variant='none' onClick={() => handleDeleteRecipient(deletingSignatoryID, deletingIndex)}>
                  {t("uploadDocFlow.delete")}
                </Button>
              </Modal.Footer>
            </Modal>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default AddRecipient;
