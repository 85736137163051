import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { Button, Accordion, Container, Row, Col, Modal, Form } from "react-bootstrap";
import apiIcon from "../../assets/images/ApiIcon.svg";
import CreateIntegration from "./CreateIntegration";
import UpdateIntegrationModal from "./UpdateIntegrationModal";
import { Dropdown } from "react-bootstrap";
import deleteIcon from "../../assets/images/Delete.svg";
import EditSquareIcon from "../../assets/images/EditSquare.png";
import { useTranslation } from "react-i18next";
import { FiRefreshCcw } from "react-icons/fi";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CustomToggle from "../../Components/CustomDots/CustomToggle";
import Loader from "../../Components/Loader/Loader";

function IntegrationAuth() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [appName, setAppName] = useState("");
  const [expiryInSecond, setExpiryInSecond] = useState("");
  // const [formSubmitted, setFormSubmitted] = useState(false);
  const [deleteAuthID, setDeleteAuthID] = useState("");
  const [getAuthData, setGetAuthData] = useState("");
  const [showUpdateOffCanvas, setShowUpdateOffCanvas] = useState(false);
  const [createAuthOffcanvas, setCreateAuthcanvas] = useState(false);
  const [configData, setConfigData] = useState(null);
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");

  const resetForm = () => {
    setAppName("");
    setExpiryInSecond("");
    setCreateAuthcanvas(false);
  };

  const updateIntegration = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.put(
        "/v1.0/api/UpdateIntegrationAuth",
        {
          AppName: appName,
          ExpiryinSecond: expiryInSecond,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("AutAuthIntegration.udpate"));
        getAuthIntegration();
        setShowUpdateOffCanvas(false);
      }
    } catch (error) {
      console.log("Not updating", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAuthIntegration = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetIntegrationAuth", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        setData(response.data.response || []);
        const AuthData = response.data.response[0];
        setDeleteAuthID(AuthData.integrationAuthID);
        setGetAuthData(AuthData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const GetIntegrationConfiguration = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetIntegrationConfiguration", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        setConfigData(response.data.response);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(t("AuthIntegration.errAuth"));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAuthIntegration();
    GetIntegrationConfiguration();
  }, []);

  const handleAppNameChange = (e) => {
    setAppName(e.target.value);
  };

  const handleExpiryChange = (e) => {
    setExpiryInSecond(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        "/v1.0/api/CreateIntegrationAuth",
        {
          AppName: appName,
          ExpiryinSecond: expiryInSecond,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("AuthIntegration.keyCreated"));
        setCreateAuthcanvas(false);
        getAuthIntegration();
        resetForm();
      } else {
        toast.error(t("AuthIntegration.FailedKey"));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating integration:", error);
      toast.error(t("AuthIntegration.FailedKey"));
    }
  };

  const handleRegenrate = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        "/v1.0/api/CreateIntegrationAuth",
        {
          AppName: getAuthData.appName,
          ExpiryinSecond: getAuthData.expiryinSecond,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("AuthIntegration.reGenerateKey"));
        setCreateAuthcanvas(false);
        getAuthIntegration();
        resetForm();
      } else {
        toast.error(t("AuthIntegration.errKey"));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating integration:", error);
      toast.error(t("AuthIntegration.errKey"));
    }
  };

  const deleteAuthIntegration = async () => {
    try {
      setIsLoading(true);
      const response = await axios.delete(`/v1.0/api/DeleteIntegrationAuth?integrationAuthID=${deleteAuthID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("AuthIntegration.deletekey"));
      }
      getAuthIntegration();
    } catch (error) {
      console.log("error from deleting ", error);
    } finally {
      setIsLoading(false);
      setDeleteModalVisible(false);
    }
  };

  const handleCancel = () => {
    resetForm();
    setShowUpdateOffCanvas(false);
    setDeleteModalVisible(false);
  };

  const handleUpdate = () => {
    setShowUpdateOffCanvas(true);
  };

  const handleDeleteClick = () => {
    setDeleteModalVisible(true);
  };

  const columns = [
    { label: t("tablesLabel.appName"), key: "appName" },
    { label: t("tablesLabel.expirySeconds"), key: "expiryinSecond" },
    { label: t("tablesLabel.secretKey"), key: "secretKey" },
    { label: t("tablesLabel.apiKey"), key: "apiKey" },
    { label: "", key: "actions" },
  ];

  // const handleCellClick = (event) => {
  //   const cell = event.target;
  //   cell.classList.toggle("scrollable");
  // };

  // const handleCopyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text);
  //   toast.warn("Secret Key copied to clipboard");
  // };

  return (
    <Container className='p-0 mb-3'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <Modal show={deleteModalVisible} dialogClassName='custom-modal-width' onHide={() => setDeleteModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("AuthIntegration.Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <p> {t("AuthIntegration.sureDelete")}</p>
          <div className='mt-4 d-flex justify-content-end gap-5 '>
            <Button variant='none' className='modalCancelButton' onClick={handleCancel}>
              {t("AuthIntegration.Cancel")}
            </Button>
            <Button variant='none' className='applyBtn' onClick={deleteAuthIntegration}>
              {t("AuthIntegration.Delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div className='mt-3'>
        <Accordion defaultActiveKey='0' className='accordion'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>
              <div className='d-flex align-items-center gap-3'>
                <img src={apiIcon} alt='iconLoading' />
                <label>{t("AuthIntegration.Api&key")}</label>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {data.length === 0 ? (
                <>
                  <Button variant='none' className='ImageCancelBtn' onClick={() => setCreateAuthcanvas(true)}>
                    {t("AuthIntegration.createKeys")}
                  </Button>
                </>
              ) : (
                <></>
              )}

              <Grid className='mt-3'>
                <Grid>

                  <div className='pendingtable'>
                    <TableContainer component={Paper} style={{ height: "30vh" }}>
                      <Table stickyHeader style={{ border: "none" }}>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell key={column.label} style={{ backgroundColor: "#c2e8f8" }} className='TableHeaderCell'>
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {data.length === 0 && (
                            <TableRow>
                              <td colSpan='6' className='p-4 text-center'>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                  <img alt='Document' src={apiIcon} style={{ height: "50px", width: "50px" }} />
                                  {t("AuthIntegration.noKeyFound")}
                                </div>
                              </td>
                            </TableRow>
                          )}

                          {data.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row.appName}</TableCell>
                              <TableCell className='TableCell'>{row.expiryinSecond}</TableCell>
                              <TableCell className='TableCell'>{row.secretKey}</TableCell>
                              {/* <TableCell className='TableCell ' onClick={(e) => handleCellClick(e)}>
                                {row.secretKey}
                                <FaRegCopy className=' copy-icon' onClick={() => handleCopyToClipboard(row.secretKey)} />
                              </TableCell> */}

                              <TableCell className='TableCell'>{row.apiKey}</TableCell>
                              <TableCell className='TableCell p-0'>
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu>
                                    <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={handleRegenrate}>
                                      <FiRefreshCcw size={20} />
                                      {t("AuthIntegration.RegenerateKey")}
                                    </Dropdown.Item>
                                    <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={handleUpdate}>
                                      <img src={EditSquareIcon} alt='iconloading' />
                                      {t("AuthIntegration.UpdateKey")}
                                    </Dropdown.Item>
                                    <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={handleDeleteClick}>
                                      <img src={deleteIcon} alt='iconloading' />
                                      {t("AuthIntegration.Delete")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              </Grid>
              {configData && (
                <div className='my-3'>
                  <label style={{ fontSize: "20px", fontWeight: "500", margin: "20px 0px" }}>{t("AuthIntegration.IntegrationConfiguration")}</label>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='apiDocumentationLink'>
                          <Form.Label>{t("AuthIntegration.apiDocLink")}</Form.Label>
                          <a style={{ color: "black" }} href={configData.apiDocumentationLink} target='_blank' rel='noopener noreferrer'>
                            <br /> {configData.apiDocumentationLink}
                          </a>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className='mb-3' controlId='webhookReqParamGuideline'>
                          <Form.Label>{t("AuthIntegration.ApiDocGuideline")}</Form.Label>
                          <Form.Control as='textarea' rows={3} value={configData.apiDocumentationRemarks} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <UpdateIntegrationModal
        show={showUpdateOffCanvas}
        onHide={() => setShowUpdateOffCanvas(false)}
        appName={appName}
        expiryInSecond={expiryInSecond}
        handleAppNameChange={handleAppNameChange}
        handleExpiryChange={handleExpiryChange}
        handleUpdate={updateIntegration}
        handleCancel={handleCancel}
      />
      <CreateIntegration
        isLoading={isLoading}
        onHide={() => setCreateAuthcanvas(false)}
        show={createAuthOffcanvas}
        appName={appName}
        expiryInSecond={expiryInSecond}
        handleAppNameChange={handleAppNameChange}
        handleExpiryChange={handleExpiryChange}
        handleSubmit={handleSubmit}
        handleCancel={resetForm}
      />
    </Container>
  );
}

export default IntegrationAuth;
