import React, { useEffect, useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import ResizableButtons from "./ResizableButtons";
import { useDrag } from 'react-dnd';
import "react-datepicker/dist/react-datepicker.css";
import { RiEdit2Fill } from "react-icons/ri";
import ControlsFotmatting from './ControlsFotmatting';
import { Scale } from '@mui/icons-material';

const ImageWithControls = ({
  signatoryColors, selectedImage, displayedButtons, drop, updateControlsLocation, handleButtonRemove, dropRef, textInput, setTextInput, onUpdateQRDetails, imageContainerRef, size }) => {

  return (
    <div className='large-image' style={{
      position: "relative",
      height: "auto",

    }}>

      <div ref={drop(dropRef)} className='doc-image' style={{
        // width: "100%",
        position: "relative",
        height: "auto"
      }}>
        <img src={selectedImage} alt='Selected' ref={imageContainerRef} />

        {displayedButtons.map((button, index) => {

          return (
            <ButtonsOnImage button={button} index={index} handleButtonRemove={handleButtonRemove} signatoryColors={signatoryColors} updateControlsLocation={updateControlsLocation} textInput={textInput} setTextInput={setTextInput} selectedImage={selectedImage} onUpdateQRDetails={onUpdateQRDetails}
            />
          )
        }
        )}
      </div>
    </div>
  )
}

export default ImageWithControls


export const ButtonsOnImage = ({ button, index, handleButtonRemove, signatoryColors, updateControlsLocation, selectedImage, onUpdateQRDetails }) => {

  const [resizing, setResizing] = useState(false);
  const [editingStates, setEditingStates] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [textInput, setTextInput] = useState();
  const [showDateInput, setShowDateInput] = useState(false);

  const handleDateChange = (event) => {
    const date = event.target.value;
    // console.log(">>>>date", date)
    // const [year, month, day] = date.split('-'); //yyyy-dd-mm
    // console.log(day, year, month)
    // const formattedDate = `${day}/${month}/${year}`; // Rearrange the components into dd/mm/yyyy
    setSelectedDate(date);
  };


  // useEffect(() => {
  //   if (button.label === "Date") {
  //     if (button.textEditor) {
  //       setSelectedDate(button.textEditor)
  //     }
  //   }
  // }, [button]);


  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  button = {
    ...button,
    dragging: !resizing,
  }

  const handleText = (controlLocationID, text) => {
    console.log("text on click", text)
    setTextInput(text);
    setEditingStates(prevState => ({
      ...prevState,
      [controlLocationID]: true,
    }));
  };

  const handleInputChange = (event) => {
    setTextInput(event.target.value);
  };


  const handleDate = (controlLocationID) => {

    console.log("hello")
    // setControlLocID(controlLocationID);
    // setButtonClicked(true);
    setShowDatePicker(true);
    setEditingStates(prevState => ({
      ...prevState,
      [controlLocationID]: true,
    }));
  }

  // useEffect(() => {
  //   if (buttonClicked) {
  //     setShowDatePicker(true);
  //     setEditingStates(prevState => ({
  //       ...prevState,
  //       [controlLocID]: true,
  //     }));
  //     setButtonClicked(false);
  //     console.log(">>>>>")
  //   }
  // })


  // const handleDateChange = (date, controlLocationID) => {
  //   if (date) {
  //     try {
  //       const selectedDateWithoutTime = date.toLocaleDateString();
  //       console.log(">>>", date, selectedDateWithoutTime);
  //       setSelectedDate(date);
  //       setTextInput(selectedDateWithoutTime);
  //       setEditingStates(prevState => ({
  //         ...prevState,
  //         [controlLocationID]: false,
  //       }));
  //       setShowDatePicker(false);
  //     } catch (error) {
  //       console.error("Error occurred while handling date change:", error);
  //     }
  //   }
  // };

  const [, ref] = useDrag({
    type: "BUTTON",
    item: {
      button
    },
  });


  const resizeProps = {
    button,
    onUpdateControlsLocation: updateControlsLocation,
    signatoryColors,
    setResizing,
    selectedImage,
  }

  return (
    <div
      ref={ref}
      key={button.controlLocationID}
      className='draggable-button-container'
      draggable={!resizing}
      style={{
        position: "absolute",
        left: `${button.position.x}px`,
        top: `${button.position.y}px`,
        touchAction: "none",
      }}
    >

      {/* {console.log(button.width, button.height)} */}


      {button.label === "Date" && (
        <ResizableButtons {...resizeProps}
          onClick={() => setShowDateInput(true)}>
          <div style={{
            width: button.width + 'px',
            height: button.height + 'px',
            padding: "10px ",
            cursor: "grab"

          }}>
            {/* {showDateInput ? */}
            <input
              type='date'
              value={selectedDate || button.textEditor}
              onChange={handleDateChange}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                cursor: "grab"
              }}
              onBlur={() => {
                updateControlsLocation(button.controlLocationID, button.controlIdentifier, selectedDate, button.position.x, button.position.y, button.height, button.width, button.fontStyle, button.fontSize, button.fontColor);
                setShowDateInput(false);
              }
              }
            />
            {/* : */}
            {/* <div style={{
                width: "100%",
                height: "100%",
                border: "none",
                cursor: "grab"
              }}>
                {button.textEditor && button.textEditor !== "" ? button.textEditor : "dd/mm/yyyy"}
              </div> */}

            {/* } */}
          </div>
        </ResizableButtons>
      )}





      {button.label === "Number" && (
        <ResizableButtons {...resizeProps}
          onClick={() => handleText(button.controlLocationID, button.textEditor)}>
          {editingStates[button.controlLocationID] ? (
            <input
              type='number'
              value={textInput}
              onChange={(event) => setTextInput(event.target.value)}
              style={{
                width: button.width + 'px',
                height: button.height + 'px'
              }}
              onBlur={() => {
                setEditingStates(prevState => ({
                  ...prevState,
                  [button.controlLocationID]: false,
                }));
                updateControlsLocation(button.controlLocationID, button.controlIdentifier, textInput, button.position.x, button.position.y, button.height, button.width, button.fontStyle, button.fontSize, button.fontColor);
              }}
              autoFocus
            />
          ) : (
            <div style={{ width: button.textEditor ? '100%' : '', height: button.textEditor ? '100%' : '', display: "flex", flexWrap: "wrap" }}>
              {button.textEditor ? button.textEditor : "Enter Number"}
            </div>
          )}
        </ResizableButtons>
      )}

      {button.label === "Text" && (
        <ResizableButtons {...resizeProps}
          onClick={() => handleText(button.controlLocationID, button.textEditor)}>
          {editingStates[button.controlLocationID] ? (
            <textarea
              style={{
                width: button.width + 'px',
                height: button.height + 'px',
                overflow: "auto",
                resize: 'none'
              }}
              value={textInput}
              onChange={(event) => handleInputChange(event)}
              onBlur={() => {
                // Clear editing state for this button when blurred
                setEditingStates(prevState => ({
                  ...prevState,
                  [button.controlLocationID]: false,
                }));
                updateControlsLocation(button.controlLocationID, button.controlIdentifier, textInput, button.position.x, button.position.y, button.height, button.width, button.fontStyle, button.fontSize, button.fontColor);
              }}
              autoFocus
            />
          ) : (
            <div style={{ width: button.textEditor ? '100%' : '', height: button.textEditor ? '100%' : '', display: "flex", flexWrap: "wrap" }}>
              {button.textEditor ? button.textEditor : "Enter Text"}
            </div>
          )}
        </ResizableButtons>
      )}

      {button.label !== "Date" && button.label !== "Text" && button.label !== "Number" &&
        <ResizableButtons {...resizeProps}>
          {
            button.label === "QR Code" && button.qrCodePath ? (
              <div
                style={{
                  width: button.width + 'px',
                  height: button.height + 'px'
                }}>
                <img
                  src={`${button.qrCodePath}`}
                  style={{ height: "100%", width: "100%" }}
                  alt="QR Code "
                />
              </div>
            ) : <div>{button.label}</div>
          }

        </ResizableButtons>
      }

      <Button className='remove-button' onClick={() => handleButtonRemove(index, button.controlLocationID)}>
        <RxCross2 size={9} />
      </Button>

      <Button className='draggableEdit-button' onClick={toggleOffcanvas}>
        <RiEdit2Fill size={9} />
      </Button>

      {showOffcanvas &&
        <ControlsFotmatting show={showOffcanvas} onHide={toggleOffcanvas} button={button} onUpdateControlsLocation={updateControlsLocation}
          selectedImage={selectedImage} onUpdateQRDetails={onUpdateQRDetails} />
      }

    </div>
  )
}