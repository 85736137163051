import React, { useEffect, useState } from "react";
import axios from "../../../Api/api";
import { Dropdown, Button, Modal } from "react-bootstrap";
import ViewModal from "../../ViewModal/ViewModal";
import { toast } from "react-toastify";
import SignOrder from "../../SignOrder";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Stack, Pagination } from "@mui/material";
import DraftSearchFilter from "../../SearchFilter/DraftSearchFilter";
import { useTranslation } from "react-i18next";
import noDocImg from "../../../assets/images/NoDocuments (1).png";
import downlaodIcon from "../../../assets/images/document-download.png";
import showIcon from "../../../assets/images/Show.png";
import "./style.css";
import CustomToggle from "../../CustomDots/CustomToggle";
import { useUser } from "../../../Context/UserContext";
import Loader from "../../Loader/Loader";

const InactiveDocs = () => {
  const { t } = useTranslation();
  const { userAccess } = useUser("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [requestNo, setRequestNo] = useState("");
  const [docName, setDocName] = useState("");
  const [docNameShow, setDocNameShow] = useState([]);
  const [docNameview, setDocNameview] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [downloadDisabled, setDownloadDisabled] = useState(true);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const token = sessionStorage.getItem("token");

  const fetchInActiveData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetRegisterSignInActive", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.response);
      setSearchResults(response.data.response || []);

      setIsLoading(false);
    } catch (error) {
      console.error(t("dashboardCardStatuses.errorfetchingUserData", error));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInActiveData();
  }, [token]);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let response;
      if (startDate || endDate || referenceNo || requestNo || docName) {
        response = await axios.get("/v1.0/api/GetRegisterSignDraftList", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            StartDate: startDate,
            EndDate: endDate,
            ReferenceNo: referenceNo,
            RequestNo: requestNo,
            DocName: docName,
          },
        });
      }
      setSearchResults(response.data.response || []);
      setIsLoading(false);
    } catch (error) {
      console.error(t("dashboardCardStatuses.errorfetchingUserData", error));
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setStartDate("");
    setEndDate("");
    setReferenceNo("");
    setRequestNo("");
    setDocName("");
    handleSearch();
    fetchInActiveData();
  };

  const handleRegisterSignId = async (registerSignID) => {
    try {
      const response = await axios.get(`/v1.0/api/GetDocsByRegisterSignID?RegisterSignID=${registerSignID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const documents = response.data.response || [];
      setDocNameShow(documents);
      setShowDownloadModal(true);
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.errorFetchingSignedDocKey"));
    }
  };
  const handleViewRegisterSignId = async (registerSignID) => {
    try {
      const response = await axios.get(`/v1.0/api/GetDocsByRegisterSignID?RegisterSignID=${registerSignID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const documents = response.data.response || [];
      setDocNameview(documents);
      setShowViewModal(true);
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.errorFetchingSignedDocKey"));
    }
  };

  const handleFileDownload = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: selectedDocument.signedDocKey,
        },
      });

      if (response.data.statusCode === "1") {
        const fileUrl = response.data.response.document;

        const downloadedBlob = await downloadURL(fileUrl);

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(downloadedBlob);
        downloadLink.download = "document.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        toast.success(t("dashboardCardStatuses.fileDownload"));
      } else if (response.data.statusCode === "7") {
        handleDownClose();
        toast.info(t("dashboardCardStatuses.fileNotDownload"));
      }
    } catch (error) {
      toast.error(t("dashboardCardStatuses.fileNotDownload"));
    } finally {
      setIsLoading(false);
      setShowDownloadModal(false);
    }
  };

  async function downloadURL(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`${t("dashboardCardStatuses.httpErr")} ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw error;
    }
  }

  const handleView = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: selectedDocument.signedDocKey,
        },
      });
      if (response.data.statusCode === "1") {
        const pdfUrl = response.data.response.document;
        handleDownClose();
        setPdfUrl(pdfUrl);
        setShowModal(true);
      } else if (response.data.statusCode === "7") {
        handleDownClose();
        toast.info(t("dashboardCardStatuses.fileNotDisplay"));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  const handleCheckboxChange = (document) => {
    setSelectedDocument(document);
    setDownloadDisabled(false);
  };

  const handleDownClose = () => {
    setSelectedDocument(null);
    setShowDownloadModal(false);
    setShowViewModal(false);
    setDownloadDisabled(true);
  };

  const columns = [
    { label: t("tablesLabel.docName"), key: "docName" },
    { label: t("tablesLabel.date"), key: "createdOn" },
    { label: t("tablesLabel.refNumber"), key: "referenceNumber" },
    { label: t("tablesLabel.requestNumber"), key: "companyPrefixNo" },
    { label: t("tablesLabel.createdby"), key: "createdByName" },
    { label: "", key: "actions" },
  ];

  return (
    <Grid className='container' style={{ marginTop: "12px" }}>
      <Grid item xs={12}>
        {isLoading && (
          <div className='loader-overlay'>
            <Loader />
          </div>
        )}

        <div className='py-3 p-3 container pendingtable'>
          <div className='mx-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <h4>{t("dashboardCardStatuses.InactiveDoc")}</h4>
            <DraftSearchFilter
              startDate={startDate}
              endDate={endDate}
              referenceNo={referenceNo}
              requestNo={requestNo}
              docName={docName}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setReferenceNo={setReferenceNo}
              setRequestNo={setRequestNo}
              setDocName={setDocName}
              handleSearch={handleSearch}
              clearSearch={clearSearch}
            />
          </div>
          <TableContainer component={Paper} style={{ height: "38vh" }}>
            <Table stickyHeader style={{ border: "none" }}>
              <TableHead className='customTableHead'>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.label} style={{ backgroundColor: "#E7EBEE" }} className='TableHeaderCell'>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {searchResults.length === 0 && (
                  <TableRow>
                    <td colSpan='6' className='p-5 text-center'>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img alt='iconLoading' src={noDocImg} style={{ height: "150px", width: "200px" }} />
                        {t("dashboardCardStatuses.notDocsFound")}
                      </div>
                    </td>
                  </TableRow>
                )}

                {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell onClick={(e) => handleCellClick(e)} className='TableCell truncate-cell'>
                      {row.docName}
                    </TableCell>
                    <TableCell className='TableCell'>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>
                    <TableCell onClick={(e) => handleCellClick(e)} className='TableCell truncate-cell'>
                      {row.referenceNumber}
                    </TableCell>
                    <TableCell className='TableCell'>{row.companyPrefixNo}</TableCell>
                    <TableCell onClick={(e) => handleCellClick(e)} className='TableCell truncate-cell'>
                      {row.createdByName}
                    </TableCell>

                    {userAccess.remainingDays <= 0 ? (
                      <>
                        <TableCell className='TableCell p-0'>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                          </Dropdown>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className='TableCell p-0'>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                            <Dropdown.Menu>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleRegisterSignId(row.registerSignID)}>
                                <img src={downlaodIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.download")}
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewRegisterSignId(row.registerSignID)}>
                                <img src={showIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.view")}
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <SignOrder masterDocID={row.masterDocID} />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
            <div className='showHideNoDocs'>
              {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, data.length)} {t("tablesLabel.documentsof")} {data.length} {t("tablesLabel.document")}
            </div>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(data.length / rowsPerPage)}
                variant='outlined'
                shape='rounded'
                page={page + 1}
                onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
              />
            </Stack>
          </div>
        </div>
      </Grid>

      <ViewModal show={showModal} handleClose={() => setShowModal(false)} pdfUrl={pdfUrl} />

      <Modal show={showDownloadModal} onHide={handleDownClose} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("dashboardCardStatuses.downloadDoc")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <p>{t("dashboardCardStatuses.selectDownload")}</p>
          {docNameShow.map((doc, index) => (
            <div className='form-check' key={index}>
              <input type='checkbox' className='form-check-input' id={`checkbox${index}`} checked={selectedDocument === doc} onChange={() => handleCheckboxChange(doc)} />
              <label className='form-check-label' htmlFor={`checkbox${index}`}>
                {doc.docName}
              </label>
            </div>
          ))}
          <div className='mt-5 d-flex justify-content-between gap-2 '>
            <Button variant='none' className='modalCancelButton' onClick={handleDownClose}>
              {t("dashboardCardStatuses.cancel")}
            </Button>
            <Button variant='none' className='applyBtn' onClick={handleFileDownload} disabled={downloadDisabled}>
              {t("dashboardCardStatuses.download")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showViewModal} onHide={handleDownClose} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("dashboardCardStatuses.viewDocument")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <p>{t("dashboardCardStatuses.selectDoc")}</p>
          {docNameview.map((doc, index) => (
            <div className='form-check' key={index}>
              <input type='checkbox' className='form-check-input' id={`checkbox${index}`} checked={selectedDocument === doc} onChange={() => handleCheckboxChange(doc)} />
              <label className='form-check-label' htmlFor={`checkbox${index}`}>
                {doc.docName}
              </label>
            </div>
          ))}
          <div className='mt-5 d-flex justify-content-between gap-2 '>
            <Button variant='none' className='modalCancelButton' onClick={handleDownClose}>
              {t("dashboardCardStatuses.cancel")}
            </Button>
            <Button variant='none' className='applyBtn' onClick={handleView} disabled={downloadDisabled}>
              {t("dashboardCardStatuses.confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Grid>
  );
};

export default InactiveDocs;
