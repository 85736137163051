import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import axios from "../../Api/api";
import ReactSignatureCanvas from "react-signature-canvas";
import { RxCross2 } from "react-icons/rx";
import html2canvas from "html2canvas";
import SignatureColor from "./SignatureColor";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { IoIosCheckbox } from "react-icons/io";

const SignatureModal = (props) => {
  const {
    email,
    fullName,
    setFullName,
    registerSignID,
    controlLocationID,
    signatoriesID,
    selectedMasterDocID,
    showModal,
    setShowModal,
    controlName,
    controlID,
    initials,
    setInitials,
    auth,
    handleUploadedSignature,
    signatoryName,
    handleEnterText,
    textEditor,
    setIsBulkSign
  } = props;

  // console.log("control", controlName, textEditor);

  const [selectedTab, setSelectedTab] = useState("SELECT_SIGN");
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState("default");
  const [showStyleSidebar, setShowStyleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewSignature, setPreviewSignature] = useState(null);
  const [uploadSign, setUploadSign] = useState();
  const [extension, setExtension] = useState();
  const [textInput, setTextInput] = useState('');
  const [selectedColor, setSelectedColor] = useState("black");
  const [isSignAllChecked, setIsSignAllChecked] = useState(false);
  const [savedSignature, setSavedSignature] = useState();
  const [savedStamp, setSavedStamp] = useState();
  const previewBoxRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setTextInput(textEditor);
  }, [textEditor]);

  useEffect(() => {
    setIsSignAllChecked(false);
  }, [showModal]);


  const styleOptions = [
    "style1",
    "style2",
    "style3",
    "style4",
    "style5",
    "style6",
    "style7",
  ];

  useEffect(() => {
    if (controlName === "Stamp") {
      setSelectedTab("SAVED");
      getSignatureStampVerification();
    }
  }, [controlName]);

  const getSignatureStampVerification = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetSignatureStampVerification", {
        headers: {
          SignAuth: auth,
        },
      });

      if (response.data.statusCode === "1") {
        const { mySignatureBase64String, companyStampBase64String } = response.data.response;

        setSavedSignature(mySignatureBase64String);
        setSavedStamp(companyStampBase64String);
      }
    } catch (error) {
      // toast.error("Failed to load saved data");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setErrorMessage("");
    if (selectedFile) {
      if (isImageFile(selectedFile)) {
        const fileExtension = selectedFile.name.split(".").pop();
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64Image = e.target.result;
          //console.log(base64Image);
          // const minSizeInBytes = 50 * 1024; // 50 KB
          const maxSizeInBytes = 200 * 1024; // 200 KB

          if (base64Image.length > maxSizeInBytes) {
            setPreviewImage(null);
            setErrorMessage(t("signatureModal.imageSizeLimit"));
            return;
          }

          setPreviewImage(base64Image);
          const uploadData = extractBase64FromDataURI(base64Image);
          setUploadSign(uploadData);
          setExtension(fileExtension);
        };

        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
        setErrorMessage(t("signatureModal.invalidFileFormat"));
      }
    }
  };

  const isImageFile = (file) => {
    const acceptedFormats = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    return acceptedFormats.includes(file.type);
  };

  // draw signature
  const signRef = useRef({});

  const handleClear = () => {
    signRef.current.clear();
  };

  const handleDrawSignature = async () => {
    const signatureImage = await signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const drawdata = extractBase64FromDataURI(signatureImage);
    return drawdata;
  };

  const renderSignature = () => {
    return (
      <div className={`signStyle d-flex align-items-center`} style={{ color: selectedColor, width: "fit-content", backgroundColor: "transparent" }}>
        {controlName === "Signature" && (
          <div className='signature-item'>
            <h1 className={selectedStyle}>{fullName}</h1>
          </div>
        )}
        {controlName === "Initial" && (
          <div className='signature-item'>
            <h1 className={selectedStyle}>{initials}</h1>
          </div>
        )}
      </div>
    );
  };

  const generateStylePreview = (style) => {
    const styleClassName = style === "default" ? "" : style;
    return (
      <div
        className={`styles-box d-flex align-items-center justify-content-evenly ${styleClassName}`}
        style={{ color: selectedColor }}
        onClick={() => {
          setSelectedStyle(style);
          setShowStyleSidebar(false);
        }}
      >
        {controlName === "Signature" && <h2 className={styleClassName}>{fullName}</h2>}
        {controlName === "Initial" && <h2 className={styleClassName}>{initials}</h2>}
      </div>
    );
  };

  const extractBase64FromDataURI = (dataURI) => {
    const splitURI = dataURI.split(",");

    if (splitURI.length === 2) {
      const base64Data = splitURI[1];
      return base64Data;
    } else {
      // console.error("Invalid data URI format");
      return null;
    }
  };

  const saveAsBase64 = () => {
    const previewBox = previewBoxRef.current;
    //console.log("preview", previewBox);
    return html2canvas(previewBox, {
      backgroundColor: null
    })
      .then((canvas) => {
        const base64Image = canvas.toDataURL("image/png");
        const base64Data = extractBase64FromDataURI(base64Image);
        return base64Data;
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleSignButton = async (isBulkSign) => {
    setIsLoading(true);
    let signature;
    let signatureExtension;

    if (selectedTab === "SELECT_SIGN") {
      const selectSignBase64 = await saveAsBase64();
      signature = selectSignBase64;
      signatureExtension = ".png";
    } else if (selectedTab === "DRAW") {
      const selectDrawBase64 = await handleDrawSignature();
      signature = selectDrawBase64;
      signatureExtension = ".png";
    } else if (selectedTab === "UPLOAD") {
      signature = uploadSign;
      signatureExtension = extension;
    } else if (selectedTab === "SAVED") {
      if (controlName === "Stamp") {
        signature = savedStamp;
        signatureExtension = ".png";
      } else if (controlName === "Signature") {
        signature = savedSignature;
        signatureExtension = ".png";
      }
    }


    const uploadedImage = signature;
    // const imageExtension = signatureExtension;
    handleUploadedSignature(uploadedImage, controlLocationID, signatoriesID, controlName, controlID, signatureExtension, selectedMasterDocID, isBulkSign);
    //console.log('extension', signatureExtension)

    try {
      const response = axios.post(
        "/v1.0/api/CreateSignActivityVerification",
        {
          Status: `${controlName} uploaded`,
          Message: `${signatoryName} uploaded ${controlName} `,
        },
        {
          headers: {
            signAuth: auth,
          },
        }
      );

      //console.log(response);
    } catch (error) { }

    setShowModal(false);
  };


  // const handleSignAll = async () => {
  //   await setIsBulkSign(true); 
  //   handleSignButton();
  // };

  const renderPreviewSignature = () => {
    //console.log(previewSignature);
    if (previewSignature) {
      return (
        <div className='preview-box mb-2 d-flex align-items-center p-2'>
          <img src={previewSignature} alt='Preview' style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </div>
      );
    }
    return null;
  };

  const handleCheckboxChange = (event) => {
    setIsSignAllChecked(event.target.checked);
  };

  const handleSign = () => {
    if (isSignAllChecked) {
      handleSignButton(true);
    }
    else {
      handleSignButton(false);
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title> Sign Here</Modal.Title>
      </Modal.Header>

      {showStyleSidebar && (
        <div className="style-sidebar">
          <Button
            onClick={() => setShowStyleSidebar(false)}
            className="applyBtn w-100"
          >
            <RxCross2 />
          </Button>

          {styleOptions.map((style) => (
            <div key={style}>{generateStylePreview(style)}</div>
          ))}
        </div>
      )}

      <Modal.Body className="signature-modal-body">
        <p className="mb-0">Confirm Your name and Signature.</p>
        {/* <span style={{ color: "red" }}>*</span> Required */}
        <Row className="py-2">
          <Col md={7}>
            {controlName === "Signature" && (
              <>
                <label htmlFor="name">
                  Full Name
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </>
            )}
            {controlName === "Initial" && (
              <>
                <label htmlFor="initials">
                  <b> Initials</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  type="text"
                  id="initials"
                  value={initials}
                  onChange={(e) => setInitials(e.target.value)}
                />
              </>
            )}
          </Col>
        </Row>
        <div className="signature_tabs pt-2">
          <div className="nav-links">
            {controlName === "Stamp" ? null : <>

              <button
                onClick={() => handleTabChange("SELECT_SIGN")}
                className={selectedTab === "SELECT_SIGN" ? "active-tab" : ""}
              >
                Select Style
              </button>
              <button
                onClick={() => handleTabChange("DRAW")}
                className={selectedTab === "DRAW" ? "active-tab" : ""}
              >
                Draw
              </button>
            </>}

            <button
              onClick={() => handleTabChange("UPLOAD")}
              className={selectedTab === "UPLOAD" ? "active-tab" : ""}
            >
              Upload
            </button>

            <button
              onClick={() => {
                handleTabChange("SAVED");
                getSignatureStampVerification();
              }}
              className={selectedTab === "SAVED" ? "active-tab" : ""}
            >
              Saved
            </button>
          </div>
          <hr className="m-0" />

          {selectedTab === "SELECT_SIGN" && (
            <div>
              <div className="d-flex justify-content-between my-3">
                {/* <b>Preview</b> */}
                <SignatureColor
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                />
                <button
                  onClick={() => setShowStyleSidebar(true)}
                >
                  Change Sign
                </button>
              </div>
              <div className="preview-box d-flex align-items-center">
                <div className="signStyle" ref={previewBoxRef}>
                  {renderSignature()}
                </div>
              </div>
            </div>
          )}

          {selectedTab === "DRAW" && (
            <div>
              <div className="d-flex justify-content-between my-3">
                {/* <b>Draw signature</b> */}

                <SignatureColor
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                />
                <button onClick={handleClear}>
                  Clear
                </button>
              </div>
              <div className="preview-box">
                <ReactSignatureCanvas
                  penColor={selectedColor}
                  ref={signRef}
                  canvasProps={{
                    width: 500,
                    height: 150,
                    className: "sigCanvas",
                    background: "transparent"
                  }}
                />
              </div>
            </div>
          )}

          {selectedTab === "UPLOAD" && (
            <div>
              <div className="d-flex justify-content-between my-3">
                {/* <b>Preview</b> */}
              </div>
              <div className="preview-box mb-2 d-flex align-items-center p-2">
                {errorMessage ? (
                  <div className="error-message">{errorMessage}</div>
                ) : (
                  previewImage && (
                    <img
                      src={previewImage}
                      alt="Uploaded"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  )
                )}
              </div>
              <div>
                <Button onClick={handleFileSelect} className="applyBtn" style={{ background: "#0c9ad7" }}>
                  {controlName === "Stamp" && "Upload Stamp"}
                  {controlName === "Signature" && "Upload Signature"}
                  {controlName === "Initial" && "Upload Initials"}
                </Button>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
              </div>
              <small>
                For best results use an image that is 400 x 145 pixels
              </small>
            </div>
          )}

          {selectedTab === "SAVED" && (
            <div>
              <div className="d-flex justify-content-between my-3">
                {/* <b>Saved Items</b> */}
              </div>
              <div className="preview-box mb-2 d-flex align-items-center p-2">
                {errorMessage ? (
                  <div className="error-message">{errorMessage}</div>
                ) : controlName === "Stamp" ? (
                  savedStamp ? (
                    <>
                      <img
                        src={`data:image/png;base64,${savedStamp}`}
                        alt="Saved Stamp"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </>
                  ) : (
                    <div>No saved stamp available</div>
                  )
                ) : controlName === "Signature" ? (
                  savedSignature ? (
                    <>
                      <img
                        src={`data:image/png;base64,${savedSignature}`}
                        alt="Saved Signature"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />

                    </>
                  ) : (
                    <div>No saved signature available</div>
                  )
                ) : null}
              </div>
            </div>
          )}

          <div className="adopt-text my-2">
            <small>
              By selecting Sign Here, I agree that the signature and initials
              will be the electronic representation of my signature and initials
              for all purposes when I (or my agent) use them on documents,
              including legally binding contracts.
            </small>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
      >
        <div className="d-flex w-100 justify-content-start align-items-center bulk-sign">
          <input type="checkbox"
            checked={isSignAllChecked}
            onChange={handleCheckboxChange}
            style={{ width: "auto", marginRight: "10px" }} />
          <small>
            Do you want to place your
            {controlName === "Stamp" && " Stamp "}
            {controlName === "Signature" && " Signature "}
            {controlName === "Initial" && " Initials "}
            on all relevant sections ?</small></div>
        <div className="d-flex justify-content-between w-100">
          <Button variant="none" onClick={closeModal} className="modalCancelButton me-2">
            Cancel
          </Button>

          {/* <Button
            variant="none"
            onClick={() => handleSignButton(true)}
            className="modalCancelButton me-2"
          >
            {controlName === "Stamp" && "Stamp All"}
            {controlName === "Signature" && "Sign All"}
            {controlName === "Initial" && "Initials All"}
          </Button> */}

          <Button
            variant="none"
            onClick={handleSign}
            className="applyBtn w-100"
          >
            {controlName === "Stamp" && "Stamp Now"}
            {controlName === "Signature" && "Sign Now"}
            {controlName === "Initial" && "Initials Now"}
            {controlName === "Text" && "Save Text"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignatureModal;
