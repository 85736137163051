import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LuMonitor } from "react-icons/lu";
import { Col, Row, Accordion } from "react-bootstrap";
import { FaCheck, FaDropbox, FaGoogleDrive } from "react-icons/fa";
import { ImOnedrive } from "react-icons/im";
import cobrandingIcon from "../../assets/images/cobrandingIcon.svg";
import logoIcon from "../../assets/images/logoIcon.svg";
import cobrandIcon from "../../assets/images/group.svg";
import { useUser } from "../../Context/UserContext";
import "./style.css";
import Loader from "../Loader/Loader";

function CoBrandingDetails() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userAccess } = useUser("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [objectives, setObjectives] = useState("");
  const [companyPrefix, setCompanyPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [displayedImage, setDisplayedImage] = useState(logoIcon);
  const [displayedStampImage, setDisplayedStampImage] = useState(cobrandingIcon);
  const [base64Image, setBase64Image] = useState(null);
  const [base64StampImage, setBase64StampImage] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [IsGoogleDrive, setIsGoogleDrive] = useState(false);
  const [IsOneDrive, setIsOneDrive] = useState(false);
  const [IsDropbox, setIsDropbox] = useState(false);

  const token = sessionStorage.getItem("token");

  const [coBrandingData, setCoBrandingData] = useState({
    accountLogoPath: "",
    accountLogoFile: "",
    companyStampPath: "",
    companyStampFile: "",
    colorTheme: "",
    companyRefPrefix: "",
    objectives: "",
    IsGoogleDrive: false,
    IsOneDrive: false,
    IsDropbox: false,
  });

  useEffect(() => {
    if (coBrandingData) {
      setObjectives(coBrandingData.objectives || "");
      setCompanyPrefix(coBrandingData.companyRefPrefix || "");
    }
  }, [coBrandingData, userAccess]);

  const fetchUserProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/Account/UserProfile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.response.accountID) {
        setAccountId(response.data.response.accountID);
        fetchCoBrandingData(response.data.response.accountID);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleCheckboxChange = (checkboxName, isChecked) => {
    switch (checkboxName) {
      case "googleDrive":
        setIsGoogleDrive(isChecked);
        break;
      case "oneDrive":
        setIsOneDrive(isChecked);
        break;
      case "dropbox":
        setIsDropbox(isChecked);
        break;
      default:
        break;
    }

    setIsFormChanged(true);
  };

  const fetchCoBrandingData = async (accountId) => {
    try {
      if (!accountId) {
        return;
      }
      setIsLoading(true);
      const response = await axios.get(`/v1.0/api/GetCoBranding?AccountID=${accountId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const { accountLogoFile, accountLogoPath, colorTheme, companyRefPrefix, objectives, companyStampPath, companyStampFile, isGoogleDrive, isOneDrive, isDropbox } = response.data.response;

        setIsGoogleDrive(isGoogleDrive);
        setIsOneDrive(isOneDrive);
        setIsDropbox(isDropbox);

        setCoBrandingData({
          companyStampFile,
          companyStampPath,
          accountLogoFile,
          accountLogoPath,
          colorTheme,
          companyRefPrefix,
          objectives,
          IsGoogleDrive,
          IsOneDrive,
          IsDropbox,
        });

        setDisplayedImage(accountLogoPath && accountLogoFile ? `${accountLogoPath}${accountLogoFile}` : logoIcon);
        setDisplayedStampImage(companyStampPath && companyStampFile ? `${companyStampPath}${companyStampFile}` : cobrandingIcon);
        setSelectedColor(colorTheme || "");
      }
    } catch (error) {
      console.error("Error fetching cobranding data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.AccountLogoFile = base64Image;
      data.AccountLogoExtension = ".png";
      data.CompanyStampFile = base64StampImage;
      data.CompanyStampExtention = ".png";
      data.ColorTheme = selectedColor;
      data.IsGoogleDrive = IsGoogleDrive;
      data.IsOneDrive = IsOneDrive;
      data.IsDropbox = IsDropbox;

      const response = await axios.put("/Account/UpdateAccount", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(response.data.message);
        setIsFormChanged(false);
        fetchCoBrandingData(accountId);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(t("coBranding.errWrong"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setDisplayedImage(imageUrl);

      try {
        await convertToBase64(file);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error(error.message);
      }
    }
    setIsFormChanged(true);
  };

  const handleStampImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const stampUrl = URL.createObjectURL(file);
      setDisplayedStampImage(stampUrl);

      try {
        await convertStampToBase64(file);
      } catch (error) {
        console.error("Error converting stamp image to base64:", error);
        toast.error(error.message);
      }
    }
    setIsFormChanged(true);
  };

  const handleColorOptionClick = (color) => {
    setSelectedColor(color);
    setIsFormChanged(true);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.name.endsWith(".png") || file.name.endsWith(".jpeg") || file.name.endsWith(".jpg")) {
        reader.onload = () => {
          let base64String = reader.result?.split(",")[1];
          base64String = base64String.replace(/["',:;]/g, "");
          setBase64Image(base64String);
          resolve(base64String);
        };
      } else {
        reject(new Error("Invalid file format. Please upload a PNG or JPG file."));
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const convertStampToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.name.endsWith(".png") || file.name.endsWith(".jpeg") || file.name.endsWith(".jpg")) {
        reader.onload = () => {
          let base64String = reader.result?.split(",")[1];
          base64String = base64String.replace(/["',:;]/g, "");
          setBase64StampImage(base64String);
          resolve(base64String);
        };
      } else {
        reject(new Error("Invalid file format. Please upload a PNG or JPG file."));
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const handleObjectivesChange = (e) => {
    setObjectives(e.target.value);
    setIsFormChanged(true);
  };

  const handleCompanyPrefixChange = (e) => {
    setCompanyPrefix(e.target.value);
    setIsFormChanged(true);
  };

  const handleCancelChanges = () => {
    // fetchCoBrandingData(accountId);
    setIsFormChanged(false);
  };

  return (
    <>
      <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey='2'>
          <Accordion.Header>
            <div className='d-flex align-items-center gap-3'>
              <img src={cobrandIcon} alt='IconLoading' />
              <label>{t("coBranding.coBranding")}</label>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className='main-container'>
              {isLoading && (
                <div className='loader-overlay'>
                  <Loader />
                </div>
              )}
              <div className='main-container-form'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <div className='profile-Form'>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          {displayedImage && (
                            <img
                              src={displayedImage}
                              alt='Selected'
                              className='Imageshown'
                              onError={(e) => {
                                e.target.src = logoIcon;
                              }}
                            />
                          )}

                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ fontSize: "18px", fontWeight: "600" }}>{t("coBranding.CompanyLogo")}</label>
                            <label>{t("coBranding.uploadCompanyLogo")}</label>
                          </div>
                        </div>
                        <label className='uploadProfilebtn' htmlFor='accountLogoImg'>
                          <input id='accountLogoImg' style={{ display: "none" }} name='accountLogoImg' accept='.jpg, .jpeg, .png' type='file' onChange={handleImageChange} />
                          {t("coBranding.uploadNewLogo")}
                        </label>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className='profile-Form'>
                        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          {displayedStampImage && (
                            <img
                              src={displayedStampImage}
                              alt='Selected'
                              className='Imageshown'
                              onError={(e) => {
                                e.target.src = cobrandingIcon;
                              }}
                            />
                          )}

                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ fontSize: "18px", fontWeight: "600" }}>{t("coBranding.CompanyStamp")}</label>
                            <label>{t("coBranding.uploadCompanysStamp")}</label>
                          </div>
                        </div>

                        <label className='uploadProfilebtn' htmlFor='CompanyStampFile'>
                          <input id='CompanyStampFile' style={{ display: "none" }} name='CompanyStampFile' accept='.jpg, .jpeg, .png' type='file' onChange={handleStampImageChange} />
                          {t("coBranding.uploadStamp")}
                        </label>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        <label> {t("coBranding.companyName")} </label>
                      </div>
                      <input {...register("CompanyRefPrefix")} onChange={handleCompanyPrefixChange} value={companyPrefix} placeholder={t("coBranding.enterCompanyName")} />
                    </Col>

                    <Col md={6}>
                      <div
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        <label htmlFor='objectives'>{t("coBranding.description")} </label>
                      </div>
                      <div>
                        <input id='objectives' {...register("Objectives")} onChange={handleObjectivesChange} value={objectives} placeholder={t("coBranding.enterDescription")} />
                      </div>
                    </Col>

                    <Col md={6} className='mt-3'>
                      <label>{t("coBranding.SideColor")}</label>
                      <div className='color-options'>
                        <div className='color-option' style={{ backgroundColor: "#0979A9" }} onClick={() => handleColorOptionClick("#0979A9")}>
                          {selectedColor === "#0979A9" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#9409A9" }} onClick={() => handleColorOptionClick("#9409A9")}>
                          {selectedColor === "#9409A9" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#092DA9" }} onClick={() => handleColorOptionClick("#092DA9")}>
                          {selectedColor === "#092DA9" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#A90969" }} onClick={() => handleColorOptionClick("#A90969")}>
                          {selectedColor === "#A90969" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#A90909" }} onClick={() => handleColorOptionClick("#A90909")}>
                          {selectedColor === "#A90909" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#A94C09" }} onClick={() => handleColorOptionClick("#A94C09")}>
                          {selectedColor === "#A94C09" && <FaCheck className='checkmark' />}
                        </div>
                        <div className='color-option' style={{ backgroundColor: "#2E7404" }} onClick={() => handleColorOptionClick("#2E7404")}>
                          {selectedColor === "#2E7404" && <FaCheck className='checkmark' />}
                        </div>
                      </div>
                    </Col>

                    <h6 className='mt-3'>{t("coBranding.FileSources")}</h6>
                    <p>{t("coBranding.Selectoptions")}</p>

                    <Col md={3}>
                      <div className='form-check '>
                        <input type='checkbox' className='form-check-input' id='system' checked={true} disabled />
                        <label className='mx-2' htmlFor='system'>
                          {t("coBranding.System")}
                        </label>
                        <LuMonitor color='#0C9AD7' size={22} />
                      </div>
                    </Col>

                    {userAccess.googDrive === true ? (
                      <>
                        <Col md={3}>
                          <div className='form-check '>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id='googleDrive'
                              {...register("IsGoogleDrive")}
                              checked={IsGoogleDrive}
                              onChange={(e) => handleCheckboxChange("googleDrive", e.target.checked)}
                            />
                            <label className='mx-2' htmlFor='googleDrive'>
                              {t("coBranding.GoogleDrive")}
                            </label>
                            <FaGoogleDrive color='#0C9AD7' />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <Col md={3}>
                      <div className='form-check '>
                        <input
                          type='checkbox'
                          id='oneDrive'
                          checked={IsOneDrive}
                          className='form-check-input'
                          {...register("IsOneDrive")}
                          onChange={(e) => handleCheckboxChange("oneDrive", e.target.checked)}
                        />
                        <label className='mx-2' htmlFor='oneDrive'>
                          OneDrive
                        </label>
                        <ImOnedrive color='#0C9AD7' />
                      </div>
                    </Col> */}

                    {userAccess.dropbox === true ? (
                      <>
                        <Col md={3}>
                          <div className='form-check'>
                            <input
                              type='checkbox'
                              id='dropbox'
                              z
                              className='form-check-input'
                              {...register("IsDropbox")}
                              checked={IsDropbox}
                              onChange={(e) => handleCheckboxChange("dropbox", e.target.checked)}
                            />
                            <label className='mx-2' htmlFor='dropbox'>
                              {t("coBranding.Dropbox")}
                            </label>
                            <FaDropbox color='#0C9AD7' />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className='d-flex gap-2 justify-content-start mt-5'>
                      {isFormChanged ? (
                        <>
                          <Button variant='none' className='ImageDoneBtn' onClick={handleSubmit(onSubmit)}>
                            {t("coBranding.save")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleCancelChanges}>
                            {t("coBranding.cancel")}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </Row>
                </form>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default CoBrandingDetails;
