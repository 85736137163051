import React, { useEffect, useState } from "react";
import axios from "../../../Api/api";
import { toast } from "react-toastify";
import { Dropdown, Button, Modal, Form } from "react-bootstrap";
import ShareModal from "../../CommonModal/ShareModal";
import ViewModal from "../../ViewModal/ViewModal";
import "./style.css";
import SignOrder from "../../SignOrder";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AuditTrailModal from "../../../Pages/AuditTrail/AuditTrailModal";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import DocumentSearchFilters from "../../SearchFilter/DocSearchFilter";
import DownloadModal from "../../DownloadModal";
import noDocImg from "../../../assets/images/NoDocuments (1).png";
import downlaodIcon from "../../../assets/images/document-download.png";
import shareIcon from "../../../assets/images/export.png";
import showIcon from "../../../assets/images/Show.png";
import auditIcon from "../../../assets/images/searchicon.png";
import resendIcon from "../../../assets/images/resend.svg";
import recall from "../../../assets/images/recall.svg";
import CustomToggle from "../../CustomDots/CustomToggle";
import DashboardComments from "../../DashboardComments/DashboardComments";
import { useUser } from "../../../Context/UserContext";
import { BiTransferAlt } from "react-icons/bi";
import Loader from "../../Loader/Loader";

const PendingWithOthers = () => {
  const { t } = useTranslation();
  const { userAccess } = useUser("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signedDocKey, setSignedDocKey] = useState("");
  const { reset } = useForm();
  const [masterDocID, setMasterDocID] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [docName, setDocName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [shareFormData, setShareFormData] = useState({
    Email: "",
    Subject: "",
    Body: "",
    MasterDocID: "",
    RegisterSignID: "",
  });

  const [page, setPage] = useState(0);
  const [showATModal, setShowATModal] = useState(false);
  const [auditTrailURL, setAuditTrailURL] = useState("");
  const [registerSignID, setRegisterSignID] = useState();
  const [dataRow, setDataRow] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [showRecallConfirmationModal, setShowRecallConfirmationModal] = useState(false);
  const [selectedRowForRecall, setSelectedRowForRecall] = useState(null);
  const [showResendConfirmationModal, setShowResendConfirmationModal] = useState(false);
  const [selectedRowForResend, setSelectedRowForResend] = useState(null);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [newOwner, setNewOwner] = useState("");

  const handleRecallConfirmationModal = (row) => {
    setSelectedRowForRecall(row);
    setShowRecallConfirmationModal(true);
  };

  const handleResendConfirmationModal = (row) => {
    setSelectedRowForResend(row);
    setShowResendConfirmationModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const token = sessionStorage.getItem("token");

  // Function to handle opening the share modal
  const handleShareModalShow = async (row) => {
    const { masterDocID, registerSignID } = row;
    setMasterDocID(masterDocID);
    setRegisterSignID(registerSignID);
    try {
      const shareData = {
        ...shareFormData,
        MasterDocID: masterDocID,
        RegisterSignID: registerSignID,
      };
      setShareFormData(shareData);
      setShowShareModal(true);
    } catch (error) {
      toast.error(t("dashboardCardStatuses.errsharingData"));
    }
  };

  // Function to handle submitting share data
  const handleShareDocument = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/CreateDocShared", shareFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.docShareSuccess"));
      } else if (response.data.statusCode === "3") {
        toast.info(t("dashboardCardStatuses.faildShareDoc"));
      }
      setShowShareModal(false);
      setIsLoading(false);
      reset();
    } catch (error) {
      if (error.response && error.response.statusCode === "4") {
        toast.error(t("dashboardCardStatuses.unAuthPleaseLogin"));
      } else {
        toast.error(t("dashboardCardStatuses.errSharingDoc"));
      }
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetPendingMasterDocList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successStatus) {
        setData(response.data.response);
        setSearchResults(response.data.response || []);

        if (response.data.response && response.data.response.length > 0) {
          const dockey = response.data.response[0];
          setSignedDocKey(dockey.signedDocKey);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, userAccess]);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let response;
      if (startDate || endDate || referenceNo || docNumber || docName) {
        response = await axios.get("/v1.0/api/GetPendingMasterDocList", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            StartDate: startDate,
            EndDate: endDate,
            ReferenceNo: referenceNo,
            DocNumber: docNumber,
            DocName: docName,
          },
        });
      }
      setSearchResults(response.data.response || []);
      setIsLoading(false);
    } catch (error) {
      console.error(t("dashboardCardStatuses.errorfetchingUserData"));
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setStartDate("");
    setEndDate("");
    setReferenceNo("");
    setDocNumber("");
    setDocName("");
    handleSearch();
    fetchData();
  };

  // //handle downlaod
  const handleFileDownload = async (row) => {
    setDataRow(row);
    setShowDownloadModal(true);
  };

  const handelView = async (row) => {
    setIsLoading(true);
    try {
      const { signedDocKey } = row;
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: signedDocKey,
        },
      });
      const pdfUrl = response.data.response.document;
      setPdfUrl(pdfUrl);
      setShowModal(true);
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.fileNotDisplay"));
    } finally {
      setIsLoading(false);
    }
  };

  //Resend Email
  const handleResendDocument = async () => {
    setIsLoading(true);
    try {
      const { signedDocKey } = selectedRowForResend;
      const response = await axios.get("/v1.0/api/ReminderEmail", {
        headers: {
          Authorization: `Bearer ${token}`,
          SignAuth: signedDocKey,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success("Reminder email successfully sent");
      } else {
        toast.error("Email didn't send. Try Again!");
      }
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.fileNotDisplay"));
    } finally {
      setIsLoading(false);
      setShowResendConfirmationModal(false);
      setSelectedRowForResend(null);
    }
  };

  const handleViewAuditTrail = (signedDocKey) => {
    const newPdfUrl = `/AuditTrail/id/${signedDocKey}`;
    setAuditTrailURL(newPdfUrl);
    setShowATModal(true);
  };

  const handleRecallDocument = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/RecallSignature",
        {
          RegisterSignID: selectedRowForRecall.registerSignID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success("Signature recalled successfully");
      } else {
        toast.error("Object reference not set to an instance of an object.");
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Internal Error. Try After Some time");
      console.error("Error", error);
      setIsLoading(false);
    } finally {
      setShowRecallConfirmationModal(false);
      setSelectedRowForRecall(null);
    }
  };

  //Transfer Document
  const handleTransferOwnerShip = (row) => {
    setMasterDocID(row.masterDocID);
    setDocName(row.docName);
    setShowTransferModal(true);
  };

  const handleTransfer = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "/v1.0/api/CreateDocumentOwnership",
        {
          MasterDocID: masterDocID,
          NewOwner: newOwner,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.OwnershipSuccess"));
      } else {
        toast.error(t("dashboardCardStatuses.FailedTransfer"));
      }
      setIsLoading(false);
      setShowTransferModal(false);
    } catch (error) {
      toast.error(t("dashboardCardStatuses.FailedTransfer"));
      console.error("Error", error);
      setIsLoading(false);
    }
  };

  const columns = [
    { label: t("tablesLabel.docName"), key: "docName" },
    { label: t("tablesLabel.docNumber"), key: "docCompanyPrefixNo" },
    { label: t("tablesLabel.date"), key: "createdOn" },
    { label: t("tablesLabel.pendingWith"), key: "pendingWithName" },
    { label: t("tablesLabel.refNumber"), key: "referenceNumber" },
    { label: t("tablesLabel.createdby"), key: "createdByName" },
    { label: "", key: "actions" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  return (
    <Grid className='container'>
      <Grid item xs={12}>
        {/* Loader overlay */}
        {isLoading && (
          <div className='loader-overlay'>
            <Loader />
          </div>
        )}

        <div className='py-3 p-3 container pendingtable '>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <label className='fs-16'>{t("menuButtons.pendingWithOthers")}</label>
            <div className='search-filters-container'>
              <DocumentSearchFilters
                startDate={startDate}
                endDate={endDate}
                referenceNo={referenceNo}
                docNumber={docNumber}
                docName={docName}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setReferenceNo={setReferenceNo}
                setDocNumber={setDocNumber}
                setDocName={setDocName}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
              />
            </div>
          </div>
          {/* Table */}
          <TableContainer component={Paper} style={{ height: "38vh" }}>
            <Table stickyHeader style={{ border: "none" }}>
              {/* Table Headers */}
              <TableHead className='customTableHead'>
                <TableRow style={{ padding: 0 }}>
                  {columns.map((column) => (
                    <TableCell key={column.label} style={{ backgroundColor: "#FDF7E8" }} className='TableHeaderCell'>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {searchResults.length === 0 && (
                  <TableRow>
                    <td colSpan='6' className='p-5 text-center'>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img alt='iconLoading' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                        {t("dashboardCardStatuses.notDocsFound")}
                      </div>
                    </td>
                  </TableRow>
                )}

                {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    {/* Table Cells */}
                    <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                      {row.docName}
                    </TableCell>
                    <TableCell className='TableCell'>{row.docCompanyPrefixNo}</TableCell>
                    <TableCell className='TableCell'>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>

                    <TableCell className='TableCell' classname>
                      {row.pendingWithName}
                    </TableCell>
                    <TableCell className='TableCell'>{row.referenceNumber}</TableCell>
                    <TableCell onClick={(e) => handleCellClick(e)} className='TableCell truncate-cell'>
                      {row.createdByName}
                    </TableCell>

                    {userAccess.remainingDays <= 0 ? (
                      <>
                        <TableCell className='TableCell p-0'>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />
                          </Dropdown>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className='TableCell p-0'>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />

                            <Dropdown.Menu>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileDownload(row)}>
                                <img src={downlaodIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.download")}
                              </Dropdown.Item>
                              {userAccess.docShare === true ? (
                                <>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShareModalShow(row)}>
                                    <img src={shareIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.share")}
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <></>
                              )}
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handelView(row)}>
                                <img src={showIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.view")}
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <SignOrder masterDocID={row.masterDocID} />
                              </Dropdown.Item>

                              {userAccess.auditTrail === true ? (
                                <>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewAuditTrail(row.signedDocKey)}>
                                    <img src={auditIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.viewAuditTrail")}
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <></>
                              )}

                              {userAccess.resendNotification === true ? (
                                <>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleResendConfirmationModal(row)}>
                                    <img src={resendIcon} alt='iconLoading' />
                                    {t("dashboardCardStatuses.resendNotification")}
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <></>
                              )}

                              {userAccess.recalled === true ? (
                                <>
                                  <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleRecallConfirmationModal(row)}>
                                    <img src={recall} alt='iconLoading' />
                                    {t("dashboardCardStatuses.Recall")}
                                  </Dropdown.Item>
                                </>
                              ) : (
                                <></>
                              )}

                              <Dropdown.Item>
                                <DashboardComments registerSignID={row.registerSignID} />
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleTransferOwnerShip(row)}>
                                <BiTransferAlt size={22} />
                                {t("dashboardCardStatuses.TransferOwnerShip")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
            <div className='showHideNoDocs'>
              {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, data.length)} {t("tablesLabel.documentsof")} {data.length} {t("tablesLabel.document")}
            </div>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(data.length / rowsPerPage)}
                variant='outlined'
                shape='rounded'
                page={page + 1}
                onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
              />
            </Stack>
          </div>
        </div>
      </Grid>

      {/* Share and View modals */}
      <ShareModal
        show={showShareModal}
        handleClose={() => setShowShareModal(false)}
        formData={shareFormData}
        handleShare={handleShareDocument}
        handleChange={(e) => setShareFormData({ ...shareFormData, [e.target.name]: e.target.value })}
        masterDocID={masterDocID}
        registerSignID={registerSignID}
      />

      <ViewModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setPdfUrl("");
        }}
        pdfUrl={pdfUrl}
      />

      <DownloadModal
        show={showDownloadModal}
        dataRow={dataRow}
        handleClose={() => {
          setShowDownloadModal(false);
        }}
      />

      <AuditTrailModal
        show={showATModal}
        auditTrailURL={auditTrailURL}
        handleClose={() => {
          setShowATModal(false);
          setAuditTrailURL("");
        }}
      />

      <Modal show={showRecallConfirmationModal} onHide={() => setShowRecallConfirmationModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title> {t("dashboardCardStatuses.confirmRecall")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          {t("dashboardCardStatuses.SureRecall")}
          <div className=' mt-4 d-flex justify-content-end gap-5 '>
            <Button variant='none' className='modalCancelButton' onClick={() => setShowRecallConfirmationModal(false)}>
              {t("dashboardCardStatuses.cancel")}
            </Button>
            <Button className='applyBtn' variant='none' onClick={handleRecallDocument}>
              {t("dashboardCardStatuses.confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showResendConfirmationModal} onHide={() => setShowResendConfirmationModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title> {t("dashboardCardStatuses.confirmResend")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          {t("dashboardCardStatuses.sureResend")}
          <div className=' mt-4 d-flex justify-content-end gap-5 '>
            <Button variant='none' className='modalCancelButton' onClick={() => setShowResendConfirmationModal(false)}>
              {t("dashboardCardStatuses.cancel")}
            </Button>
            <Button className='applyBtn' variant='none' onClick={handleResendDocument}>
              {t("dashboardCardStatuses.confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showTransferModal} backdrop='static' keyboard={false} onHide={() => setShowTransferModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title> {t("dashboardCardStatuses.TransferOwnerShip")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <Form>
            <Form.Group controlId='formDocName'>
              <Form.Label>{t("dashboardCardStatuses.document")}</Form.Label>
              <Form.Control type='text' readOnly value={docName} disabled />
            </Form.Group>
            <Form.Group controlId='formNewOwner' className='mt-3'>
              <Form.Label>{t("dashboardCardStatuses.TransferTo")}</Form.Label>
              <Form.Control type='text' placeholder='e.g. mail@mail.com' value={newOwner} onChange={(e) => setNewOwner(e.target.value)} />
            </Form.Group>
          </Form>
          <div className=' mt-4 d-flex justify-content-center gap-5 '>
            <Button variant='none' className='modalCancelButton' onClick={() => setShowTransferModal(false)}>
              {t("dashboardCardStatuses.cancel")}
            </Button>
            <Button className='applyBtn' variant='none' onClick={handleTransfer}>
              {t("dashboardCardStatuses.confirm")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Grid>
  );
};
export default PendingWithOthers;
