import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import JoditEditor from "jodit-react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import cvImg from "../../assets/images/file-02.svg";
import messageImg from "../../assets/images/message-text-square-01.svg";
import coverLetter from "../../assets/images/envelope-paper.svg";
import contractImg from "../../assets/images/file-question-02.svg";
import othersImg from "../../assets/images/divide-03.svg";
import emailImg from "../../assets/images/mail-02.svg";
import robotImg from "../../assets/images/robot.svg";
import rectangleBox from "../../assets/images/rectangle.png";
import cvIcon from "../../assets/images/file-02-white.svg";
import messageIcon from "../../assets/images/message-text-square-white.svg";
import coverLetterIcon from "../../assets/images/envelope-paper-white.svg";
import contractIcon from "../../assets/images/file-question-white.svg";
import othersIcon from "../../assets/images/divide-03-white.svg";
import mailIcon from "../../assets/images/mail-02-white.svg";
import { GoDownload } from "react-icons/go";
import aggrementImg from "../../assets/images/agreement-1.svg";
import aggrementIcon from "../../assets/images/agreement-2.svg";
import { useUser } from "../../Context/UserContext";
import UserLisence from "../../Components/UserLisence/UserLisence";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader/Loader";

function OpenAIComponent() {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOPenAILoading, setisOPenAILoading] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const { user, userAccess } = useUser();
  const { t } = useTranslation();

  const [fieldErrors, setFieldErrors] = useState({
    DocumentType: false,
    Name: false,
    ExtraDetails: false,
    CustomDocumentType: false,
  });

  const validateFields = () => {
    const errors = {
      DocumentType: !formData.DocumentType,
      Name: !formData.Name,
      CustomDocumentType: formData.DocumentType === "Other" && !formData.CustomDocumentType,
    };

    setFieldErrors(errors);

    return !Object.values(errors).some((error) => error);
  };

  const [formData, setFormData] = useState({
    CustomDocumentType: "",
    DocumentType: "",
    ExtraDetails: "",
    Name: "",
  });

  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);
  const location = useLocation();

  const token = sessionStorage.getItem("token");

  const handleCancel = () => {
    setFormData({
      CustomDocumentType: "",
      DocumentType: "",
      ExtraDetails: "",
      Name: "",
    });
    setFieldErrors({});
  };

  const handleUpdateCancel = () => {
    handleCancel();
    setEditorContent("");
    setIsUpdating(false);
    setFieldErrors({});
    setShowDownloadButton(false);
  };

  //update the data
  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const updatedFormData = {
        ...formData,
        IsAIDoc: true,
        AIGeneratedText: editorRef.current.value,
      };

      const response = await axios.post("/v1.0/api/GenerateAIPDFDocAndSave", updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        handleUpdateCancel();
        toast.success(t("macAi.macAiUpdate"));
      }
      setEditorContent("");
    } catch (error) {
      console.error("Error updating document:", error);
      toast.error(t("macAi.macUpdateErr"));
    } finally {
      setIsLoading(false);
    }
  };

  //handle Show data in fields
  useEffect(() => {
    if (location.state && location.state.row) {
      const row = location.state.row;
      setFormData({
        CustomDocumentType: row.documentType === "Other" ? row.documentType : "",
        DocumentType: row.documentType,
        ExtraDetails: row.extraDetails,
        Name: row.name,
        AITextID: row.aiTextID,
        AIGeneratedText: row.aiGeneratedText,
      });
      setEditorContent(row.aiGeneratedText);
      setIsUpdating(true);
    }
  }, [location.state]);

  //textEditor cancel
  const handleEditorCancel = () => {
    setEditorContent("");
  };

  const handleDocumentTypeChange = (documentType) => {
    setFormData({ ...formData, DocumentType: documentType });
  };

  //generate Text call
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    try {
      setisOPenAILoading(true);
      const response = await axios.post(
        "/v1.0/api/GenerateAIText",
        {
          CustomDocumentType: formData.CustomDocumentType,
          DocumentType: formData.DocumentType,
          ExtraDetails: formData.ExtraDetails,
          Name: formData.Name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        setEditorContent(response.data.response);
        toast.success(t("macAi.GeneratedDocumentcc"));
      } else if (response.data.statusCode === "12") {
        toast.info(t("macAi.MacIssue"));
      }
    } catch (error) {
      console.error("Error:", error);
      toast.info(t("macAi.MacIssue"));
    } finally {
      setisOPenAILoading(false);
    }
  };

  //save and downlaod call
  const handleSave = async () => {
    if (!validateFields()) return;
    if (!editorContent.trim()) {
      toast.info(t("macAi.TextEditorContent"));
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post(
        "/v1.0/api/GenerateAIPDFDocAndSave",
        {
          AITextID: null,
          AIGeneratedText: editorRef.current.value,
          CustomDocumentType: formData.CustomDocumentType,
          DocumentType: formData.DocumentType,
          ExtraDetails: formData.ExtraDetails,
          Name: formData.Name,
          IsAIDoc: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        setFieldErrors("");
        setShowDownloadButton(true);
        setFormData((prevFormData) => ({ ...prevFormData, AITextID: response.data.response.aiTextID, generatedDocumentPath: response.data.response.generatedDocumentPath })); // Save the aiTextID and generatedDocumentPath in the form data
        toast.success(t("macAi.generatedDocSave"));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateText = async () => {
    try {
      setIsLoading(true);
      const updatedFormData = {
        ...formData,
        AIGeneratedText: editorRef.current.value,
      };

      const response = await axios.post("/v1.0/api/GenerateAIPDFDocAndSave", updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("macAi.macAiUpdate"));
      }
    } catch (error) {
      console.error("Error updating document:", error);
      toast.error(t("macAi.macUpdateErr"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!formData.generatedDocumentPath) {
      toast.error(t("macAi.notDownload"));
      return;
    }
    setIsLoading(true);

    const fileName = formData.Name || getFileNameFromUrl(formData.generatedDocumentPath);
    const downloadedBlob = await downloadURL(formData.generatedDocumentPath);
    const url = URL.createObjectURL(downloadedBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    handleUpdateCancel();
    setIsLoading(false);
    toast.success(t("macAi.downloadSuccess"));
  };

  //download function
  async function downloadURL(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`${"Error"} ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  function getFileNameFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  const whiteIcons = {
    CV: cvIcon,
    Message: messageIcon,
    CoverLetter: coverLetterIcon,
    Contract: contractIcon,
    Agreement: aggrementImg,
    Email: mailIcon,
    Other: othersIcon,
  };

  return (
    <>
      {user.AccountType === "B" && user.RoleID === 1 && userAccess.remainingDays > 0 ? (
        <>
          {userAccess.macAI === true ? (
            <>
              <div className='container my-3'>
                {isLoading && (
                  <div className='loader-overlay'>
                    <Loader />
                  </div>
                )}
                {isOPenAILoading && (
                  <div className='loader-overlay'>
                    <div className='OpenAiloader'></div>
                  </div>
                )}

                <Row className='my-3 gap-4 justify-content-center'>
                  <Col md={3}>
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img src={rectangleBox} alt='Rectangle' />
                      <div className='MACACIIMAGE'  >
                        <div>
                          <label>{t("macAi.generatingYourDoc")}</label>
                          <label>{t("macAi.seeHistory")}</label>{" "}
                          <Link to='/mac-ai-docs'>
                            <label className='AidocumentBtn'>{t("macAi.gotoList")}</label>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <img src={robotImg} className='mt-4' alt='Robot' />
                    </div>
                  </Col>
                </Row>

                <div className='userProfile-form'>
                  <Form onSubmit={handleFormSubmit}>
                    <Row>
                      <label>{t("macAi.docType")}</label>
                      <Col className='mb-2'>
                        <div className='mt-2 '>
                          <Button className={`selectedButton m-1 ${formData.DocumentType === "CV" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("CV")}>
                            <img src={formData.DocumentType === "CV" ? whiteIcons.CV : cvImg} alt='CV' /> {t("macAi.CV")}
                          </Button>
                          <Button className={`selectedButton  m-1  ${formData.DocumentType === "Message" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("Message")}>
                            <img src={formData.DocumentType === "Message" ? whiteIcons.Message : messageImg} alt='Message' /> {t("macAi.Message")}
                          </Button>
                          <Button className={`selectedButton m-1 ${formData.DocumentType === "Contract" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("Contract")}>
                            <img src={formData.DocumentType === "Contract" ? whiteIcons.Contract : contractImg} alt='Contract' /> {t("macAi.Contract")}
                          </Button>
                          <Button className={`selectedButton m-1 ${formData.DocumentType === "Cover letter" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("Cover letter")}>
                            <img src={formData.DocumentType === "Cover letter" ? whiteIcons.CoverLetter : coverLetter} alt='Cover Letter' /> {t("macAi.CoverLetter")}
                          </Button>
                          <Button className={`selectedButton m-1 ${formData.DocumentType === "Agreement" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("Agreement")}>
                            <img src={formData.DocumentType === "Agreement" ? whiteIcons.Agreement : aggrementIcon} alt='Agreement' /> {t("macAi.Agreement")}
                          </Button>
                          <Button className={`selectedButton m-1 ${formData.DocumentType === "Email" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("Email")}>
                            <img src={formData.DocumentType === "Email" ? whiteIcons.Email : emailImg} alt='Email' /> {t("macAi.Email")}
                          </Button>
                          <Button className={`selectedButton m-1 ${formData.DocumentType === "Other" ? "active" : ""}`} variant='none' onClick={() => handleDocumentTypeChange("Other")}>
                            <img src={formData.DocumentType === "Other" ? whiteIcons.Other : othersImg} alt='Other' /> {t("macAi.Other")}
                          </Button>
                        </div>
                        {fieldErrors.DocumentType && <div className='text-danger'>{t("macAi.selectDocType")}</div>}
                      </Col>
                    </Row>

                    <Row className='mt-2'>
                      {formData.DocumentType === "Other" && (
                        <Col>
                          <Form.Group className='mt-2' controlId='Custom'>
                            <Form.Label>{t("macAi.OtherType")}</Form.Label>
                            <Form.Control type='text' value={formData.CustomDocumentType} onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, CustomDocumentType: e.target.value }))} />
                          </Form.Group>
                          {fieldErrors.CustomDocumentType && <div className='text-danger'>{t("macAi.specifyDocType")}</div>}
                        </Col>
                      )}
                      <Col>
                        <Form.Group className='mt-2' controlId='name'>
                          <Form.Label>{t("macAi.Name")}</Form.Label>
                          <Form.Control type='text' value={formData.Name} onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, Name: e.target.value }))} />
                        </Form.Group>
                        {fieldErrors.Name && <div className='text-danger'>{t("macAi.nameRequired")}</div>}
                      </Col>
                      <Col>
                        <Form.Group className='mt-2' controlId='extraDetail'>
                          <Form.Label>{t("macAi.ExtraDetail")}</Form.Label>
                          <Form.Control type='text' value={formData.ExtraDetails} onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, ExtraDetails: e.target.value }))} />
                        </Form.Group>
                        {fieldErrors.ExtraDetails && <div className='text-danger'>{t("macAi.provideExtraDetails")}</div>}
                      </Col>
                    </Row>

                    {(formData.DocumentType || formData.Name || formData.ExtraDetails || formData.CustomDocumentType) && (
                      <div className='d-flex gap-3 mt-3'>
                        <Button variant='none' className='ImageDoneBtn' type='submit'>
                          {t("macAi.GenerateAItext")}
                        </Button>
                        <Button variant='none' className='ImageCancelBtn' onClick={handleCancel}>
                          {t("macAi.Cancel")}
                        </Button>
                      </div>
                    )}
                  </Form>
                </div>

                <div className='userProfile-form mt-3'>
                  <JoditEditor
                    ref={editorRef}
                    value={editorContent}
                    config={{
                      placeholder: t("macAi.enterText"),
                      readonly: false,
                      toolbar: true,
                      speechRecognize: false,
                      source: false,
                      fullsize: false,
                      print: false,
                      about: false,
                      height: 300,
                    }}
                    tabIndex={1}
                    onBlur={(newContent) => setEditorContent(newContent)}
                  />
                </div>

                {isUpdating ? (
                  <div className='d-flex gap-3 mt-3'>
                    <Button variant='none' className='ImageDoneBtn' onClick={handleUpdate}>
                      {t("macAi.update")}
                    </Button>
                    <Button variant='none' className='ImageCancelBtn' onClick={handleUpdateCancel}>
                      {t("macAi.Cancel")}
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className='d-flex  gap-3 mt-3'>
                      {showDownloadButton ? (
                        <>
                          <Button variant='none' className='ImageDoneBtn' onClick={handleUpdateText}>
                            {t("macAi.Updatetext")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleUpdateCancel}>
                            {t("macAi.Cancel")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleDownload}>
                            {t("macAi.docDownload")}
                            <GoDownload className='mx-2' size={22} />
                          </Button>
                        </>
                      ) : (
                        <>
                          {!isUpdating && (formData.DocumentType || formData.Name || formData.ExtraDetails || formData.CustomDocumentType) && (
                            <>
                              <Button className='ImageDoneBtn' onClick={handleSave}>
                                {t("macAi.save")}
                              </Button>
                              <Button className='ImageCancelBtn' onClick={handleEditorCancel}>
                                {t("macAi.clearText")}
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <UserLisence />
        </>
      )}
    </>
  );
}

export default OpenAIComponent;
