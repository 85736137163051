import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoArrowBack } from "react-icons/io5";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imSignerLogo from "../../assets/images/IAMSignerWebLogo.png";
import "./style.css";
import ForgotCarousel from "./forgotCursol";
import LangSelector from "../../Components/Language/LangSelector";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { Modal } from "react-bootstrap";
import lockIcon from "../../assets/images/resetpassSuccess.svg";
import Loader from "../../Components/Loader/Loader";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value);
    if (value !== newPassword) {
      setConfirmPasswordError(t("logScreens.passMatch"));
    } else {
      setConfirmPasswordError("");
    }
  };

  const [newPasswordError, setNewPasswordError] = useState("");
  const [authStatus, setAuthStatus] = useState();
  const [auth, setAuth] = useState();
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");

  const idIndex = parts.indexOf("id");

  const resetFields = () => {
    setNewPassword("");
    setConfirmPassword("");
    setNewPasswordError("");
    setConfirmPasswordError("");
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (idIndex !== -1 && idIndex < parts.length - 1) {
        const pathAfterId = parts.slice(idIndex + 1).join("/");
        setAuth(pathAfterId);

        try {
          const response = await axios.get("/Account/CheckChangePasswordLinkExpiry", {
            headers: {
              SignAuth: pathAfterId,
            },
          });
          setAuthStatus(response.data.message);
          setIsLoading(false);
        } catch (error) {
          console.error("API Error:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    checkAuthStatus();
  }, [auth]);

  const handleChangeNewPassword = (value) => {
    setNewPassword(value);
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(value)) {
      setNewPasswordError(t("logScreens.passwordValidate"));
    } else {
      setNewPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(t("logScreens.passwordMismatch"));
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.put(
        "/Account/ChangePassword",
        {
          NewPassword: newPassword,
        },
        {
          headers: {
            SignAuth: auth,
          },
        }
      );
      if (response.data.statusCode === "1") {
        resetFields();
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
          navigate("/");
        }, 15000);
      } else {
        toast.error(t("logScreens.unAbleChanged"));
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(t("logScreens.ChangeErr"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  const isFormValid = newPassword !== "" && confirmPassword !== "" && !newPasswordError && !confirmPasswordError;

  if (authStatus === "Link is valid") {
    return (
      <>
        {isLoading && (
          <div className='loader-overlay'>
            <Loader />
          </div>
        )}

        <Container fluid className='login-container'>
          <Row>
            <Col sm={12} md={6} className='login-col d-flex flex-column  '>
              <div className='login-form'>
                <div className='headingIMSigner'>
                  <div className='d-flex gap-2 align-items-center'>
                    <Link to='/'>
                      <IoArrowBack size={22} style={{ color: "#0F1315", cursor: "pointer" }} />
                    </Link>
                    <img alt='logo' style={{ height: "45px", width: "140px" }} src={imSignerLogo} />
                  </div>
                  <LangSelector style={{ padding: "0px" }} />
                </div>

                <div className='mb-3'>
                  <label style={{ fontSize: "20px", fontWeight: "700" }}>{t("logScreens.resetPass")}</label>
                </div>
                <p>{t("logScreens.Createpass")}</p>

                <Form onSubmit={handleSubmit}>
                  <Form.Group className='mt-2' controlId='newPassword'>
                    <Form.Label>{t("changePassword.newPassword")}</Form.Label>
                    <div className='password-input'>
                      <Form.Control type={showNewPassword ? "text" : "password"} value={newPassword} onChange={(e) => handleChangeNewPassword(e.target.value)} />
                      <span className='toggle-password' onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                      </span>
                    </div>
                    {newPasswordError && <Form.Text className='text-danger'>{newPasswordError}</Form.Text>}
                  </Form.Group>

                  <Form.Group className='mt-2' controlId='confirmPassword'>
                    <Form.Label>{t("changePassword.confirmPassword")}</Form.Label>
                    <div className='password-input'>
                      <Form.Control type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => handleChangeConfirmPassword(e.target.value)} />
                      <span className='toggle-password' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                      </span>
                    </div>
                    {confirmPasswordError && <Form.Text className='text-danger'>{confirmPasswordError}</Form.Text>}
                  </Form.Group>

                  <Button className='forgot-button mt-3' type='submit' disabled={!isFormValid} style={{ backgroundColor: isLoading || !isFormValid ? "#889CAA" : "" }}>
                    {t("changePassword.submit")}
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={6} className='slider-col d-none d-md-flex flex-column align-items-center'>
              <ForgotCarousel />
            </Col>
          </Row>
        </Container>

        <Modal show={showSuccessModal} onHide={handleCloseModal} centered dialogClassName='custom-modal-width'>
          <Modal.Body className='p-3'>
            <div className='thankyou-message-modal'>
              <div className='message-box d-flex flex-column justify-content-center align-items-center'>
                <img src={lockIcon} alt='iconLoading' />
                <h4 style={{ marginTop: "1rem", color: "#24B032" }}>{t("logScreens.resetPass")}</h4>
                <p className='m-0'>{t("logScreens.successFullyChanged")}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } else if (authStatus === "The link has expired. Please request a new password reset link.") {
    return (
      <div className='declined-Link-container'>
        <div className='message-box d-flex flex-column justify-content-center align-items-center'>
          <h1>{t("logScreens.Expired")}</h1>
          <p>{t("logScreens.passwordReset")}</p>
        </div>
      </div>
    );
  } else if (authStatus === "Sorry, You have already changed the password thank you!") {
    return (
      <div className='declined-Link-container'>
        <div className='message-box d-flex flex-column justify-content-center align-items-center'>
          <h1>{t("logScreens.sorry")}</h1>
          <p>{t("logScreens.alreadyChanged")}!</p>
        </div>
      </div>
    );
  }
};

export default ResetPassword;
