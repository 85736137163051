import React, { useEffect, useState, useRef } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Dropdown, Modal } from "react-bootstrap";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import "./templates.css";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import TemplatesSearchFilter from "./TemplatesSearchFilter";
import EditTemplateNameModal from "./EditTemplateNameModal";
import { useTranslation } from "react-i18next";
import TemplatesConfirmationModal from "../../Components/TemplatesConfirmationModal";
import TemplatesStatuses from "./TemplatesStatuses";
import EditSquareIcon from "../../assets/images/EditSquare.png";
import editName from "../../assets/images/Edit.svg";
import deleteImg from "../../assets/images/Delete.svg";
import inactiveToggle from "../../assets/images/toggle-off-circle.svg";
import templateicon from "../../assets/images/applyTemplates.svg";
import { FaRegCopy } from "react-icons/fa";
import CustomToggle from "../../Components/CustomDots/CustomToggle";
import { useUser } from "../../Context/UserContext";
import Loader from "../../Components/Loader/Loader";

const TemplatesPage = () => {
  const { t } = useTranslation();
  const { user, userAccess } = useUser("");
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Scope, setScope] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [docStatus, setDocStatus] = useState("");
  const navigate = useNavigate();
  const [templateID, setTemplateID] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [originalTemplateName, setOriginalTemplateName] = useState("");
  const [selectedTemplateID, setSelectedTemplateID] = useState(null);
  const [showTemplateIDModal, setShowTemplateIDModal] = useState(false);

  const handleOpenTemplateIDModal = (templateID) => {
    setSelectedTemplateID(templateID);
    setShowTemplateIDModal(true);
  };

  const handleCloseTemplateIDModal = () => {
    setSelectedTemplateID(null);
    setShowTemplateIDModal(false);
  };

  const templateIDRef = useRef(null);

  const handleCopyTemplateID = () => {
    if (templateIDRef.current) {
      templateIDRef.current.select();
      document.execCommand("copy");
      toast.info(t("templateUploadFlow.copiedClipboard"));
    }
  };

  const token = sessionStorage.getItem("token");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchTemplatesData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetAllTemplates", {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      });
      if (response.data) {
        // const sortedData = response.data.response.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
        setData(response.data.response);
        setSearchResults(response.data.response || []);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplatesData();
  }, [token]);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let response;

      if (startDate || endDate || Scope || templateName || docStatus) {
        response = await axios.get("/v1.0/api/GetAllTemplates", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            StartDate: startDate,
            EndDate: endDate,
            Scope: Scope,
            TemplateName: templateName,
            DocStatus: docStatus,
          },
        });

        setSearchResults(response.data.response || []);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setStartDate("");
    setEndDate("");
    setScope("");
    setTemplateName("");
    setDocStatus("");
    handleSearch();
    fetchTemplatesData();
  };

  //inactive templates

  const handleDeleteTemplate = async (templateID) => {
    //console.log(templateID);
    setIsLoading(true);
    try {
      setTemplateID(templateID);

      const response = await axios.delete(
        `/v1.0/api/DeleteTemplate?TemplateID=${templateID}`,
        { TemplateSignatoryID: templateID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.statusCode === "1") {
        toast.success(t("templateUploadFlow.templateInactiveSuccess"));
        setData((prevData) => prevData.filter((item) => item.templateID !== templateID));
        handleCloseDeleteConfirmation();
        fetchTemplatesData();
      } else {
        toast.error(t("templateUploadFlow.failedToInactive"));
      }
    } catch (error) {
      toast.error(t("templateUploadFlow.errToggleDoc"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDeletion = async () => {
    //console.log(selectedTemplateID);
    if (selectedTemplateID) {
      handleCloseConfirmation();
      await handleDeleteTemplate(selectedTemplateID);
    }
  };

  const handleToggle = async (templateID) => {
    //console.log(templateID);
    setIsLoading(true);
    try {
      setTemplateID(templateID);

      const response = await axios.put(
        `/v1.0/api/DeactivateTemplate?TemplateID=${templateID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.statusCode === "1") {
        toast.success(t("templateUploadFlow.templateDeactivate"));
        setData((prevData) => prevData.filter((item) => item.templateID !== templateID));
        fetchTemplatesData();
      } else {
        toast.error(t("templateUploadFlow.failedDeactivate"));
      }
    } catch (error) {
      toast.error(t("templateUploadFlow.errToggleDoc"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDeactivation = async () => {
    //console.log(selectedTemplateID);
    if (selectedTemplateID) {
      handleCloseConfirmation();
      await handleToggle(selectedTemplateID);
    }
  };

  // Function to handle opening the confirmation modal
  const handleOpenConfirmation = (templateID) => {
    setSelectedTemplateID(templateID);
    setShowDeactivateModal(true);
  };

  // Function to handle closing the confirmation modal
  const handleCloseConfirmation = () => {
    setShowDeactivateModal(false);
    setSelectedTemplateID(null);
  };

  const handleOpenDeleteConfirmation = (templateID) => {
    setSelectedTemplateID(templateID);
    setShowDeleteModal(true);
  };

  // Function to handle closing the confirmation modal
  const handleCloseDeleteConfirmation = () => {
    setShowDeleteModal(false);
    setSelectedTemplateID(null);
  };

  const handleEditTemplateName = async (templateID, tempName) => {
    setSelectedTemplateID(templateID);
    setOriginalTemplateName(tempName);
    setShowModal(true);
  };

  const handleSaveTemplateName = (updatedTemplateName) => {
    setTemplateName(updatedTemplateName);
    fetchTemplatesData();
  };

  const handleEditDoc = (templateID) => {
    navigate("/editTemplates");
    localStorage.setItem("templateID", templateID);
  };

  const columns = [
    { label: t("tablesLabel.templateName"), key: "templateName" },
    { label: t("tablesLabel.date"), key: "createdOn" },
    { label: t("tablesLabel.scope"), key: "templateID" },
    { label: t("tablesLabel.status"), key: "templateName" },
    { label: "", key: "actions" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <>
      {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
        <></>
      ) : (
        <>
          <Grid className='container mt-3'>
            <Grid item xs={12}>
              {/* Loader overlay */}
              {isLoading && (
                <div className='loader-overlay'>
                  <Loader />
                </div>
              )}
              <div className=' mb-3 '>
                <TemplatesStatuses />
              </div>
              <div className='py-3 p-3 container pendingtable'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <label className='fs-21'>{t("templateUploadFlow.myTemplate")}</label>

                  <div className='search-filters-container'>
                    <TemplatesSearchFilter
                      startDate={startDate}
                      endDate={endDate}
                      Scope={Scope}
                      templateName={templateName}
                      docStatus={docStatus}
                      setDocStatus={setDocStatus}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      setScope={setScope}
                      setTemplateName={setTemplateName}
                      handleSearch={handleSearch}
                      clearSearch={clearSearch}
                    />
                  </div>
                </div>

                {/* Table */}
                <TableContainer component={Paper} style={{ height: "62vh" }}>
                  <Table style={{ border: "none" }}>
                    {/* Table Headers */}
                    <TableHead className='customTableHead'>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell key={column.label} style={{ backgroundColor: "#F0F3F4" }} className='TableHeaderCell'>
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                      {searchResults.length === 0 && (
                        <TableRow>
                          <td colSpan='6' className='p-5 text-center'>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <img alt='iamgeLoading' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                              {t("dashboardCardStatuses.notDocsFound")}
                            </div>
                          </td>
                        </TableRow>
                      )}
                      {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className='TableCell'>{row.templateName}</TableCell>
                          <TableCell className='TableCell'>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>
                          <TableCell className='TableCell'>{row.scope === "I" ? "Individual" : "Company"}</TableCell>
                          {/* <TableCell className='TableCell'>{row.isActive === true ? "Active" : "Inactive"}</TableCell> */}
                          <TableCell className='TableCell'>
                            <label
                              style={{
                                padding: "2px 12px",
                                borderRadius: "5px",
                                backgroundColor: row.isActive ? "#00cc4533" : "#eeeeee",
                                color: "black",
                              }}
                            >
                              {row.isActive ? "Active" : "Inactive"}
                            </label>
                          </TableCell>
                          <TableCell className='TableCell p-0'>
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} />

                              <Dropdown.Menu>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleOpenDeleteConfirmation(row.templateID)}>
                                  <img src={deleteImg} alt='iconLoading' />
                                  {t("templateUploadFlow.delete")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleOpenConfirmation(row.templateID)}>
                                  <img src={inactiveToggle} alt='iconLoading' />
                                  {t("templateUploadFlow.inactive")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleEditTemplateName(row.templateID, row.templateName)}>
                                  <img src={editName} alt='iconLoading' />
                                  {t("templateUploadFlow.rename")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleEditDoc(row.templateID)}>
                                  <img src={EditSquareIcon} alt='iconLoading' />
                                  {t("templateUploadFlow.editTemplate")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleOpenTemplateIDModal(row.templateID)}>
                                  <img src={templateicon} alt='iconLoading' />
                                  {t("templateUploadFlow.templateid")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </TableCell>
                          <TableCell style={{ padding: 0 }}></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Pagination */}
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
                  <div className='showHideNoDocs'>
                    {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, data.length)} {t("tablesLabel.documentsof")} {data.length} {t("tablesLabel.document")}
                  </div>
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(data.length / rowsPerPage)}
                      variant='outlined'
                      shape='rounded'
                      page={page + 1}
                      onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                      sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                    />
                  </Stack>
                </div>
              </div>
            </Grid>

            <TemplatesConfirmationModal
              show={showDeleteModal}
              handleClose={handleCloseDeleteConfirmation}
              handleConfirm={handleConfirmDeletion}
              title={t("templateConfirmationModal.deleteDocument")}
              button={t("templateConfirmationModal.delete")}
            />
            <TemplatesConfirmationModal show={showDeactivateModal} handleClose={handleCloseConfirmation} handleConfirm={handleConfirmDeactivation} title='Inactive Document' button='Inactive' />

            {/* edit template name modal */}
            {showModal && <EditTemplateNameModal originalTemplateName={originalTemplateName} templateID={selectedTemplateID} handleClose={() => setShowModal(false)} onSave={handleSaveTemplateName} />}

            {/* Template ID Modal */}
            <Modal show={showTemplateIDModal} centered onHide={handleCloseTemplateIDModal} dialogClassName='custom-modal-width' backdrop='static' keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>{t("templateUploadFlow.templateid")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='px-3 py-2'>
                <Modal.Body className='px-3 py-2'>
                  <div className='input-group'>
                    <input ref={templateIDRef} type='text' className='form-control' value={selectedTemplateID} readOnly />
                    <button style={{ border: "1px solid #ccc", background: "#fff", borderRadius: "5px", padding: "2px 3px" }} onClick={handleCopyTemplateID}>
                      {t("templateUploadFlow.copy")} <FaRegCopy />
                    </button>
                  </div>
                </Modal.Body>
              </Modal.Body>
            </Modal>
          </Grid>
        </>
      )}
    </>
  );
};

export default TemplatesPage;
