// import React, { useState } from 'react';
// import LogoImg from "../../assets/images/stepper-logo.svg";
// import { Nav } from 'react-bootstrap';
// import { Link } from 'react-router-dom';


// const Stepper = ({ activeStep, t }) => {
//     const [completedSteps, setCompletedSteps] = useState([]);

//     return (
//         <Nav className='step_number_holder d-none d-sm-block '>
//             <Nav.Item className="stepper-logo">
//                 <Nav.Link as={Link} to="/home">
//                     <img src={LogoImg} alt='imageLoading' style={{ height: "30px", width: "100px" }} />
//                 </Nav.Link>
//             </Nav.Item>

//             <Nav.Item>
//                 <Nav.Link className={`upload_step my-3 ${completedSteps.includes(1) || activeStep === 1 ? "active" : ""}`}>
//                     <span className='numb'>1</span>
//                     <em>{t("uploadDocScreen.uploadDocument")}</em>
//                 </Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//                 <Nav.Link className={`signatories_step my-3 ${completedSteps.includes(2) || activeStep === 2 ? "active" : ""}`} to='/second-step'>
//                     <span className='numb'>2</span>
//                     <em>{t("uploadDocScreen.placeSignatories")}</em>
//                 </Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//                 <Nav.Link className={`sign_step my-3 ${completedSteps.includes(3) || activeStep === 3 ? "active" : ""}`}>
//                     <span className='numb'>3</span>
//                     <em>{t("uploadDocScreen.sendAndSign")}</em>
//                 </Nav.Link>
//             </Nav.Item>
//         </Nav>
//     )
// }

// export default Stepper

import React, { useState } from 'react';
import LogoImg from "../../assets/images/stepper-logo.svg";
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './Stepper.css';  // Import the CSS file
import stepper_completed from "../../assets/images/stepper_completed.svg";

const Stepper = ({ activeStep, t, completedSteps }) => {

    const location = useLocation();

    const isCompleted = (step) => completedSteps && completedSteps.includes(step);
    const isActive = (step) => activeStep === step;

    const templatesStepper = location.pathname.includes("/createTemplates") || location.pathname.includes("/editTemplates");

    return (
        <Nav className='step_number_holder d-none d-md-block'>
            <Nav.Item className="stepper-logo">
                <Nav.Link as={Link} to="/home">
                    <img src={LogoImg} alt='imageLoading' style={{ height: "30px", width: "100px" }} />
                </Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link className={`upload_step my-3 ${isCompleted(1) ? 'completed' : isActive(1) ? 'active' : 'inactive'}`}>
                    <span className='numb'>{isCompleted(1) ? <img src={stepper_completed} alt='completed' /> : 1}</span>
                    <em>{t("uploadDocScreen.uploadDocument")}</em>
                </Nav.Link>
            </Nav.Item>
            <div className='d-flex justify-content-center align-items-center' style={{ width: "36%" }}><div className={`stepper-line ${isCompleted(1) ? 'stepper-line-completed' : isActive(1) ? 'stepper-line-active' : ''}`}></div></div>
            <Nav.Item>
                <Nav.Link className={`signatories_step my-3 ${isCompleted(2) ? 'completed' : isActive(2) ? 'active' : 'inactive'}`} >
                    <span className='numb'>{isCompleted(2) ? <img src={stepper_completed} alt='completed' /> : 2}</span>
                    <em>{t("uploadDocScreen.placeSignatories")}</em>
                </Nav.Link>
            </Nav.Item>

            {!templatesStepper ?
                (
                    <>
                        <div className='d-flex justify-content-center align-items-center' style={{ width: "36%" }}><div className={`stepper-line ${isCompleted(2) ? 'stepper-line-completed' : isActive(2) ? 'stepper-line-active' : ''}`}></div></div>

                        <Nav.Item>
                            <Nav.Link className={`sign_step my-3 ${isCompleted(3) ? 'completed' : isActive(3) ? 'active' : 'inactive'}`}>
                                <span className='numb'>{isCompleted(3) ? <img src={stepper_completed} alt='completed' /> : 3}</span>
                                <em>{t("uploadDocScreen.sendAndSign")}</em>
                            </Nav.Link>
                        </Nav.Item>
                    </>
                ) :
                null
            }
        </Nav>
    );
}

export default Stepper;
