// import React, { useEffect, useState } from "react";
// import { GrDrag } from "react-icons/gr";
// import { AiOutlineDelete } from "react-icons/ai";
// import { Button, Modal } from "react-bootstrap";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import axios from "../../Api/api";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import "./Imagethumbnails.css";
// import ScrollToTopButton from "../Step2/ScrolDown";

// const ImageThumbnails = ({ t, controls, selectedImage, setSelectedImage, setMasterDocPagesId, imagesData, setImagesData, buttons }) => {
//   // console.log(buttons)
//   const [deletingMasterPageID, setDeletingMasterPageID] = useState(null);
//   const [deletingIndex, setDeletingIndex] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [showConfirmModal, setShowConfirmModal] = useState(false);
//   const [seeMore, setSeeMore] = useState(false); // State for see more/less
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   // Listen for window width changes
//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   // Handle click on see more/less button
//   const handleSeeMoreClick = () => {
//     setSeeMore(!seeMore);
//   };

//   const navigate = useNavigate();

//   const token = sessionStorage.getItem("token");

//   useEffect(() => {
//     if (imagesData && imagesData.length > 0) {
//       setSelectedImage(imagesData[0].pagesPath);
//       setMasterDocPagesId(imagesData[0].masterDocPagesID);
//     }
//   }, [imagesData]);

//   const handleImageClick = (pagesPath, masterDocPagesID) => {
//     setSelectedImage(pagesPath);
//     setMasterDocPagesId(masterDocPagesID);
//   };

//   // const handleImageClick = (imagePath, masterDocPagesID) => {
//   //   setSelectedImage(imagePath);
//   //   setMasterDocPagesId(masterDocPagesID);
//   //   const normalizedImagePath = imagePath.replace(/\\/g, '\\\\')

//   //   const selectedImageElement = mainDocumentRef.current.querySelector(`img[src="${normalizedImagePath}"]`);

//   //   console.log(mainDocumentRef.current);
//   //   console.log(selectedImageElement, imagePath, normalizedImagePath);

//   //   if (selectedImageElement) {
//   //     const offsetTop = selectedImageElement.offsetTop;

//   //     mainDocumentRef.current.scrollTo({
//   //       top: offsetTop,
//   //       behavior: "smooth",
//   //     });
//   //   }
//   // };

//   // confirmation modal
//   const handleShowConfirmModal = (masterDocPagesID, pageIndex) => {
//     setDeletingMasterPageID(masterDocPagesID);
//     setDeletingIndex(pageIndex);
//     setShowConfirmModal(true);
//   };

//   const handleCloseConfirmModal = () => {
//     setShowConfirmModal(false);
//   };

//   const handleImageDelete = async (masterDocPagesID, pageIndex) => {
//     setIsLoading(true);
//     try {
//       const response = await axios.put(
//         "/v1.0/api/DeleteMasterDocPages",
//         {
//           MasterDocPagesID: masterDocPagesID,
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//         }
//       );

//       if (response.status === 200) {
//         const updatedImagesData = [...imagesData];
//         updatedImagesData.splice(pageIndex, 1);
//         setImagesData(updatedImagesData);

//         if (updatedImagesData.length === 0) {
//           setSelectedImage(null);
//         }
//         setIsLoading(false);
//       }
//       toast.success(t("uploadDocFlow.pageDeleted"), {
//         position: "top-right",
//       });
//     } catch (error) {
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info(t("sessionExpire"));
//       } else {
//         setIsLoading(false);
//         toast.error(t("uploadDocFlow.errDelete"));
//       }
//     } finally {
//       setShowConfirmModal(false);
//     }
//   };

//   const handleDragEnd = (result) => {
//     if (!result.destination) return;

//     const items = [...imagesData];
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     const newOrder = {
//       MasterDocPagesID: reorderedItem.masterDocPagesID,
//       PagesOrder: result.destination.index + 1,
//     };

//     setImagesData(items);
//     updateImagesOrder(newOrder);
//   };

//   const updateImagesOrder = async (newOrder) => {
//     try {
//       const response = await axios.put(
//         "/v1.0/api/UpdateMasterDocPagesOrdering",
//         newOrder,

//         {
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//     } catch (error) {
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info(t("uploadDocFlow.sessionExpire"));
//       } else {
//         toast.error(t("uploadDocFlow.errPages"));
//       }
//     }
//   };

//   return (
//     <>
//       <div className='scrollable-content-bookmark'>
//         <ScrollToTopButton />

//         <DragDropContext onDragEnd={handleDragEnd}>
//           <Droppable droppableId='image-list' direction='vertical'>
//             {(provided) => (
//               <div ref={provided.innerRef} {...provided.droppableProps}>
//                 {imagesData.length === 0 ? (
//                   <div className='no-pages-message'>{t("uploadDocFlow.pageNotFound")}</div>
//                 ) : (
//                   imagesData.map((imageData, index) => {
//                     const hasControls = controls && controls.some((control) => control.masterDocPagesID === imageData.masterDocPagesID);
//                     {
//                       /* console.log("has controls", hasControls, imageData.masterDocPagesID); */
//                     }

//                     return (
//                       <>
//                         <Draggable key={`image-${index}`} draggableId={`image-${index}`} index={index}>
//                           {(provided) => (
//                             <div
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                               className={`image-div my-2 mx-1 my-lg-4 mx-lg-3 ${selectedImage === imageData.pagesPath ? "selected" : ""} ${hasControls ? "has-buttons" : ""}`}
//                               onClick={() => handleImageClick(imageData.pagesPath, imageData.masterDocPagesID)}
//                             >
//                               <img src={imageData.pagesPath} alt={`Image ${index}`} />

//                               {buttons ? (
//                                 <div className={`actions-tab d-flex justify-content-center align-items-center ${selectedImage === imageData.pagesPath ? "selected" : ""}`}>
//                                   <Button className='deleteImgBtn mx-2' variant='none' onClick={() => handleShowConfirmModal(imageData.masterDocPagesID, index)}>
//                                     <AiOutlineDelete />
//                                   </Button>
//                                   <div className='drag-handle bg-transparent' {...provided.dragHandleProps}>
//                                     <GrDrag />
//                                   </div>
//                                 </div>
//                               ) : null}
//                             </div>
//                           )}
//                         </Draggable>
//                       </>
//                     );
//                   })
//                 )}
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         </DragDropContext>
//       </div>

//       {/* page delete modal */}
//       <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
//         <Modal.Header closeButton>
//           <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deletePage")}</Modal.Body>
//         <Modal.Footer>
//           <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
//             {t("uploadDocFlow.cancel")}
//           </Button>
//           <Button variant='none' className='applyBtn' onClick={() => handleImageDelete(deletingMasterPageID, deletingIndex)}>
//             {t("uploadDocFlow.delete")}
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default ImageThumbnails;

import React, { useEffect, useState } from "react";
import { GrDrag } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Modal, Collapse } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Imagethumbnails.css";
import ScrollToTopButton from "../Step2/ScrolDown";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ImageThumbnails = ({ t, controls, selectedImage, setSelectedImage, setMasterDocPagesId, imagesData, setImagesData, buttons }) => {
  const [deletingMasterPageID, setDeletingMasterPageID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (imagesData && imagesData.length > 0) {
      setSelectedImage(imagesData[0].pagesPath);
      setMasterDocPagesId(imagesData[0].masterDocPagesID);
    }
  }, [imagesData]);

  const handleImageClick = (pagesPath, masterDocPagesID) => {
    setSelectedImage(pagesPath);
    setMasterDocPagesId(masterDocPagesID);
  };

  const handleShowConfirmModal = (masterDocPagesID, pageIndex) => {
    setDeletingMasterPageID(masterDocPagesID);
    setDeletingIndex(pageIndex);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleImageDelete = async (masterDocPagesID, pageIndex) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteMasterDocPages",
        {
          MasterDocPagesID: masterDocPagesID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        const updatedImagesData = [...imagesData];
        updatedImagesData.splice(pageIndex, 1);
        setImagesData(updatedImagesData);

        if (updatedImagesData.length === 0) {
          setSelectedImage(null);
        }
        setIsLoading(false);
      }
      toast.success(t("uploadDocFlow.pageDeleted"), {
        position: "top-right",
      });
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("sessionExpire"));
      } else {
        setIsLoading(false);
        toast.error(t("uploadDocFlow.errDelete"));
      }
    } finally {
      setShowConfirmModal(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = [...imagesData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newOrder = {
      MasterDocPagesID: reorderedItem.masterDocPagesID,
      PagesOrder: result.destination.index + 1,
    };

    setImagesData(items);
    updateImagesOrder(newOrder);
  };

  const updateImagesOrder = async (newOrder) => {
    try {
      const response = await axios.put(
        "/v1.0/api/UpdateMasterDocPagesOrdering",
        newOrder,

        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.errPages"));
      }
    }
  };

  return (
    <>
      <div className='scrollable-content-bookmark'>
        <ScrollToTopButton />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='image-list' direction='vertical'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {imagesData.slice(0, seeMore ? imagesData.length : 5).map((imageData, index) => {
                  const hasControls = controls && controls.some((control) => control.masterDocPagesID === imageData.masterDocPagesID);

                  return (
                    <Draggable key={`image-${index}`} draggableId={`image-${index}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`image-div my-2 mx-1 my-lg-4 mx-lg-3 ${selectedImage === imageData.pagesPath ? "selected" : ""} ${hasControls ? "has-buttons" : ""}`}
                          onClick={() => handleImageClick(imageData.pagesPath, imageData.masterDocPagesID)}
                        >
                          <img src={imageData.pagesPath} alt={`Image ${index}`} />
                          {buttons ? (
                            <div className={`actions-tab d-flex justify-content-center align-items-center ${selectedImage === imageData.pagesPath ? "selected" : ""}`}>
                              <Button className='deleteImgBtn mx-2' variant='none' onClick={() => handleShowConfirmModal(imageData.masterDocPagesID, index)}>
                                <AiOutlineDelete />
                              </Button>
                              <div className='drag-handle bg-transparent' {...provided.dragHandleProps}>
                                <GrDrag />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!seeMore && imagesData.length > 5 && (
          <div className='seemorebutton' onClick={() => setSeeMore(true)}>
            <Button style={{ border: "none", color: "black", padding: "0px" }} variant='none'>
              {t("uploadDocFlow.seemore")}
            </Button>
            <IoIosArrowDown />
          </div>
        )}
        {seeMore && (
          <div className='seemorebutton' onClick={() => setSeeMore(false)}>
            <Button style={{ border: "none", color: "black", padding: "0px" }} variant='none'>
              {t("uploadDocFlow.seeLess")}
            </Button>
            <IoIosArrowUp />
          </div>
        )}
      </div>
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deletePage")}</Modal.Body>
        <Modal.Footer>
          <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
            {t("uploadDocFlow.cancel")}
          </Button>
          <Button variant='none' className='applyBtn' onClick={() => handleImageDelete(deletingMasterPageID, deletingIndex)}>
            {t("uploadDocFlow.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageThumbnails;
