import React from "react";
import { useUser } from "../../Context/UserContext";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import "./style.css";
import { Col, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function DaysToExpire() {
  const { user, userAccess } = useUser();
  const { t } = useTranslation();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <div className='userProfile-form px-4'>
        <div className='d-flex align-items-center gap-3 px-2'>
          <HiOutlineCalendarDays size={30} />
          <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("DaysToExpire.SubscribedPlanDetail")}</label>
        </div>

        <Row className='my-2'>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("DaysToExpire.AccountHolder")}</Form.Label>
              <div className='form-static-text'>{user.fullName}</div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("DaysToExpire.PackageName")}</Form.Label>
              <div className='form-static-text'>{userAccess.packageName}</div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("DaysToExpire.DaysRemainingExpire")}</Form.Label>
              <div className='form-static-text'>{userAccess.remainingDays}</div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("DaysToExpire.TotalDays")}</Form.Label>
              <div className='form-static-text'>{userAccess.totalDays}</div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("DaysToExpire.StartDate")}</Form.Label>
              <div className='form-static-text'>{formatDate(userAccess.subscriptionStartDate)}</div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>{t("DaysToExpire.EndDate")}</Form.Label>
              <div className='form-static-text'>{formatDate(userAccess.subscriptionEndDate)}</div>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DaysToExpire;
