import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button, Form, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import noDocImg from "../../assets/images/Folder_empty.svg";
import FodlerIcon from "../../assets/images/Folderblack.svg";
import deleteIcon from "../../assets/images/Delete.svg";
import updateIcon from "../../assets/images/Edit.svg";
import documentIcon from "../../assets/images/FolderAdd.svg";
import FolderDocs from "../../assets/images/folder fill.svg";
import CustomToggle from "../CustomDots/CustomToggle";
import ViewFiles from "../../assets/images/Show.svg";
import AddCompletedDocuments from "./AddCompletedDocuments";
import { useUser } from "../../Context/UserContext";
import UserLisence from "../UserLisence/UserLisence";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";

function Folder() {
  const [showFolder, setShowFolder] = useState(false);
  const { user, userAccess } = useUser("");
  const [showTotalFolder, setTotalShowFolder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ FolderName: "", FolderType: "Files", Description: "" });
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({ FolderID: "", FolderName: "", FolderType: "", Description: "" });
  const [selectedFolderId, setSelectedFolderId] = useState(false);
  const [showDeleteConfirmatrionFolderModal, setShowDeleteConfirmatrionFolderModal] = useState(false);
  const [showDeleteConfirmatrionFolderID, setShowDeleteConfirmatrionFolderID] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const token = sessionStorage.getItem("token");

  const GetFoldersandDocs = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetFolder", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const GetFoldersData = response.data.response;
        setTotalShowFolder(GetFoldersData);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetFoldersandDocs();
  }, []);

  const navigateToViewFiles = (folderId, folderName) => {
    const folderPath = `/folders/${folderName}/${folderId}`;
    navigate(folderPath);
  };

  const handleViewDocuments = (folderId, folderName) => {
    setSelectedFolderId(folderId);
    navigate(`/folders/${folderName}/${folderId}`);
  };

  const handleAddDocumentClick = (folderID) => {
    setSelectedFolderId(folderID);
    setShowAddDocument(true);
  };

  const handleAddDocumentClose = () => {
    GetFoldersandDocs();
    setShowAddDocument(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateFolder", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.createFolderSuccess"));
        setFormData({ FolderName: "", Description: "" });
        setShowFolder(false);
        GetFoldersandDocs();
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error(t("Folder.errCreateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseOffcanvas = () => {
    setShowFolder(false);
  };

  const handleCancel = () => {
    setShowFolder(false);
  };

  const handleUpdateFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.put("/v1.0/api/UpdateFolder", updateFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.folderUpdateSuccess"));
        GetFoldersandDocs();
        setShowUpdateOffcanvas(false);
      }
    } catch (error) {
      console.error("Error updating folder:", error);
      toast.error(t("Folder.errUpdateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseUpdate = () => {
    setShowUpdateOffcanvas(false);
  };

  const handleUpdateClick = (row) => {
    const { folderID, folderName, description, folderType } = row;
    setUpdateFormData({ FolderID: folderID, FolderName: folderName, Description: description, FolderType: folderType });
    setShowUpdateOffcanvas(true);
  };

  const HandleDeleteFolderOnly = async () => {
    const data = {
      FolderID: showDeleteConfirmatrionFolderID,
    };
    try {
      setIsLoading(true);
      const response = await axios.delete("/v1.0/api/DeleteFolder", {
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.deleteSuccess"));
        setShowDeleteConfirmatrionFolderModal(false);
        GetFoldersandDocs();
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      toast.error(t("Folder.errDeleteFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFolderModal = (folderID) => {
    setShowDeleteConfirmatrionFolderID(folderID);
    setShowDeleteConfirmatrionFolderModal(true);
  };

  return (
    <>
      {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
        <>
          <UserLisence />
        </>
      ) : (
        <>
          {userAccess.folders === true ? (
            <>
              <div className='container mt-3'>
                {isLoading && (
                  <div className='loader-overlay'>
                    <Loader />
                  </div>
                )}

                <Offcanvas show={showFolder} onHide={handleCloseOffcanvas} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title> {t("Folder.errDeleteFolder")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className='mb-3' controlId='folderName'>
                        <Form.Label> {t("Folder.folderName")}</Form.Label>
                        <Form.Control type='text' name='FolderName' value={formData.FolderName} onChange={handleInputChange} placeholder={t("Folder.enterfodlerName")} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='description'>
                        <Form.Label>{t("Folder.Description")}</Form.Label>
                        <Form.Control as='textarea' name='Description' value={formData.Description} onChange={handleInputChange} rows={3} placeholder={t("Folder.enterDescription")} />
                      </Form.Group>
                      <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                        <Button variant='none' className='resetBtn' onClick={handleCancel}>
                          {t("Folder.Cancel")}
                        </Button>
                        <Button type='submit' variant='none' disabled={!formData.FolderName.trim()} className='applyBtn'>
                          {t("Folder.Create")}
                        </Button>
                      </div>
                    </Form>
                  </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showUpdateOffcanvas} onHide={handleCloseUpdate} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("Folder.updateFolder")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <hr />
                  <Offcanvas.Body>
                    <Form onSubmit={handleUpdateFormSubmit}>
                      <Form.Group className='mb-3' controlId='updateFolderName'>
                        <Form.Label> {t("Folder.folderName")}</Form.Label>
                        <Form.Control type='text' name='FolderName' value={updateFormData.FolderName} onChange={handleUpdateInputChange} placeholder={t("Folder.enterfodlerName")} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='updateDescription'>
                        <Form.Label>{t("Folder.Description")}</Form.Label>
                        <Form.Control as='textarea' name='Description' value={updateFormData.Description} onChange={handleUpdateInputChange} rows={3} placeholder={t("Folder.enterDescription")} />
                      </Form.Group>
                      <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                        <Button variant='none' className='resetBtn' onClick={handleCloseUpdate}>
                          {t("Folder.Cancel")}
                        </Button>
                        <Button type='submit' variant='none' className='applyBtn'>
                          {t("Folder.Create")}
                        </Button>
                      </div>
                    </Form>
                  </Offcanvas.Body>
                </Offcanvas>

                <button className='templateButton' onClick={() => setShowFolder(true)}>
                  {t("Folder.createFolder")}
                </button>

                <div className='userProfile-form container mt-3'>
                  <div className='d-flex align-items-center mb-4 gap-3'>
                    <img src={FodlerIcon} alt='folderimage' style={{ height: "30px", width: "30px" }} />
                    <label style={{ fontSize: "20px", fontWeight: "600" }}>{t("Folder.Allfolders")}</label>
                  </div>

                  <hr />

                  {showTotalFolder.length === 0 ? (
                    <div className='text-center'>
                      <img src={noDocImg} alt='folder Image' style={{ height: "120px", width: "180px" }} />
                      <p>{t("Folder.nofolder")}</p>
                    </div>
                  ) : (
                    <div>
                      {showTotalFolder.map((row, index) => (
                        <div key={index} className='folderDivStyle'>
                          <div onClick={() => navigateToViewFiles(row.folderID, row.folderName)}>
                            <div className='d-flex align-items-center gap-2'>
                              {row.numberOfFiles > 0 ? (
                                <div className='FolderDivSpan'>
                                  <img src={FolderDocs} alt='fileIcon' style={{ height: "30px", width: "30px" }} />
                                </div>
                              ) : (
                                <img src={FodlerIcon} alt='emptyFolderIcon' style={{ height: "30px", width: "30px" }} />
                              )}
                              <span style={{ fontSize: "18px", fontWeight: "500", margin: "0px" }}>{row.folderName}</span>
                            </div>
                            <p className='mb-0 mx-5'>{row.description}</p>
                          </div>
                          <div className='d-flex align-items-center  justify-content-end gap-4'>
                            <span style={{ fontSize: "12px" }}>{row.numberOfFiles} Document</span>
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} />
                              <Dropdown.Menu>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUpdateClick(row)}>
                                  <img src={updateIcon} alt='editImage' />
                                  {t("Folder.update")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewDocuments(row.folderID, row.folderName)}>
                                  <img src={ViewFiles} alt='documentImage' />
                                  {t("Folder.view")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleAddDocumentClick(row.folderID)}>
                                  <img src={documentIcon} alt='deleteImage' />
                                  {t("Folder.add")}
                                </Dropdown.Item>
                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleDeleteFolderModal(row.folderID)}>
                                  <img src={deleteIcon} alt='deleteImage' />
                                  {t("Folder.delete")}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <Modal show={showDeleteConfirmatrionFolderModal} onHide={() => setShowDeleteConfirmatrionFolderModal(false)} dialogClassName='custom-modal-width'>
                  <Modal.Header closeButton>
                    <Modal.Title> {t("Folder.deleteConfirmation")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='px-3 py-2'>
                    {t("Folder.sureDeleteFolder")}
                    <div className=' mt-4 d-flex justify-content-end gap-5 '>
                      <Button variant='none' className='modalCancelButton' onClick={() => setShowDeleteConfirmatrionFolderModal(false)}>
                        {t("Folder.Cancel")}
                      </Button>
                      <Button className='applyBtn' variant='none' onClick={HandleDeleteFolderOnly}>
                        {t("Folder.Confirm")}
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <AddCompletedDocuments show={showAddDocument} handleClose={handleAddDocumentClose} folderId={selectedFolderId} />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default Folder;
