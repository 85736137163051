import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./modal.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../Api/api";
import Loader from "../Loader/Loader";

const ShareModal = ({ show, handleClose, masterDocID, registerSignID }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState();
  const [emailSubject, setEmailSubject] = useState();
  const [emailBody, setEmailBody] = useState();
  const [error, setError] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    Document: false,
    AuditTrail: false,
    SupportingDocs: false,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    // Check if all fields are filled
    if (email && emailSubject && emailBody) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, emailSubject, emailBody]);

  const token = sessionStorage.getItem("token");

  const handleCheckboxChange = (checkbox) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [checkbox]: !prevSelected[checkbox],
    }));
  };

  useEffect(() => {
    setSelectedCheckboxes({
      Document: false,
      AuditTrail: false,
      SupportingDocs: false,
    });
  }, [show]);

  const handleShare = async () => {
    const requestBody = {
      Email: email,
      Subject: emailSubject,
      Body: emailBody,
      MasterDocID: masterDocID,
      RegisterSignID: registerSignID,
      IsDocShared: selectedCheckboxes.Document ? true : false,
      IsAuditTrailDocShared: selectedCheckboxes.AuditTrail ? true : false,
      IsSupportingDocShared: selectedCheckboxes.SupportingDocs ? true : false,
    };

    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/CreateDocShared", requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.shareFileSucessToast"));
      }
      resetFields();
      handleClose();
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.statusCode === "4") {
        toast.error(t("dashboardCardStatuses.unathorizedErrSharedFile"));
        resetFields();
      }

      if (error.response && error.response.data && error.response.data.statusCode === "3") {
        toast.error(t("shareModal.alreadyShared"));
        resetFields();
      } else {
        toast.error(t("shareModal.shareFileToastErr"));
        resetFields();
      }
      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    if (!email || !emailSubject || !emailBody) {
      setError("this field is required");
      return;
    } else {
      setError("");
    }

    if (!selectedCheckboxes.Document && !selectedCheckboxes.AuditTrail && !selectedCheckboxes.SupportingDocs) {
      toast.error("Select at least one option");
    } else {
      handleShare();

      handleClose();
    }
  };

  const resetFields = () => {
    setEmail("");
    setEmailSubject("");
    setEmailBody("");
    setSelectedCheckboxes({
      Document: false,
      AuditTrail: false,
      SupportingDocs: false,
    });
  };

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <Modal show={show} onHide={handleClose} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("shareModal.shareDoc")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <strong>{t("shareModal.chooseDoc")}</strong>

          <div className='form-check mt-2'>
            <input type='checkbox' className='form-check-input' id='shareDocument' checked={selectedCheckboxes.Document} onChange={() => handleCheckboxChange("Document")} />
            <label className='form-check-label' htmlFor='shareDocument'>
              {t("viewModal.orgDoc")}
            </label>
          </div>

          <div className='form-check'>
            <input type='checkbox' className='form-check-input' id='shareAuditTrail' checked={selectedCheckboxes.AuditTrail} onChange={() => handleCheckboxChange("AuditTrail")} />
            <label className='form-check-label' htmlFor='shareAuditTrail'>
              {t("viewModal.auditTrail")}
            </label>
          </div>

          <div className='form-check mb-2'>
            <input type='checkbox' className='form-check-input' id='shareSupportingDocs' checked={selectedCheckboxes.SupportingDocs} onChange={() => handleCheckboxChange("SupportingDocs")} />
            <label className='form-check-label' htmlFor='shareSupportingDocs'>
              {t("viewModal.supportingDoc")}
            </label>
          </div>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>{t("shareModal.email")}</Form.Label>
            <div>
              <input type='email' placeholder='e.g. mail@mail.com ' required value={email} onChange={(e) => setEmail(e.target.value)} />
              {error && <span className='text-danger'>{t("shareModal.fieldRequired")}</span>}
            </div>
          </Form.Group>

          <Form.Group className='mt-2' controlId='formBasicSubject'>
            <Form.Label>{t("shareModal.subject")}</Form.Label>
            <div>
              <input className='input-modal' placeholder='e.g. Subject ' require value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} />
            </div>
            {error && <span className='text-danger'>{t("shareModal.fieldRequired")}</span>}
          </Form.Group>

          <Form.Group className='mt-2' controlId='formBasicBody'>
            <Form.Label>{t("shareModal.body")}</Form.Label>
            <div>
              <textarea className='input-modal' placeholder='enter your message here' value={emailBody} onChange={(e) => setEmailBody(e.target.value)} cols={55} rows={2}></textarea>
            </div>
            {error && <span className='text-danger'>{t("shareModal.fieldRequired")}</span>}
          </Form.Group>


          <div className=' mt-4 d-flex justify-content-between gap-5 '>
            <Button variant='none' className='modalCancelButton' onClick={handleClose}>
              {t("shareModal.cancel")}
            </Button>
            <Button className='applyBtn' variant='none' type='submit' disabled={isDisabled} onClick={handleSubmit}>
              {t("shareModal.shareFile")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
