import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { staticToken } from "../../Token/token";
import { toast } from "react-toastify";
import { Button, Form, Accordion, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useUser } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import emailIcon from "../../assets/images/sms-edit.svg";
import ReactSwitch from "react-switch";
import Loader from "../Loader/Loader";

function Delegation() {
  const { t } = useTranslation();
  const refreshToken = staticToken;

  const [isLoading, setIsLoading] = useState(false);

  const { user, userAccess } = useUser();
  const [countryCodes, setCountryCodes] = useState([]);
  const [delegateData, setDelegateData] = useState(null);
  const [activeDelegate, setActiveDelegate] = useState(false);
  const [buttonHide, setButtonHide] = useState(false);
  const token = sessionStorage.getItem("token");

  const [formData, setFormData] = useState({
    DelegatedFromEmails: "",
    DelegatedToEmail: "",
    DelegatedToCountryCode: "",
    DelegatedToPhone: "",
    StartDate: "",
    EndDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setButtonHide(true);
  };

  const handleToggleChange = (isChecked) => {
    setActiveDelegate(isChecked);
    ActivateDelegation(isChecked);
  };

  const CreateDelegation = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/v1.0/api/CreateDelegation", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success("Delegation created successfully.");
        setFormData({
          ...formData,
          DelegatedFromEmails: user.email, // Set DelegatedFromEmails to user's email
          DelegatedToEmail: "",
          DelegatedToCountryCode: "",
          DelegatedToPhone: "",
          StartDate: "",
          EndDate: "",
        });
        setButtonHide(false);
        getDelegation();
      } else {
        toast.error("Failed to create delegation.");
      }
    } catch (error) {
      toast.error("Error creating delegation.");
    }
  };

  const ActivateDelegation = async (isChecked) => {
    try {
      const response = await axios.put(
        "/v1.0/api/ActivateDelegation",
        {
          SignDelegateID: delegateData.signDelegateID,
          IsActive: isChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.response === true) {
        toast.success("Delegation activated successfully.");
        getDelegation();
      } else if (response.data.response === false) {
        toast.success("Successfully deactivated");
        getDelegation();
      } else {
        toast.error("Error activating delegation.");
      }
    } catch (error) {
      console.error("Error activating delegation.");
      setIsLoading(false);
    }
  };

  const getDelegation = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetDelegate", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const userInfo = response.data.response;
        setDelegateData(userInfo);
        setFormData({
          ...formData,
          DelegatedFromEmails: userInfo.delegatedFromEmails,
          DelegatedToEmail: userInfo.delegatedToEmail,
          DelegatedToCountryCode: userInfo.delegatedToCountryCode,
          DelegatedToPhone: userInfo.delegatedToPhone,
          StartDate: userInfo.startDate,
          EndDate: userInfo.endDate,
        });
        setActiveDelegate(userInfo.isActive);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const response = await axios.put(
        "/v1.0/api/UpdateDelegation",
        {
          ...formData,
          SignDelegateID: delegateData.signDelegateID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success("Successfully Updated");
      }
      setButtonHide(false);
      getDelegation();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Initialize DelegatedFromEmails with user's email on component mount
    setFormData((prevFormData) => ({
      ...prevFormData,
      DelegatedFromEmails: user.email,
    }));
  }, [user.email]);

  useEffect(() => {
    getDelegation();
  }, [userAccess.remainingDays]);

  useEffect(() => {
    const countryCode = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/Account/GetCountryCodes", {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        if (response.data.statusCode === "1") {
          const countryCodeList = response.data.response;
          setCountryCodes(countryCodeList);
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    countryCode();
  }, []);

  const {
    formState: { errors },
  } = useForm();

  const handleCancel = () => {
    setFormData({
      DelegatedFromEmails: delegateData.delegatedFromEmails,
      DelegatedToEmail: delegateData.delegatedToEmail,
      DelegatedToCountryCode: delegateData.delegatedToCountryCode,
      DelegatedToPhone: delegateData.delegatedToPhone,
      StartDate: delegateData.startDate,
      EndDate: delegateData.endDate,
    });
    setButtonHide(false);
  };

  const handleClearAndCancel = () => {
    setFormData({
      ...formData,
      DelegatedFromEmails: user.email, // Set DelegatedFromEmails to user's email
      DelegatedToEmail: "",
      DelegatedToCountryCode: "",
      DelegatedToPhone: "",
      StartDate: "",
      EndDate: "",
    });
    setButtonHide(false);
  };

  return (
    <div className='container my-3 p-0'>
      {userAccess.remainingDays <= 0 ? (
        <></>
      ) : (
        <>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>
                <div className='d-flex align-items-center gap-3'>
                  <img src={emailIcon} alt='IconLoading' />
                  <label>{t("userProfile.Delegation")}</label>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {isLoading && (
                  <div className='loader-overlay'>
                   <Loader />
                  </div>
                )}

                <Form className='my-3' onSubmit={CreateDelegation}>
                  <Row className='justify-content-end m-0'>
                    <Col md={2}>
                      <Form.Group controlId='StartDate'>
                        <Form.Label>{t("userProfile.StartDate")}</Form.Label>
                        <Form.Control type='date' name='StartDate' value={formData.StartDate ? formData.StartDate.slice(0, 10) : ""} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group controlId='EndDate'>
                        <Form.Label>{t("userProfile.EndDate")}</Form.Label>
                        <Form.Control type='date' name='EndDate' value={formData.EndDate ? formData.EndDate.slice(0, 10) : ""} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <Form.Group controlId='DelegatedToEmail'>
                        <Form.Label>{t("userProfile.DelegatedEmail")}</Form.Label>
                        <Form.Control type='email' placeholder="Enter user's email" name='DelegatedToEmail' value={formData.DelegatedToEmail} onChange={handleChange} required />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <div>
                        <Form.Label className='form-label'>{t("logScreens.mobileNumber")}</Form.Label>
                        <div className='input-group'>
                          <Form.Select
                            as='select'
                            value={formData.DelegatedToCountryCode}
                            onChange={(e) => {
                              setFormData({ ...formData, DelegatedToCountryCode: e.target.value });
                              console.log("Updated formData:", { DelegatedToCountryCode: e.target.value });
                            }}
                            className={`form-control ${errors && errors.CountryCode ? "error-border" : ""}`}
                            style={{ width: "15%" }}
                          >
                            <option value='' disabled>
                              {t("logScreens.Codes")}
                            </option>
                            {countryCodes.map((country) => (
                              <option key={country.lookupID} value={country.lookupID}>
                                {country.lookupID}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            type='text'
                            name='DelegatedToPhone'
                            placeholder={t("logScreens.mobileNumber")}
                            value={formData.DelegatedToPhone}
                            onChange={handleChange}
                            pattern='[0-9]{11,15}'
                            minLength={10}
                            maxLength={15}
                            style={{ width: "calc(80% - 40px)" }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div style={{ display: "flex", gap: "10px", marginTop: "33px" }}>
                        <span className='password-protected'>{t("userProfile.ActivateDelegation")}</span>
                        <ReactSwitch
                          checked={activeDelegate}
                          onChange={handleToggleChange}
                          onColor='#0c9ad7'
                          onHandleColor='#ffffff'
                          handleDiameter={15}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={50}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* New fields for StartDate and EndDate */}

                  {delegateData === null
                    ? buttonHide && (
                        <div div className='d-flex gap-2 justify-content-start my-2'>
                          <Button variant='none' className='ImageDoneBtn' type='submit'>
                            {t("userProfile.save")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleClearAndCancel}>
                            {t("userProfile.cancel")}
                          </Button>
                        </div>
                      )
                    : buttonHide && (
                        <div div className='d-flex gap-2 justify-content-start my-2'>
                          <Button variant='none' className='ImageDoneBtn' onClick={handleUpdate}>
                            {t("userProfile.update")}
                          </Button>
                          <Button variant='none' className='ImageCancelBtn' onClick={handleCancel}>
                            {t("userProfile.cancel")}
                          </Button>
                        </div>
                      )}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      )}

    </div>
  );
}

export default Delegation;
