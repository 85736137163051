
import React, { useEffect } from "react";
import "./Comments.css";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useState } from "react";
import { Button, Dropdown, DropdownButton, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import commentButton from '../../assets/images/Send.svg';
import downloadIcon from '../../assets/images/download.svg';
import viewIcon from '../../assets/images/Show.svg';
import deleteIcon from '../../assets/images/Delete.svg';
import editIcon from '../../assets/images/Edit Square.svg';

const Comments = ({ registerSignID, signAuth, signatoryEmail, signatoryName }) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [docCommentsIDs, setDocCommentsIDs] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [commentToDeleteIndex, setCommentToDeleteIndex] = useState(null);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [supportingDocs, setSupportingDocs] = useState([]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);

    if (e.key === "Enter") {
      if (!e.target.value) {
        toast.error(t("addComment.enterValidValue"));
        return;
      }
      addComment(e);
    }
  };

  const addComment = async () => {
    if (!newComment) {
      toast.error(t("addComment.enterValidValue"));
      return;
    }

    const requestBody = {
      Comments: newComment,
      AdhockUserID: "3c9a2b68-f820-4fd2-a32a-5eec2e5f00cd",
      CommentPostedBy: signatoryName,
      CreatedBy: signatoryEmail,
      MasterDocID: null,
      RegisterSignID: registerSignID,
    };

    setIsLoading(true);

    try {
      const response = await axios.post("/v1.0/api/CreateDocCommentsVerification", requestBody, {
        headers: {
          SignAuth: signAuth,
        },
      });

      if (response.status === 200) {
        getSupportingDocs();
        setDocCommentsIDs([...docCommentsIDs, response.data.response]);
        setNewComment("");
        setIsLoading(false);
        toast.success(t("addComment.commentAdded"));

        try {
          const response = await axios.post(
            "/v1.0/api/CreateSignActivityVerification",
            {
              Status: t("addComment.newCommentAdded"),
              Message: `${signatoryName} ${t("addComment.addedNewComment")} [${newComment}]`,
            },
            {
              headers: {
                signAuth: signAuth,
              },
            }
          );
        } catch (error) { }
      }
    } catch (error) {
      // console.error("Error:", error);
      setIsLoading(false);
      toast.error(t("addComment.failedToAdd"));
    }
  };

  const handleDeleteComment = async (docCommentsID, index) => {
    setIsLoading(true);
    const requestBody = {
      DocCommentsID: docCommentsID,
      ModifiedBy: signatoryEmail,
    };
    try {
      const response = await axios.put("/v1.0/api/DeleteDocCommentsVerification", requestBody, {
        headers: {
          SignAuth: signAuth,
        },
      });

      if (response.status === 200) {
        const updatedComments = [...comments];
        updatedComments.splice(index, 1);
        const updatedDocCommentsIDs = [...docCommentsIDs];
        updatedDocCommentsIDs.splice(index, 1);
        setComments(updatedComments);
        setDocCommentsIDs(updatedDocCommentsIDs);
        setIsLoading(false);
        toast.success(t("addComment.commentDeleted"));

        try {
          const response = await axios.post(
            "/v1.0/api/CreateSignActivityVerification",
            {
              Status: "Comment deleted",
              Message: `${signatoryName} deleted a comment`,
            },
            {
              headers: {
                signAuth: signAuth,
              },
            }
          );
        } catch (error) { }
      }
    } catch (error) {
      // console.error("Error:", error);
      setIsLoading(false);
      toast.error(t("addComment.failedTodelete"));
    } finally {
      setShowConfirmModal(false);
    }
  };

  const toggleEdit = (index) => {
    setEditingIndex(index);
    setEditedText(comments[index].text);
  };

  const saveEditedComment = async (docCommentsID) => {
    const updatedText = editedText;

    const requestBody = {
      DocCommentsID: docCommentsID,
      Comments: updatedText,
      ModifiedBy: signatoryEmail,
    };

    setIsLoading(true);
    try {
      const response = await axios.put("/v1.0/api/UpdateDocCommentsVerification", requestBody, {
        headers: {
          SignAuth: signAuth,
        },
      });

      if (response.status === 200) {
        const updatedComments = [...comments];
        // //console.log("updated commet", updatedComments);
        const index = updatedComments.findIndex((comment) => comment.docCommentsID === docCommentsID);

        if (index !== -1) {
          updatedComments[index].comments = updatedText;
          // updatedComments[index].edited = true;
          setComments(updatedComments);
          setEditingIndex(-1);
          setIsLoading(false);
          toast.success(t("addComment.commentUpdate"));

          try {
            const response = await axios.post(
              "/v1.0/api/CreateSignActivityVerification",
              {
                Status: " Comment edited",
                Message: `${signatoryName} edited comment as [${updatedText}]`,
              },
              {
                headers: {
                  signAuth: signAuth,
                },
              }
            );
          } catch (error) { }
        }
      }
    } catch (error) {
      // console.error("Error:", error);
      toast.error(t("addComment.failedToUpdate"));
      setIsLoading(false);
    }
  };

  const getSupportingDocs = async () => {
    try {
      const response = await axios.get("/v1.0/api/GetSupportingDocAndDocCommentsVerification", {
        headers: {
          SignAuth: signAuth,
        },
      });

      // //console.log("supportingDocs/Comments", response);
      if (response.data.successStatus) {
        const allComments = response.data.response.docComments;
        setComments(allComments);
        setDocCommentsIDs(response.data.response.docComments.map((comment) => comment.docCommentsID));

        setSupportingDocs(response.data.response.supportingDocs);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // //console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (signAuth) {
      getSupportingDocs();
    }
  }, [signAuth]);

  const handleFileDownload = async (doc) => {
    setIsLoading(true);
    try {
      const response = await fetch(doc.supportingDocPath);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = doc.supportingDocName;
      downloadLink.click();

      try {
        const response = await axios.post(
          "/v1.0/api/CreateSignActivityVerification",
          {
            Status: "file downloaded",
            Message: `${signatoryName} downloaded  ${doc.supportingDocName} file`,
          },
          {
            headers: {
              signAuth: signAuth,
            },
          }
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      // console.error("Error fetching or creating Blob:", error);
    }
  };

  const handleFileview = async (doc) => {
    window.open(doc.supportingDocPath, "_blank");
    try {
      const response = await axios.post(
        "/v1.0/api/CreateSignActivityVerification",
        {
          Status: "file viewed",
          Message: `${signatoryName} viewed  ${doc.supportingDocName} file`,
        },
        {
          headers: {
            signAuth: signAuth,
          },
        }
      );
    } catch (error) { }
  };
  // comments delete confirmation modal
  const handleShowConfirmModal = (docCommentsID, index) => {
    setDeletingCommentId(docCommentsID);
    setCommentToDeleteIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      <h6 className='comments-heading'> {t("Comments.Comments")}</h6>
      <div className='comment-section'>
        <ListGroup className='comment-list'>
          {comments.map((comment, index) => (
            <ListGroup.Item key={index} className='comment-body'>
              {editingIndex === index ? (
                // Editing mode
                <>
                  <Form.Control type='text' value={editedText} onChange={(e) => setEditedText(e.target.value)} className='comment-save-input' />
                  <Button variant='none' className='py-1 px-2  m-1 applyBtn' onClick={() => saveEditedComment(comment.docCommentsID)}>
                    {t("addComment.save")}
                  </Button>
                </>
              ) : (
                // Viewing mode
                <>
                  <div className='signature-comment-body'>
                    <div className='comment-header  mb-0 p-0 d-flex align-items-center justify-content-between'>
                      {comment.commentPostedBy || "no name"}
                      {comment.createdBy === signatoryEmail ? (
                        // Not in editing mode
                        <div>
                          <Button variant='none' className='action-button px-1 py-0' onClick={() => toggleEdit(index)}>
                            <img src={editIcon} alt='edit' />
                          </Button>
                          <Button variant='none' className='action-button px-1 py-0' onClick={() => handleShowConfirmModal(docCommentsIDs[index], index)}>
                            <img src={deleteIcon} alt='delete' />
                          </Button>
                        </div>
                      ) : // In editing mode
                        null}
                    </div>
                    <div className='comment-text text-start m-0'>{comment.comments}</div>
                    <small className='timestamp text-start'>
                      {comment.createdDate}
                      <span className='mx-1'></span>
                      {comment.createdTime}
                      {comment.modifiedOn !== null && <span className='edited-label mx-2 fw-normal'>{t("addComment.edited")}</span>}
                    </small>
                  </div>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>

        <div className='d-flex align-items-center justify-content-between p-2'>
          <InputGroup className='new-comment'>
            <Form.Control type='text' placeholder={t("Comments.addCommentHere")} value={newComment} onChange={handleCommentChange} onKeyPress={handleCommentChange} className='comment-input' />
          </InputGroup>
          <Button onClick={addComment} className='comment-button ms-1'>
            <img src={commentButton} alt='send button' />
          </Button>
        </div>
      </div>

      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("addComment.confirmDelete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("addComment.deleteComment")}</Modal.Body>
        <Modal.Footer>
          <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
            {t("addComment.cancel")}
          </Button>
          <Button variant='none' className='applyBtn' onClick={() => handleDeleteComment(deletingCommentId, commentToDeleteIndex)}>
            {t("addComment.delete")}
          </Button>
        </Modal.Footer>
      </Modal>

        <h6 className='m-0 py-3 ps-3 d-flex gap-2 border-bottom'>{t("addComment.supportingDocuments")} </h6>
      <div className='supporting-docs'>
        <div className="supporting-docs-list"> {supportingDocs.map((doc, index) => (
          <div key={index} className='docs-name border-bottom d-flex'>
            <p className='px-2 my-2  d-flex align-items-center'>
              {index + 1} - {doc.supportingDocName}
            </p>
            <DropdownButton title='' id='doc-dropdown' variant='none'>
              <Dropdown.Item className='d-flex justify-content-start align-items-center gap-2' onClick={() => handleFileDownload(doc)}>
                <img src={downloadIcon} alt='downloadIcon' />
                {t("addComment.download")}
              </Dropdown.Item>
              <Dropdown.Item className='d-flex justify-content-start align-items-center gap-2' onClick={() => handleFileview(doc)}>
                <img src={viewIcon} alt='viewIcon' />

                {t("addComment.view")}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        ))}</div>
       
      </div>
    </>
  );
};

export default Comments;
