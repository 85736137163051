import React, { useState, useEffect } from "react";
import DropboxChooser from "react-dropbox-chooser";
import { Form } from "react-bootstrap";
import { FaDropbox } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "../../../Api/api";
import { useTranslation } from "react-i18next";
import dropboxIcon from "../../../assets/images/dropboxUpload.svg";
import Loader from "../../../Components/Loader/Loader";

function Dropbox({ uploadedFiles, setUploadedFiles, masterDocIDs, setMasterDocIDs, fileToggleStates, setFileToggleStates, TemplateID, setTemplateID, templateName, templateScope }) {
  const { t } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  // const [masterDocIDs, setMasterDocIDs] = useState([]);
  const [isSerial, setIsSerial] = useState(true);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [dropboxFiles, setDropboxFiles] = useState([]);

  const token = sessionStorage.getItem("token");
  const handleSuccess = (files) => {
    // setSelectedFiles(files);
    // Assuming only one file is selected
    setDropboxFiles(files);
    if (files.length === 1) {
      setSelectedFileUrl(files[0].link);
      setSelectedFileName(files[0].name);
      //console.log("files", files);
    }
  };
  const handleCancel = () => {
    // setSelectedFiles([]);
    setSelectedFileUrl("");
    setSelectedFileName("");
  };
  const fetchBase64 = async (url, fileName) => {
    setIsLoading(true);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = function () {
        const base64String = reader.result.split(",")[1];
        //console.log("File as Base64:", fileName, base64String);
        handleFileUpload(fileName, base64String);
        //console.log(uploadedFiles);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error downloading file:", error);
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (fileName, base64String) => {
    // const allowedFileTypes = [
    //   "application/pdf",
    //   "application/msword",
    //   "application/vnd.ms-powerpoint",
    //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    //   "application/vnd.ms-excel",
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // ];

    // const validFiles = dropboxFiles.filter((file) => allowedFileTypes.includes(file.type));

    // if (validFiles.length === 0) {
    //   setUploadErrorMessage("Format not allowed.");
    //   setIsLoading(false);
    // } else {
    //   setUploadErrorMessage("");

    const base64Files = [];
    base64Files.push({
      name: fileName,
      base64: base64String,
      MasterDocID: null,
    });

    //console.log("before API...uploaded+ base64", uploadedFiles, base64Files, base64Files.length);
    //
    const uploadedFileNames = uploadedFiles.map((fileName) => fileName);
    //console.log("names", uploadedFileNames);

    const duplicateFiles = base64Files.filter((uploadedFile) => uploadedFileNames.includes(uploadedFile.name));

    //console.log("duplicates", duplicateFiles, duplicateFiles.length);
    if (duplicateFiles.length > 0) {
      setUploadErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
      setIsLoading(false);
    } else {
      const body = {
        TemplateName: templateName,
        TemplateID: TemplateID,
        Scope: templateScope == "Individual" ? "I" : "B",
        PrimaryDocs: base64Files.map((uploadedFile) => {
          const fileExtension = fileName.split(".").pop();

          return {
            DocName: uploadedFile.name,
            DocExtension: fileExtension,
            DocFile: base64String,
            IsPasswordProtected: false,
          };
        }),
      };

      try {
        const response = await axios.post("/v1.0/api/TemplateDoc", body, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (response.data.successStatus) {
          // console.log("response", response);
          setTemplateID(response.data.response.templateID);
          localStorage.setItem("templateID", response.data.response.templateID);

          setMasterDocIDs((prevIDs) => [...prevIDs, response.data.response.masterDocs[0].masterDocIDs]);

          const newUploadedFiles = base64Files.map((uploadedFile) => uploadedFile.name);

          setUploadedFiles((prevFiles) => [...prevFiles, ...newUploadedFiles]);

          setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

          setUploadErrorMessage("");
          setSelectedFile(null);
          toast.success(t("uploadDocFlow.fileuploadSuccessfully"));

          setIsLoading(false);
        } else {
          setUploadErrorMessage(t("uploadDocFlow.apireqFailed"));
          setIsLoading(false);
        }
      } catch (error) {
        setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
        toast.error(t("uploadDocFlow.errorUploadFile"), {
          position: "top-right",
        });
        //console.log(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedFileUrl && selectedFileName) {
      const modifiedUrl = selectedFileUrl.replace("www.dropbox.com", "dl.dropboxusercontent.com").replace("?dl=0", "?raw=1");
      fetchBase64(modifiedUrl, selectedFileName);
    }
  }, [selectedFileUrl, selectedFileName]);
  const APP_KEY = "rqqpqwswwxtxbuq";
  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <DropboxChooser s appKey={APP_KEY} success={handleSuccess} cancel={handleCancel} multiselect={false}>
        <div className='uplad_box'>
          <div className='upload_trigger_bx'>
            <img alt='' src={dropboxIcon} />
            <div className='upload_doc_part'>
              <Form.Group controlId='file-input-add' className='file-upload_temp'>
                <Form.Label>{t("uploadDocFlow.dropbox")}</Form.Label>
                <Form.Control className='temp_file-input-additional' type='file' name='file' accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx' />
              </Form.Group>
              <p>{t("uploadDocFlow.selectFilesToUploadDropbox")}</p>
            </div>
          </div>
        </div>
      </DropboxChooser>
    </>
  );
}
export default Dropbox;
