import React, { useState, useEffect } from "react";
import "./FirstStep.css";
import { BiErrorCircle } from "react-icons/bi";
import { Button, Col, Container, Form, Modal, Row, Dropdown } from "react-bootstrap";
import AddRecipient from "./AddRecipient/AddRecipient";
import axios from "../../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Dropbox from "./Dropbox";
import UploadFromGoogleDrive from "./GoogleDrive";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../Context/UserContext";
import templateDocIcon from "../../../assets/images/templateDocs.svg";
import UploadDocIcon from "../../../assets/images/Paper Plus.svg";
import uploadLocalIcon from "../../../assets/images/monitorUpload.svg";
import pdfFileIcon from "../../../assets/images/pdfFile.svg";
import ViewFileIcon from "../../../assets/images/Show.png";
import DeleteIcon from "../../../assets/images/Delete.svg";
import CustomToggle from "../../../Components/CustomDots/CustomToggle";
import Loader from "../../../Components/Loader/Loader";

const FirstStep = ({ setActiveStep, setCompletedSteps }) => {
  const { t } = useTranslation();
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [recipientFormsValid, setRecipientFormsValid] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [recipientErrorMessage, setRecipientErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [templateName, setTemplateName] = useState();
  const [templateScope, setTemplateScope] = useState("Individual");
  const [isLoading, setIsLoading] = useState(false);
  const [fileToggleStates, setFileToggleStates] = useState([]);
  const [registerSignID, setRegisterSignID] = useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [fetchedData, setFetchedData] = useState({ primaryDocs: [] });
  const [masterDocIDs, setMasterDocIDs] = useState([]);
  const [docName, setDocName] = useState("");
  const [documentPages, setDocumentPages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingMasterDocID, setDeletingMasterDocID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [recipientFormsLength, setRecipientFormsLength] = useState(0);
  const [isSerial, setIsSerial] = useState(true);
  const { user, loading, userAccess } = useUser();

  const storedTemplateID = localStorage.getItem("templateID");
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const handleDropboxFileSelected = (fileUrl) => {
    setSelectedFileUrl(fileUrl);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/v1.0/api/GetTemplateData?TemplateID=${storedTemplateID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (response.data.successStatus) {
          setFetchedData(response.data.response);

          // Ensure primaryDocs is an array and not undefined
          if (Array.isArray(response.data.response.primaryDocs)) {
            const uploadedDocs = response.data.response.primaryDocs.map((doc) => doc.docName);
            setUploadedFiles(uploadedDocs);

            const initialFileToggleStates = uploadedDocs.map(() => false);
            setFileToggleStates(initialFileToggleStates);

            setTemplateID(response.data.response.templateID);
            setTemplateName(response.data.response.templateName);

            response.data.response.scope !== null ? setTemplateScope(response.data.response.scope) : setTemplateScope("Individual");

            // setTemplateScope(response.data.response.scope);

            setFormData(response.data.response);

            const masterDocIDs = response.data.response.primaryDocs.map((doc) => doc.masterDocID);
            setMasterDocIDs(masterDocIDs);

            const newToggleStates = response.data.response.primaryDocs.map((doc) => doc.isPasswordProtected);
            setFileToggleStates(newToggleStates);
          }
        }
      } catch (error) {
        if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      }
    };

    fetchData();
  }, [userAccess]);

  const handleFileUpload = async (event) => {
    setIsLoading(true);
    const newFiles = Array.from(event.target.files);

    //console.log("new files", newFiles);
    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/vnd.ms-powerpoint",
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));

    if (validFiles.length === 0) {
      setUploadErrorMessage(t("uploadDocFlow.formatNotAllow"));
      setIsLoading(false);
    } else {
      setUploadErrorMessage("");
      const base64Files = [];

      for (const file of validFiles) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const base64Data = e.target.result.split(",")[1];
          base64Files.push({
            name: file.name,
            base64: base64Data,
            MasterDocID: null,
          });

          console.log(base64Files);
          if (base64Files.length === validFiles.length) {
            const uploadedFileNames = uploadedFiles.map((fileName) => fileName);

            const duplicateFiles = base64Files.filter((uploadedFile) => uploadedFileNames.includes(uploadedFile.name));

            if (duplicateFiles.length > 0) {
              setUploadErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
              toast.error(t("uploadDocFlow.filewithSameNameExits"), {
                position: "top-right",
              });
              setIsLoading(false);
            } else {
              const body = {
                TemplateName: templateName,
                TemplateID: templateID,
                Scope: templateScope == "Individual" ? "I" : "B",
                PrimaryDocs: base64Files.map((uploadedFile) => {
                  const fileExtension = uploadedFile.name.split(".").pop();
                  return {
                    DocName: uploadedFile.name,
                    DocExtension: fileExtension,
                    DocFile: uploadedFile.base64,
                    IsPasswordProtected: false,
                  };
                }),
              };

              try {
                const response = await axios.post("/v1.0/api/TemplateDoc", body, {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                });

                if (response.data.statusCode === "1") {
                  setRegisterSignID(response.data.response.registerSignID);
                  localStorage.setItem("registerSignID", response.data.response.registerSignID);
                  // template
                  setTemplateID(response.data.response.templateID);
                  localStorage.setItem("templateID", response.data.response.templateID);

                  setMasterDocIDs((prevIDs) => [...prevIDs, response.data.response.masterDocs[0].masterDocIDs]);

                  setUploadedFiles([...uploadedFiles, ...base64Files.map((uploadedFile) => uploadedFile.name)]);
                  console.log([...uploadedFiles]);

                  setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

                  setUploadErrorMessage("");
                  // const recipientFormsLength = response.data.response.signatories.length;
                  // // console.log("length", recipientFormsLength);
                  // setRecipientFormsLength(recipientFormsLength);
                  // setSelectedFile(null);
                  toast.success(t("uploadDocFlow.fileuploadSuccessfully"));

                  setIsLoading(false);
                } else {
                  setUploadErrorMessage(t("uploadDocFlow.apireqFailed"));
                  setIsLoading(false);
                }
              } catch (error) {
                setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
                toast.error(t("uploadDocFlow.errorUploadFile"));

                setIsLoading(false);
              }
            }
          }
        };

        reader.readAsDataURL(file);

        reader.onerror = (error) => {
          isLoading(false);
        };
      }
    }
  };

  // console.log(uploadedFiles);

  const handleFileview = async (masterDocID, fileName) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetMasterDocPages?MasterDocID=${masterDocID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        const pages = response.data.response;
        const pagesMap = {};
        pages.forEach((page) => {
          pagesMap[page.pagesName] = page;
        });
        setDocumentPages(pagesMap);
        setDocName(fileName);
        setShowModal(true);
        setIsLoading(false);
      } else {
        // console.error("API request failed");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.filecantView"));
      }
    }
  };

  const handlePasswordProtected = async (newChecked, index, masterDoCID) => {
    const updatedToggleStates = [...fileToggleStates];
    updatedToggleStates[index] = newChecked;
    setFileToggleStates(updatedToggleStates);

    try {
      const response = await axios.put(
        "/v1.0/api/IsPasswordProtected",
        {
          MasterDocID: masterDoCID,
          IsPasswordProtected: newChecked,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // confirmation modal
  const handleShowConfirmModal = (masterDocID, index) => {
    setDeletingMasterDocID(masterDocID);
    setDeletingIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleFileDelete = async (masterDocID, index) => {
    //console.log(masterDocID, index);
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteMasterDoc",
        {
          MasterDocID: masterDocID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles.splice(index, 1);
        setUploadedFiles(updatedUploadedFiles);

        const updatedMasterDocIDs = [...masterDocIDs];
        updatedMasterDocIDs.splice(index, 1);
        setMasterDocIDs(updatedMasterDocIDs);

        setIsLoading(false);
      }

      toast.success(t("uploadDocFlow.fileDeleted"));
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errorDeletingFiles"));
      }
      setIsLoading(false);
    } finally {
      handleCloseConfirmModal();
      setIsLoading(false);
    }
  };

  const handleRecipientFormsValidation = (isValid) => {
    setRecipientFormsValid(isValid);
  };

  const handleRecipientFormsLength = (length) => {
    setRecipientFormsLength(length);
  };

  const handleContinue = async () => {
    if (recipientFormsLength < 1 && uploadedFiles.length > 0) {
      toast.error(t("uploadDocFlow.recipientForm"));
    } else if (recipientFormsLength > 0 && uploadedFiles.length === 0) {
      toast.error(t("uploadDocFlow.uploadAtleastOneFile"));
    } else if (recipientFormsLength === 0 && uploadedFiles.length === 0) {
      toast.error(t("uploadDocFlow.uploadAtleastOneFile"));
      toast.error(t("uploadDocFlow.recipientForm"));
    } else {
      setActiveStep(2);
      setCompletedSteps([1]);
    }
  };

  return (
    <>
      <Container className='uploadDoc-container'>
        {/* <div className='upload_body' id='part_one'> */}
        <div style={{ padding: "15px", backgroundColor: "#FFF", borderRadius: "10px", marginTop: "1rem" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <img alt='iconLoading' src={templateDocIcon} />
            <h4 className='m-0'>{t("templateUploadFlow.templateInfo")}</h4>
          </div>

          <Row className='d-flex flex-column my-4 gap-3'>
            <Col md={6}>
              <Form.Group controlId='templateName' className=' mx-2'>
                <Form.Label>
                  {t("templateUploadFlow.templateName")} <span className='required mx-1'>*</span>
                </Form.Label>
                <Form.Control type='text' value={templateName} onChange={(e) => setTemplateName(e.target.value)} placeholder={t("templateUploadFlow.enterTemplateName")} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId='templateName' className='mx-2'>
                <Form.Label>
                  {t("templateUploadFlow.templateScope")}
                  <span className='required mx-1'>*</span>
                </Form.Label>
                <div>
                  <div className='form-check form-check-inline particles-checkbox-container'>
                    <input
                      className='temp-particles-checkbox'
                      type='radio'
                      name='templateScope'
                      id='individual'
                      value='Individual'
                      checked={templateScope === "Individual"}
                      onChange={(e) => {
                        setTemplateScope(e.target.value);
                      }}
                    />
                    <label className='mx-2' htmlFor='individual'>
                      {t("templateUploadFlow.Individual")}
                    </label>
                  </div>
                  <div className='form-check form-check-inline particles-checkbox-container'>
                    <input
                      className='temp-particles-checkbox'
                      type='radio'
                      name='templateScope'
                      id='business'
                      value='Business'
                      checked={templateScope === "Business"}
                      onChange={(e) => {
                        setTemplateScope(e.target.value);
                      }}
                    />
                    <label className='mx-2' htmlFor='business'>
                      {t("templateUploadFlow.Company")}
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div style={{ padding: "15px", backgroundColor: "#FFF", borderRadius: "10px", marginTop: "1rem" }}>
          <div className='d-flex align-items-center gap-3 m-3'>
            <img src={UploadDocIcon} alt='iconLoading' />
            <h4 className='m-0'>{t("uploadDocFlow.documentsToUpload")}</h4>
          </div>
          <Container>
            <Row>
              <Col md={4}>
                <div className='uplad_box'>
                  <Form.Group controlId='file-input-add' className='file-upload_temp'>
                    <div className='upload_trigger_bx' onClick={() => document.querySelector(".temp_file-input-additional").click()}>
                      <img alt='iconLoading' src={uploadLocalIcon} />
                      <br />
                      <Form.Label>{t("uploadDocFlow.local")}</Form.Label>
                      <Form.Control
                        className='temp_file-input-additional'
                        style={{ display: "none" }}
                        type='file'
                        name='file'
                        accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
                        onChange={handleFileUpload}
                        multiple
                      />
                      <div className='upload_doc_part'>
                        <p>{t("uploadDocFlow.selectFilesToUpload")}</p>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Col>
              {/* {user.isDropbox && userAccess.dropbox && (
                <Col md={4}>
                  <Dropbox
                    onFileSelected={handleDropboxFileSelected}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    setTemplateID={setTemplateID}
                    TemplateID={templateID}
                    templateScope={templateScope}
                    templateName={templateName}
                  />
                </Col>
              )} */}
              {/* {user.isGoogleDrive && userAccess.googDrive && (
                <Col md={4}>
                  <UploadFromGoogleDrive
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    setTemplateID={setTemplateID}
                    TemplateID={templateID}
                    templateScope={templateScope}
                    templateName={templateName}
                  />
                </Col>
              )} */}

              {user.isDropbox &&
                (userAccess.dropbox ? (
                  <Col md={4}>
                    <Dropbox
                      onFileSelected={handleDropboxFileSelected}
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      masterDocIDs={masterDocIDs}
                      setMasterDocIDs={setMasterDocIDs}
                      fileToggleStates={fileToggleStates}
                      setFileToggleStates={setFileToggleStates}
                      setTemplateID={setTemplateID}
                      TemplateID={templateID}
                      templateScope={templateScope}
                      templateName={templateName}
                    />
                  </Col>
                ) : null)}
              {user.isGoogleDrive &&
                (userAccess.googDrive ? (
                  <Col md={4}>
                    <UploadFromGoogleDrive
                      uploadedFiles={uploadedFiles}
                      setUploadedFiles={setUploadedFiles}
                      masterDocIDs={masterDocIDs}
                      setMasterDocIDs={setMasterDocIDs}
                      fileToggleStates={fileToggleStates}
                      setFileToggleStates={setFileToggleStates}
                      setTemplateID={setTemplateID}
                      TemplateID={templateID}
                      templateScope={templateScope}
                      templateName={templateName}
                    />
                  </Col>
                ) : null)}
            </Row>

            <br />
            {uploadErrorMessage && (
              <p className='error-message'>
                <BiErrorCircle className='mb-1 mx-2' />
                {uploadErrorMessage}
              </p>
            )}

            {isLoading && (
              <div className='loader-overlay'>
                <Loader />
              </div>
            )}

            <div className='upload_file_box' id='upload_file_bx '>
              {uploadedFiles.map((fileName, index, file) => (
                <div key={index} className='uploaded-file d-flex align-items-center justify-content-between px-3 border-bottom'>
                  <p className='m-0 d-flex align-items-center gap-2'>
                    <img src={pdfFileIcon} alt='iconLoading' />
                    {fileName}
                  </p>

                  {/* <label className='password-protected-label ms-auto mx-3 mt-1 d-flex align-items-center'>
                      <span className='mx-2 password-protected'></span>
                      <ReactSwitch
                        onChange={(newChecked) => handlePasswordProtected(newChecked, index, masterDocIDs[index])}
                        checked={fileToggleStates[index] || false}
                        onColor='#FE5800'
                        onHandleColor='#ffffff'
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={25}
                        width={50}
                      />
                    </label> */}

                  {/* <div> */}
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <Dropdown.Menu>
                        <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileview(masterDocIDs[index], fileName)}>
                          <img src={ViewFileIcon} alt='iconLoading' />
                          {t("uploadDocFlow.view")}
                        </Dropdown.Item>
                        <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShowConfirmModal(masterDocIDs[index], index)}>
                          <img src={DeleteIcon} alt='iconLoading' />
                          {t("uploadDocFlow.delete")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Button className='temp-view-button' variant='none' onClick={() => handleFileview(masterDocIDs[index], fileName)}>
                        <FaEye />
                      </Button>

                      <Button className='delete-button' variant='none' onClick={() => handleShowConfirmModal(masterDocIDs[index], index)}>
                        <AiOutlineDelete />
                      </Button> */}
                  {/* </div> */}
                </div>
              ))}
            </div>

            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
              <Modal.Header closeButton>
                <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteFile")}</Modal.Body>
              <Modal.Footer>
                <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                  {t("uploadDocFlow.cancel")}
                </Button>
                <Button variant='none' className='applyBtn' onClick={() => handleFileDelete(deletingMasterDocID, deletingIndex)}>
                  {t("uploadDocFlow.delete")}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton className='btn'>
                <Modal.Title>{docName}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='d-flex'>
                <Col>
                  {isLoading ? (
                    <div className='loader d-flex justify-content-center  align-items-center'>Loading...</div>
                  ) : (
                    Object.values(documentPages).map((page, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <img src={page.pagesPath} alt={page.pagesName} style={{ width: "100%", height: "auto" }} />
                        <div className='page-name-footer'>{page.pagesName}</div>
                      </div>
                    ))
                  )}
                </Col>
              </Modal.Body>
            </Modal>

            {/*  */}
          </Container>
        </div>
        {/* </div> */}

        {/* <hr />
        <SignatoryOrientation
          onOrientationChange={handleOrientationChange}
          isSerial={isSerial}
        /> */}

        <AddRecipient
          onRecipientFormsValidation={handleRecipientFormsValidation}
          onRecipientFormsLength={handleRecipientFormsLength}
          TemplateID={templateID}
          registerSignID={registerSignID}
          uploadedFiles={uploadedFiles}
        />

        {recipientErrorMessage && (
          <p className='error-message'>
            <BiErrorCircle className='mb-1 ms-5 me' />
            {recipientErrorMessage}
          </p>
        )}

        {/* {fetchedData && (
          <Container className="px-0 px-md-5 pb-3">
            <ReferenceNumber
              fetchedData={fetchedData}
              setFetchedData={setFetchedData}
              setReferenceNumber={setReferenceNumber}
            />

            <Description
              fetchedData={fetchedData}
              setFetchedData={setFetchedData}
              setDescription={setDescription}
            />
          </Container>
        )} */}

        <div className='step_button_holder mt-3'>
          <Button className='Continue-btn mb-3 Continue-btn' id='hide_part_one' onClick={handleContinue}>
            {t("uploadDocFlow.Continue")}
          </Button>
        </div>
      </Container>
    </>
  );
};

export default FirstStep;

// const FirstStep = ({ setActiveStep }) => {
//   const { t } = useTranslation();

//   const [selectedFileUrl, setSelectedFileUrl] = useState("");
//   const [uploadedFiles, setUploadedFiles] = useState([]);
//   const [recipientFormsValid, setRecipientFormsValid] = useState(false);
//   const [uploadErrorMessage, setUploadErrorMessage] = useState("");
//   const [recipientErrorMessage, setRecipientErrorMessage] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [fileToggleStates, setFileToggleStates] = useState([]);
//   const [registerSignID, setRegisterSignID] = useState(null);
//   const [description, setDescription] = useState("");
//   const [referenceNumber, setReferenceNumber] = useState("");
//   const [fetchedData, setFetchedData] = useState({ primaryDocs: [] });
//   const [masterDocIDs, setMasterDocIDs] = useState([]);
//   const [docName, setDocName] = useState("");
//   const [documentPages, setDocumentPages] = useState({});
//   const [templateScope, setTemplateScope] = useState("individual");
//   const [templateID, setTemplateID] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [formData, setFormData] = useState(null);
//   const [showConfirmModal, setShowConfirmModal] = useState(false);
//   const [deletingMasterDocID, setDeletingMasterDocID] = useState(null);
//   const [deletingIndex, setDeletingIndex] = useState(null);
//   const [recipientFormsLength, setRecipientFormsLength] = useState(0);
//   const [isSerial, setIsSerial] = useState(true);
//   const [templatesModalShow, setTemplatesModalShow] = useState(false);
//   const [selectedFileSource, setSelectedFileSource] = useState("");
//   const [showFileSelector, setShowFileSelector] = useState(false);
//   const [allTemplates, setAllTemplates] = useState([]);
//   const [selectedMasterDocID, setSelectedMasterDocID] = useState([]);
//   const [isOnlySigner, setIsOnlySigner] = useState(false);
//   const [templateName, setTemplateName] = useState("");
//   const [childRender, setChildRender] = useState(false);

//   const storedTemplateID = localStorage.getItem("templateID");
//   const token = sessionStorage.getItem("token");
//   const navigate = useNavigate();

//   const handleUploadButtonClick = () => {
//     setShowFileSelector(!showFileSelector);
//   };

//   const handleFileSourceChange = (event) => {
//     setSelectedFileSource(event.target.value);
//   };

//   const handleDropboxFileSelected = (fileUrl) => {
//     setSelectedFileUrl(fileUrl);
//   };

//   const fetchData = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(`/v1.0/api/GetTemplateData?TemplateID=${storedTemplateID}`, {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       });

//       if (response.data.successStatus) {
//         setIsLoading(false);

//         setFetchedData(response.data.response)

//         // Ensure primaryDocs is an array and not undefined
//         if (Array.isArray(response.data.response.primaryDocs)) {
//           const uploadedDocs = response.data.response.primaryDocs.map((doc) => doc.docName);
//           setUploadedFiles(uploadedDocs);

//           const initialFileToggleStates = uploadedDocs.map(() => false);
//           setFileToggleStates(initialFileToggleStates);

//           setTemplateID(response.data.response.templateID);
//           setTemplateName(response.data.response.templateName);
//           setReferenceNumber(response.data.response.referenceNumber);
//           setDescription(response.data.response.description);
//           setIsSerial(response.data.response.isSerial);

//           setFormData(response.data.response);

//           const masterDocIDs = response.data.response.primaryDocs.map((doc) => doc.masterDocID);
//           setMasterDocIDs(masterDocIDs);

//           const newToggleStates = response.data.response.primaryDocs.map((doc) => doc.isPasswordProtected);
//           setFileToggleStates(newToggleStates);
//         }
//       }
//     } catch (error) {
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info("session expired", {
//           position: "top-right",
//         });
//       }
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [token]);

//   const handleOrientationChange = (serial) => {
//     setIsSerial(serial);
//   };

//   const handleFileUpload = async (event) => {
//     setIsLoading(true);
//     const newFiles = Array.from(event.target.files);

//     //console.log("new files", newFiles);
//     const allowedFileTypes = [
//       "application/pdf",
//       "application/msword",
//       "application/vnd.ms-powerpoint",
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//       "application/vnd.openxmlformats-officedocument.presentationml.presentation",
//       "application/vnd.ms-excel",
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//     ];

//     const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));

//     if (validFiles.length === 0) {
//       setUploadErrorMessage(t("uploadDocFlow.formatNotAllow"));
//       setIsLoading(false);
//     } else {
//       setUploadErrorMessage("");
//       const base64Files = [];

//       for (const file of validFiles) {
//         const reader = new FileReader();

//         reader.onload = async (e) => {
//           const base64Data = e.target.result.split(",")[1];
//           base64Files.push({
//             name: file.name,
//             base64: base64Data,
//             MasterDocID: null,
//           });

//           if (base64Files.length === validFiles.length) {
//             const uploadedFileNames = uploadedFiles.map((fileName) => fileName);

//             const duplicateFiles = base64Files.filter((uploadedFile) => uploadedFileNames.includes(uploadedFile.name));

//             if (duplicateFiles.length > 0) {
//               setUploadErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
//               toast.error(t("uploadDocFlow.filewithSameNameExits"), {
//                 position: "top-right",
//               });
//               setIsLoading(false);
//             } else {
//               const body = {
//                 TemplateName: templateName,
//                 CreatedBy: "EsignApp@macquires.com",
//                 TemplateID: templateID,
//                 Scope: templateScope == "individual" ? "I" : "B",
//                 PrimaryDocs: base64Files.map((uploadedFile) => {
//                   const fileExtension = uploadedFile.name.split(".").pop();

//                   return {
//                     DocName: uploadedFile.name,
//                     DocExtension: fileExtension,
//                     DocFile: uploadedFile.base64,
//                     IsPasswordProtected: false,
//                   };
//                 }),
//               };

//               try {
//                 const response = await axios.post("/v1.0/api/TemplateDoc", body, {
//                   headers: {
//                     Authorization: "Bearer " + token,
//                   },
//                 });

//                 if (response.data.successStatus) {
//                   setRegisterSignID(response.data.response.registerSignID);
//                   localStorage.setItem("registerSignID", response.data.response.registerSignID);
//                   // template
//                   setTemplateID(response.data.response.templateID);
//                   localStorage.setItem("templateID", response.data.response.templateID);

//                   setMasterDocIDs((prevIDs) => [...prevIDs, response.data.response.masterDocs[0].masterDocIDs]);

//                   setUploadedFiles([...uploadedFiles, ...base64Files.map((uploadedFile) => uploadedFile.name)]);

//                   setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

//                   setUploadErrorMessage("");
//                   setSelectedFile(null);
//                   toast.success(t("uploadDocFlow.fileuploadSuccessfully"));

//                   setIsLoading(false);
//                 } else {
//                   setUploadErrorMessage(t("uploadDocFlow.apireqFailed"));
//                   setIsLoading(false);
//                 }
//               } catch (error) {
//                 setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
//                 toast.error(t("uploadDocFlow.errorUploadFile"), {
//                   position: "top-right",
//                 });
//                 //console.log(error);
//                 setIsLoading(false);
//               }
//             }
//           }
//         };

//         reader.readAsDataURL(file);

//         reader.onerror = (error) => {
//           isLoading(false);
//         };
//       }
//     }
//   };

//   const handleFileview = async (masterDocID, fileName) => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(`/v1.0/api/GetMasterDocPages?MasterDocID=${masterDocID}`, {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       });

//       if (response.data.successStatus) {
//         const pages = response.data.response;
//         const pagesMap = {};
//         pages.forEach((page) => {
//           pagesMap[page.pagesName] = page;
//         });
//         setDocumentPages(pagesMap);
//         setDocName(fileName);
//         setShowModal(true);
//         setIsLoading(false);
//       } else {
//         // console.error("API request failed");
//         setIsLoading(false);
//       }
//     } catch (error) {
//       setIsLoading(false);
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info(t("uploadDocFlow.sessionExpire"), {
//           position: "top-right",
//         });
//       } else {
//         toast.error(t("uploadDocFlow.filecantView"));
//       }
//     }
//   };

//   const handlePasswordProtected = async (newChecked, index, masterDoCID) => {
//     const updatedToggleStates = [...fileToggleStates];
//     updatedToggleStates[index] = newChecked;
//     setFileToggleStates(updatedToggleStates);

//     try {
//       const response = await axios.put(
//         "/v1.0/api/IsPasswordProtected",
//         {
//           MasterDocID: masterDoCID,
//           IsPasswordProtected: newChecked,
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//     } catch (error) {
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info(t("uploadDocFlow.sessionExpire"), {
//           position: "top-right",
//         });
//       }
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   // confirmation modal
//   const handleShowConfirmModal = (masterDocID, index) => {
//     setDeletingMasterDocID(masterDocID);
//     setDeletingIndex(index);
//     setShowConfirmModal(true);
//   };

//   const handleCloseConfirmModal = () => {
//     setShowConfirmModal(false);
//   };

//   const handleFileDelete = async (masterDocID, index) => {
//     //console.log(masterDocID, index);
//     setIsLoading(true);
//     try {
//       const response = await axios.put(
//         "/v1.0/api/DeleteMasterDoc",
//         {
//           MasterDocID: masterDocID,
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//         }
//       );

//       if (response.data.successStatus) {
//         const updatedUploadedFiles = [...uploadedFiles];
//         updatedUploadedFiles.splice(index, 1);
//         setUploadedFiles(updatedUploadedFiles);

//         const updatedMasterDocIDs = [...masterDocIDs];
//         updatedMasterDocIDs.splice(index, 1);
//         setMasterDocIDs(updatedMasterDocIDs);

//         setIsLoading(false);
//       }

//       toast.success(t("uploadDocFlow.fileDeleted"));
//     } catch (error) {
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info(t("uploadDocFlow.sessionExpire"), {
//           position: "top-right",
//         });
//       } else {
//         toast.error(t("uploadDocFlow.errorDeletingFiles"));
//       }
//       setIsLoading(false);
//     } finally {
//       handleCloseConfirmModal();
//       setIsLoading(false);
//     }
//   };

//   const searchedTemplates = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get("/v1.0/api/GetTemplates", {
//         headers: {
//           Authorization: `Bearer ${token} `,
//         },
//         params: {
//           TemplateName: templateName,
//         },
//       });
//       if (response.data.successStatus) {
//         setAllTemplates(response.data.response);
//         setIsLoading(false);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setIsLoading(false);
//     }
//   }

//   const handleGetTemplate = async (masterDoCID) => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get("/v1.0/api/GetTemplates", {
//         headers: {
//           Authorization: `Bearer ${token} `,
//         },
//       });
//       if (response.data.successStatus) {
//         setAllTemplates(response.data.response);
//         setTemplatesModalShow(true);
//         setSelectedMasterDocID(masterDoCID);
//         setIsLoading(false);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setIsLoading(false);
//     }
//   };

//   const handleApplyTemplate = async (templateID) => {
//     setChildRender(false);
//     setIsLoading(true);
//     try {
//       const response = await axios.put(
//         "/v1.0/api/ApplyTemplate",
//         {
//           TemplateID: templateID,
//           RegisterSignID: registerSignID,
//           MasterDocID: selectedMasterDocID,
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//       if (response.data.successStatus) {
//         setChildRender(true);
//         setTemplatesModalShow(false);
//         setIsLoading(false);
//         toast.success("Template applied successfully");
//       }
//     } catch (error) {
//       if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setTemplatesModalShow(false);
//         setIsLoading(false);
//         navigate("/");
//         toast.info(t("uploadDocFlow.sessionExpire"), {
//           position: "top-right",
//         });
//       }
//     }
//   };

//   useEffect(() => {
//     //console.log(childRender);
//   })

//   const handleRecipientFormsValidation = (isValid) => {
//     setRecipientFormsValid(isValid);
//   };

//   const handleRecipientFormsLength = (length) => {
//     setRecipientFormsLength(length);
//   };

//   const handleContinue = async () => {
//     if (!recipientFormsValid && recipientFormsLength <= 1 && uploadedFiles.length > 0) {
//       setRecipientErrorMessage("Please fill recipient form.");
//     } else if (recipientFormsValid && uploadedFiles.length === 0) {
//       setUploadErrorMessage("Please upload at least one file.");
//     } else if (!recipientFormsValid && uploadedFiles.length === 0) {
//       setUploadErrorMessage("Please upload at least one file.");
//       setRecipientErrorMessage("Please fill recipient form.");
//     } else {
//       setActiveStep(2);
//     }
//   };

//   return (
//     <>
//       <Container className='upload-container'>
//         <div className='upload_body' id='part_one'>
//           {/* File source selector */}
//           <h2>{t("uploadDocFlow.documentsToUpload")}</h2>
//           {/* <div>
//             <Container className='main-div'>
//               <div className='start-upload-div'>
//                 {!showFileSelector && (
//                   <p className='start-text d-flex gap-2 align-items-center'>
//                     <IoCloudUploadOutline style={{ cursor: "pointer" }} size={40} onClick={handleUploadButtonClick} />
//                     {t("uploadDocFlow.uploadDocs")}
//                   </p>
//                 )}

//                 {showFileSelector && (
//                   <Form>
//                     <Form.Group>
//                       <Form.Label className='d-flex gap-2'>
//                         <IoCloudUploadOutline size={25} />
//                         {t("uploadDocFlow.doctoUpload")}
//                       </Form.Label>
//                       <Form.Control as='select' id='fileSource' value={selectedFileSource} onChange={handleFileSourceChange}>
//                         <option value=''>{t("uploadDocFlow.select")}</option>
//                         <option value='local'>{t("uploadDocFlow.local")}</option>
//                         <option value='googleDrive'>{t("uploadDocFlow.googleDrive")}</option>
//                         <option value='dropbox'>{t("uploadDocFlow.dropbox")}</option>
//                       </Form.Control>
//                     </Form.Group>
//                   </Form>
//                 )}
//               </div>
//             </Container>
//           </div> */}

//           <Row className='d-flex justify-content-between my-4'>
//             <Col md={6} >
//               <Form.Group controlId='templateName' className='coolinput mx-2'>
//                 <Form.Label className='text'>
//                   {t("navbar.templateName")} <span className='required mx-1'>*</span>
//                 </Form.Label>
//                 <Form.Control type='text' value={templateName} onChange={(e) => setTemplateName(e.target.value)} className='input' placeholder={t("navbar.enterTemplateName")} />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId='templateName' className='coolinput'>
//                 <Form.Label className='text'>
//                   Template Scope<span className='required mx-1'>*</span>
//                 </Form.Label>
//                 <Form.Select
//                   value={templateScope}
//                   onChange={(e) => setTemplateScope(e.target.value)}
//                   className='input'
//                 >
//                   <option value='' disabled>Select Template Scope</option>
//                   <option value='individual'>Individual</option>
//                   <option value='business'>Business</option>
//                 </Form.Select>
//               </Form.Group>
//             </Col>

//           </Row>

//           <Container>
//             <Row>
//               {/* {selectedFileSource === "local" && ( */}
//               <Col md={6}>
//                 <div className='upload_box'>
//                   <figure>
//                     <AiOutlineFileText color='#FE5800' />
//                   </figure>
//                   <div className='upload_trigger_bx'>
//                     <div className='upload_doc_part'>
//                       <h4>{t("uploadDocFlow.uploadDocs")}</h4>
//                       <Form.Group controlId='file-input-add' className='file-upload'>
//                         <Form.Label>{t("uploadDocFlow.uploadFromSystem")}</Form.Label>
//                         <Form.Control className='file-input-additional' type='file' name='file' accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx' onChange={handleFileUpload} multiple />
//                       </Form.Group>
//                       <p>{t("uploadDocFlow.selectFilesToUpload")}</p>
//                     </div>
//                   </div>
//                 </div>
//               </Col>
//               {/* )} */}
//               {/* {selectedFileSource === "dropbox" && ( */}
//               <Col md={6}>
//                 <Dropbox
//                   onFileSelected={handleDropboxFileSelected}
//                   uploadedFiles={uploadedFiles}
//                   setUploadedFiles={setUploadedFiles}
//                   masterDocIDs={masterDocIDs}
//                   setMasterDocIDs={setMasterDocIDs}
//                   fileToggleStates={fileToggleStates}
//                   setFileToggleStates={setFileToggleStates}
//                   registerSignID={registerSignID}
//                   setRegisterSignID={setRegisterSignID}
//                 />
//               </Col>
//               {/* )} */}

//               {/* {selectedFileSource === "googleDrive" && ( */}
//               <Col md={6} className='mt-2'>
//                 <UploadFromGoogleDrive
//                   uploadedFiles={uploadedFiles}
//                   setUploadedFiles={setUploadedFiles}
//                   masterDocIDs={masterDocIDs}
//                   setMasterDocIDs={setMasterDocIDs}
//                   fileToggleStates={fileToggleStates}
//                   setFileToggleStates={setFileToggleStates}
//                   registerSignID={registerSignID}
//                   setRegisterSignID={setRegisterSignID}
//                 />
//               </Col>
//               {/* )} */}
//             </Row>

//             <br />
//             {uploadErrorMessage && (
//               <p className='error-message'>
//                 <BiErrorCircle className='mb-1 mx-2' />
//                 {uploadErrorMessage}
//               </p>
//             )}

//             {isLoading && (
//             )}

//             <div className='upload_file_box' id='upload_file_bx '>
//               {uploadedFiles.map((fileName, index, file) => (
//                 <div key={index} className='uploaded-file d-flex align-items-center justify-content-between px-3 border-bottom'>
//                   <p className='m-0'>
//                     {index + 1} - {fileName}
//                   </p>

//                   <label className='password-protected-label ms-auto mx-3 mt-1 d-flex align-items-center'>
//                     <span className='mx-2 password-protected'>{t("uploadDocFlow.passwordProtected")}</span>
//                     <ReactSwitch
//                       onChange={(newChecked) => handlePasswordProtected(newChecked, index, masterDocIDs[index])}
//                       checked={fileToggleStates[index] || false}
//                       onColor='#FE5800'
//                       onHandleColor='#ffffff'
//                       handleDiameter={20}
//                       uncheckedIcon={false}
//                       checkedIcon={false}
//                       height={25}
//                       width={50}
//                     />
//                   </label>

//                   {/* <div className="d-flex align-items-center">
//                     <Button className='view-button' variant='none' onClick={() => handleFileview(masterDocIDs[index], fileName)}>
//                       <FaEye />
//                     </Button>

//                     <Button className='delete-button' variant='none' onClick={() => handleShowConfirmModal(masterDocIDs[index], index)}>
//                       <AiOutlineDelete />
//                     </Button>

//                     <Dropdown >
//                       <Dropdown.Toggle as={CustomToggle} />
//                       <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => handleGetTemplate(masterDocIDs[index])}>Apply Template</Dropdown.Item>

//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </div>  */}
//                   <div className="d-flex align-items-center">
//                     <Button className='view-button' variant='none' onClick={() => handleFileview(masterDocIDs[index], fileName)}>
//                       <FaEye />
//                     </Button>

//                     <Button className='delete-button' variant='none' onClick={() => handleShowConfirmModal(masterDocIDs[index], index)}>
//                       <AiOutlineDelete />
//                     </Button>

//                   </div>
//                 </div>
//               ))}
//             </div>

//             <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
//               <Modal.Header closeButton>
//                 <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
//               </Modal.Header>
//               <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteFile")}</Modal.Body>
//               <Modal.Footer>
//                 <Button variant='none' onClick={handleCloseConfirmModal} className='form-btns'>
//                   {t("uploadDocFlow.cancel")}
//                 </Button>
//                 <Button variant='none' className='outline-button' onClick={() => handleFileDelete(deletingMasterDocID, deletingIndex)}>
//                   {t("uploadDocFlow.delete")}
//                 </Button>
//               </Modal.Footer>
//             </Modal>

//             <Modal show={showModal} onHide={handleCloseModal}>
//               <Modal.Header closeButton className='btn'>
//                 <Modal.Title>{docName}</Modal.Title>
//               </Modal.Header>
//               <Modal.Body className='d-flex'>
//                 <Col>
//                   {isLoading ? (
//                     <div className='loader d-flex justify-content-center  align-items-center'>Loading...</div>
//                   ) : (
//                     Object.values(documentPages).map((page, index) => (
//                       <div key={index} style={{ position: "relative" }}>
//                         <img src={page.pagesPath} alt={page.pagesName} style={{ width: "100%", height: "auto" }} />
//                         <div className='page-name-footer'>{page.pagesName}</div>
//                       </div>
//                     ))
//                   )}
//                 </Col>
//               </Modal.Body>
//             </Modal>

//             {/*  */}
//           </Container>
//         </div>

//         <AddRecipient onRecipientFormsValidation={handleRecipientFormsValidation} onRecipientFormsLength={handleRecipientFormsLength} registerSignID={registerSignID} uploadedFiles={uploadedFiles} setIsOnlySigner={setIsOnlySigner} isOnlySigner={isOnlySigner} childRender={childRender} />

//         {recipientErrorMessage && (
//           <p className='error-message'>
//             <BiErrorCircle className='mb-1 ms-5 me' />
//             {recipientErrorMessage}
//           </p>
//         )}

//         <div className='step_button_holder'>
//           <Button className='continue-btn' id='hide_part_one' onClick={handleContinue}>
//             {t("uploadDocFlow.Continue")}
//           </Button>
//         </div>
//       </Container>
//     </>
//   );
// };

// export default FirstStep;
