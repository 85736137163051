import React, { useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { GrDrag } from "react-icons/gr";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Col, Container, Form, Row, Accordion, Modal, } from "react-bootstrap";
import "./AddRecipient.css";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../Context/UserContext";
import ReactSwitch from "react-switch";
import SignatoryIcon from "../../assets/images/path.svg";
import { CiCirclePlus } from "react-icons/ci";
import Loader from "../Loader/Loader";

const AddRecipient = ({ onRecipientFormsValidation, onRecipientFormsLength, registerSignID, uploadedFiles, setIsOnlySigner, isOnlySigner, childRender }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [formNumbers, setFormNumbers] = useState([]);
  const [signatoryCreatedStates, setSignatoryCreatedStates] = useState([]);
  const [signatureTypes, setSignatureTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [signatoryColor, setSignatoryColor] = useState([]);
  const [formColorMap, setFormColorMap] = useState({});
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingSignatoryID, setDeletingSignatoryID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [formData, setFormData] = useState();
  const [validationErrors, setValidationErrors] = useState([]);
  const [showExpiryDate, setShowExpiryDate] = useState(false);
  const { user, userAccess } = useUser();
  const { t } = useTranslation();
  const [showOwnEmailField, setShowOwnEmailField] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [customEmail, setCustomEmail] = useState("");
  // const [isPasswordProtected, setIsPasswordProtected] = useState(false);

  const handleFormChange = (index, field, value) => {
    const newRecipientForms = [...recipientForms];

    // Ensure recipientForms[index] is defined
    if (newRecipientForms[index]) {
      newRecipientForms[index][field] = value;
      setRecipientForms(newRecipientForms);
      const isValid = newRecipientForms.every((form) => form.name !== "" && form.email !== "");
      onRecipientFormsValidation(isValid);
    }
  };

  const handleSelectorChange = (e) => {
    const selectedValue = e.target.value;
    setShowOwnEmailField(selectedValue === "addMyOwn");
  };

  const handleCustomEmailChange = (e) => {
    setCustomEmail(e.target.value);
    handleFormChange(0, "email", e.target.value);
  };


  // useEffect(() => {
  //   const fetchTeamMembers = async () => {
  //     try {
  //       const AccountID = sessionStorage.getItem("accountID");
  //       const token = sessionStorage.getItem("token");
  //       const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${AccountID}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setTeamMembers(response.data.response);
  //     } catch (error) {
  //       console.error("Error fetching team members:", error);
  //     }
  //   };

  //   fetchTeamMembers();
  // }, []);

  const navigate = useNavigate();

  // const getInitialFormState = (condition) => {
  //   if (condition) {
  //     return {
  //       name: user.name,
  //       notificationType: "Email",
  //       email: user.email,
  //       countryCode: "",
  //       phone: "",
  //       company: "",
  //       signOrder: 1,
  //       signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
  //       signColor: signatoryColor.length > 0 ? signatoryColor[0] : "#ffe987",
  //       expiryDate: null,
  //       isExpiring: false,
  //       isPasswordProtected: false,
  //     };
  //   } else {
  //     return {
  //       name: "",
  //       notificationType: "Email",
  //       email: "",
  //       countryCode: "",
  //       phone: "",
  //       company: "",
  //       signOrder: 1,
  //       signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
  //       signColor: signatoryColor.length > 0 ? signatoryColor[0] : "#ffe987",
  //       expiryDate: null,
  //       isExpiring: false,
  //       isPasswordProtected: false,
  //     };
  //   }
  // };

  // const [recipientForms, setRecipientForms] = useState([getInitialFormState(isChecked)]);
  const [recipientForms, setRecipientForms] = useState([
    {
      name: "",
      notificationType: "Email",
      email: "",
      countryCode: "",
      phone: "",
      company: "",
      signOrder: 1,
      signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
      signColor: signatoryColor.length > 0 ? signatoryColor[0] : "#ffe987",
      expiryDate: null,
      isExpiring: false,
      isPasswordProtected: false,
    },
  ]);


  useEffect(() => {
    if (isChecked) {
      setRecipientForms((prevForms) => {
        return prevForms.map((form, index) => ({
          ...form,
          name: user.fullName,
          email: user.email,
        }));
      });
    }

  }, [isChecked])

  const token = sessionStorage.getItem("token");
  const storedRegisterSignID = localStorage.getItem("registerSignID");


  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=signatureType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const signatureTypeOptions = response.data.response.map((item) => item.name);
          setSignatureTypes(signatureTypeOptions);
          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              signatureType: signatureTypeOptions[0],
            },
          ]);
        }
      })
      .catch((error) => {
        if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=NotificationType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const notificationTypeOptions = response.data.response.map((item) => item.name);
          setNotificationTypes(notificationTypeOptions);

          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              notificationType: "Email",
            },
          ]);
        }
      })
      .catch((error) => {
        if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=CountryCode", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const countryCode = response.data.response.map((code) => code.name);
          setCountryCodes(countryCode);

          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              countryCode: "",
            },
          ]);
        }
      })
      .catch((error) => {
        if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=SignColor", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const formColors = response.data.response.map((color) => color.name);
          setSignatoryColor(formColors);

          setRecipientForms((prevRecipientForms) => [
            {
              ...prevRecipientForms[0],
              signColor: formColors[0],
            },
          ]);

          setFormColorMap({ 0: 0 });
        }
      })
      .catch((error) => {
        if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"), {
            position: "top-right",
          });
        }
      });
  }, []);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/v1.0/api/GetRegisterSignData?RegisterSignID=${storedRegisterSignID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (response.data.successStatus) {
          setIsLoading(false);
          const recipientFormsLength = response.data.response.signatories.length;
          // console.log("length", recipientFormsLength);
          onRecipientFormsLength(recipientFormsLength);

          if (Array.isArray(response.data.response.primaryDocs)) {
            setFormData(response.data.response);
          }
        }
      } catch (error) {
        setIsLoading(false);
        if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
          sessionStorage.removeItem("token");
          localStorage.clear();
          setIsLoading(false);
          navigate("/");
          toast.info(t("uploadDocFlow.sessionExpire"));
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false; // Cleanup function to prevent state updates on unmounted component
    };
  }, [childRender]);

  useEffect(() => {
    setIsLoading(true);
    if (formData && formData.signatories && formData.signatories.length > 0) {
      const signatoryColors = formData.signatories.map((signatory) => signatory.signatureColor);

      const newForms = Array.from({ length: formData.signatories.length }, (_, i) => {
        return {
          ...formData.signatories[i],
          signColor: signatoryColors[i],
        };
      });
      const newColorIndex = newForms.length;
      setCurrentColorIndex(newColorIndex);
      setRecipientForms([
        ...newForms,
        {
          name: "",
          notificationType: "Email",
          email: "",
          countryCode: "",
          phone: "",
          company: "",
          signOrder: formData.signatories.length + 1,
          signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
          signColor: signatoryColor[newColorIndex],
          expiryDate: null,
          isExpiring: false,
          isPasswordProtected: false,
        },
      ]);
    }
    // else {
    //   setRecipientForms([
    //     {
    //       name: "",
    //       notificationType: "Email",
    //       email: "",
    //       countryCode: "",
    //       phone: "",
    //       company: "",
    //       signOrder: 1,
    //       signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
    //       signColor: signatoryColor.length > 0 ? signatoryColor[0] : "#ffe987",
    //       expiryDate: null,
    //       isExpiring: false,
    //       isPasswordProtected: false,
    //     },
    //   ]);
    // }
    setIsLoading(false);
  }, [formData, setIsLoading]);

  useEffect(() => {
    updateFormNumbers();
  }, [recipientForms]);

  const updateFormNumbers = () => {
    const numbers = recipientForms.map((_, index) => index + 1);
    setFormNumbers(numbers);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsOnlySigner(!isChecked);
    console.log(">>>>>>")
  };

  const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      {t("uploadDocFlow.renderTootip")}
    </Tooltip>
  );

  const validateForms = () => {
    const errors = recipientForms.map((form) => {
      const formErrors = {};

      if (!form.name) {
        formErrors.name = t("uploadDocFlow.requiredName");
      }
      if (form.isExpiring && !form.expiryDate) {
        formErrors.expiryDate = t("uploadDocFlow.expiryRequired");
      }

      if (form.notificationType === "Email" || form.notificationType === "All") {
        if (!form.email) {
          formErrors.email = t("uploadDocFlow.emailRequired");
        } else if (!/\S+@\S+\.\S+/.test(form.email)) {
          formErrors.email = "Enter a valid email";
        }
      }

      if (form.notificationType === "SMS" || form.notificationType === "All") {
        if (!form.countryCode) {
          formErrors.countryCode = t("uploadDocFlow.countryRequired");
        }
        if (!form.phone) {
          formErrors.phone = t("uploadDocFlow.phoneRequired");
        } else if (!/^\d+$/.test(form.phone)) {
          formErrors.phone = t("uploadDocFlow.invalidPhoneNum");
        }
      }

      return formErrors;
    });

    const hasValidationErrors = errors.some((formError) => Object.keys(formError).length > 0);

    setValidationErrors(errors);

    const allFormErrorsEmpty = errors.every((formError) => Object.keys(formError).length === 0);

    onRecipientFormsValidation(!hasValidationErrors && allFormErrorsEmpty);

    return !hasValidationErrors && allFormErrorsEmpty;
  };

  const handleCreateRecipient = async (index) => {
    if (!validateForms()) {
      return;
    }

    if (uploadedFiles.length === 0) {
      toast.error(t("uploadDocFlow.uploadFileSignatory"));

      return;
    }
    setCurrentColorIndex(recipientForms.length - 1);

    setIsLoading(true);

    const requestBody = {
      RegisterSignID: registerSignID,
      Name: recipientForms[recipientForms.length - 1].name || user.fullName,
      NotificationType: recipientForms[recipientForms.length - 1].notificationType,
      Email: recipientForms[recipientForms.length - 1].email || user.email,
      CountryCode: recipientForms[recipientForms.length - 1].countryCode,
      phone: recipientForms[recipientForms.length - 1].phone,
      Company: recipientForms[recipientForms.length - 1].company,
      SignOrder: recipientForms.length,
      SignatureType: recipientForms[recipientForms.length - 1].signatureType,
      SignatureColor: signatoryColor[currentColorIndex],
      ExpiryDate: recipientForms[recipientForms.length - 1].expiryDate,
      isExpiring: recipientForms[recipientForms.length - 1].isExpiring,
      isPasswordProtected: recipientForms[recipientForms.length - 1].isPasswordProtected,
    };

    try {
      const response = await axios.post("/v1.0/api/CreateSignatory", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {

        const fetchDataResponse = await axios.get(`/v1.0/api/GetRegisterSignData?RegisterSignID=${storedRegisterSignID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        setFormData(fetchDataResponse.data.response);
        const recipientFormsLength = fetchDataResponse.data.response.signatories.length;
        // console.log("length", recipientFormsLength);
        onRecipientFormsLength(recipientFormsLength);
      }

      toast.success(t("uploadDocFlow.signatoryCreated"));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errorSignatory"));
      }

      return;
    }

    const updatedSignatoryStates = [...signatoryCreatedStates];
    updatedSignatoryStates.push(true);
    setSignatoryCreatedStates(updatedSignatoryStates);

    localStorage.setItem("signatoryCreatedStates", JSON.stringify(updatedSignatoryStates));

    setCurrentColorIndex((currentIndex) => (currentIndex + 1) % signatoryColor.length);
  };

  // const handleFormChange = (index, field, value) => {
  //   console.log("===>dd", value);
  //   const newRecipientForms = [...recipientForms];
  //   newRecipientForms[index][field] = value;
  //   setRecipientForms(newRecipientForms);
  //   const isValid = newRecipientForms.every((form) => form.name !== "" && form.email !== "");

  //   onRecipientFormsValidation(isValid);
  // };

  const handleEditRecipient = async (signatoryID, index) => {
    console.log("signatoryID", signatoryID);
    const updatedFormData = {
      SignatoriesID: signatoryID,
      Name: recipientForms[index].name,
      NotificationType: recipientForms[index].notificationType,
      Email: recipientForms[index].email,
      CountryCode: recipientForms[index].countryCode,
      phone: recipientForms[index].phone,
      Company: recipientForms[index].company,
      SignOrder: recipientForms[index].signOrder,
      SignatureType: recipientForms[index].signatureType,
      SignatureColor: recipientForms[index].signColor,
      ExpiryDate: recipientForms[index].expiryDate,
      isExpiring: recipientForms[index].isExpiring,
      isPasswordProtected: recipientForms[index].isPasswordProtected,
    };

    setIsLoading(true);

    try {
      const response = await axios.put("/v1.0/api/UpdateSignatory", updatedFormData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      toast.success(t("uploadDocFlow.formUpdate"));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errFormUpdate"));
      }
    }
  };

  // confirmation modal
  const handleShowConfirmModal = (signatoriesID, index) => {
    setDeletingSignatoryID(signatoriesID);
    setDeletingIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination || !recipientForms[result.source.index]) {
      return;
    }

    const reorderedForms = Array.from(recipientForms);
    const [removed] = reorderedForms.splice(result.source.index, 1);
    let emptyFormIndex = result.destination.index;
    while (emptyFormIndex < reorderedForms.length && reorderedForms[emptyFormIndex]) {
      emptyFormIndex++;
    }
    if (result.destination.index < emptyFormIndex) {
      reorderedForms.splice(result.destination.index, 0, removed);

      reorderedForms.forEach(async (form, index) => {
        form.signOrder = index + 1;
      });

      setRecipientForms(reorderedForms);
      updateFormNumbers();

      const updatedSignOrder = recipientForms.map((form, index) => ({
        SignatoriesID: form.signatoriesID,
        SignOrder: form.signOrder,
        Name: form.name,
        NotificationType: form.notificationType,
        Email: form.email,
        CountryCode: form.countryCode,
        phone: form.phone,
        Company: form.company,
        SignatureType: form.signatureType,
        SignatureColor: form.signColor,
        ExpiryDate: form.expiryDate,
        IsExpiring: form.IsExpiring,
        isPasswordProtected: form.isPasswordProtected,
      }));

      updateSignOrdersInDatabase(updatedSignOrder);
    }
  };

  const updateSignOrdersInDatabase = async (updatedSignOrders) => {
    try {
      const updatedDataPromises = updatedSignOrders.map(async (updatedFormData) => {
        const response = await axios.put("/v1.0/api/UpdateSignatory", updatedFormData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return response.data;
      });


    } catch (error) {
      if ((error.response.data && error.response.data.statusCode == 2) || (error.response.data && error.response.data.statusCode == 4)) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
  };

  const handleDeleteRecipient = async (signatoryID, index) => {
    setIsLoading(true);

    try {
      const response = await axios.put(
        "/v1.0/api/DeleteSignatory",
        {
          SignatoriesID: signatoryID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        const updatedRecipientForms = [...recipientForms];
        updatedRecipientForms.splice(index, 1);
        setRecipientForms(updatedRecipientForms);

        const updatedSignatoryStates = [...signatoryCreatedStates];
        updatedSignatoryStates.splice(index, 1);
        setSignatoryCreatedStates(updatedSignatoryStates);

        setCurrentColorIndex((currentIndex) => (currentIndex - 1 + signatoryColor.length) % signatoryColor.length);

        toast.success(t("uploadDocFlow.signatoryDeleted"));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if ((error.response.data && error.response.data.statusCode == 2) || (error.response.data && error.response.data.statusCode == 4)) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired", {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.ErrDeletSignatory"));
      }
    } finally {
      handleCloseConfirmModal();
    }
  };

  return (
    <Container className='mb-3 p-0 '>
      <Accordion defaultActiveKey={["10"]} alwaysOpen className='accordion'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            <div className='d-flex align-items-center gap-3'>
              <img alt='IconLoading' src={SignatoryIcon} />
              <h2 className='mb-0'>{t("uploadDocFlow.addSignatory")}</h2>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Form.Group controlId='checkboxField' className='d-flex align-items-center my-2'>
              <Form.Check type='checkbox' label={t("uploadDocFlow.IAMSigner")} checked={isOnlySigner} onChange={handleCheckboxChange} />
              <OverlayTrigger placement='bottom-end' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <Button variant='none' className='info-btn'>
                  <BsFillInfoCircleFill />
                </Button>
              </OverlayTrigger>
            </Form.Group>

            {isLoading && (
              <div className='loader-overlay'>
               <Loader /> 
              </div>
            )}

            <div className='mb-3'></div>
            <div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='droppable' direction='vertical'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {recipientForms &&
                        recipientForms.map((form, index) => {
                          const isSignatoryCreated = signatoryCreatedStates[index];
                          { console.log(isSignatoryCreated, index) }
                          return (
                            <Draggable key={index} draggableId={index.toString()} index={index} bounds='parent' isDragDisabled={!isSignatoryCreated}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    backgroundColor: snapshot.isDragging ? "lightgray" : "white",
                                  }}
                                >
                                  {/* <Form
                                    className='recipient-form d-flex flex-md-row  justify-content-start'
                                    key={index}
                                    index={index}
                                    style={{
                                      borderLeft: `10px solid ${recipientForms[index].signColor}`,
                                      paddingLeft: "10px",
                                    }}
                                  > */}
                                  <Form
                                    className='recipient-form d-flex flex-md-row  justify-content-start'
                                    key={index}
                                    index={index}
                                    style={{
                                      borderLeft: `10px solid ${recipientForms[index].signColor}`,
                                      borderRight: `1px solid ${recipientForms[index].signColor}`,
                                      borderTop: `1px solid ${recipientForms[index].signColor}`,
                                      borderBottom: `1px solid ${recipientForms[index].signColor}`,
                                      paddingLeft: "20px",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <div>
                                      <div className='d-flex  align-items-center justify-content-between'>
                                        <div className='d-flex gap-3 align-items-center'>
                                          <Col md={1} className='drag-handle  me-2' {...provided.dragHandleProps}>
                                            <GrDrag />
                                          </Col>
                                          <div className='temp-form-number-box'>
                                            {t("uploadDocFlow.signatory")}
                                            {formNumbers[index]}
                                          </div>
                                        </div>

                                        {/* Delete Signatory button */}
                                        {form.signatoriesID ? (
                                          <Button variant='' className='deleteBtn m-0' onClick={() => handleShowConfirmModal(form.signatoriesID, index)}>
                                            {t("uploadDocFlow.delete")}
                                          </Button>
                                        ) : null}
                                      </div>

                                      <Col md={11} className='mt-3'>
                                        <Row className='form-fields d-flex flex-row align-items-center '>
                                          <Row>
                                            <Col md={4}>
                                              <Form.Group controlId={`nameField-${index}`}>
                                                <Form.Label>
                                                  {t("uploadDocFlow.name")} <span className='required'>*</span>
                                                </Form.Label>
                                                <Form.Control type='text' value={form.name} onChange={(e) => handleFormChange(index, "name", e.target.value)} />
                                                {validationErrors[index]?.name && <div className='error-message'>{validationErrors[index].name}</div>}
                                              </Form.Group>
                                            </Col>
                                            <Col md={4} className=''>
                                              <Form.Group controlId={`companyField-${index}`}>
                                                <Form.Label>{t("uploadDocFlow.company")}</Form.Label>
                                                <Form.Control type='text' value={form.company} onChange={(e) => handleFormChange(index, "company", e.target.value)} />
                                              </Form.Group>
                                            </Col>

                                            <Col md={4} className=' '>
                                              <Form.Group controlId={`notificationTypeField-${index}`}>
                                                <Form.Label>{t("uploadDocFlow.notificationType")}</Form.Label>
                                                <Form.Select
                                                  name={`notificationType-${index}`}
                                                  value={form.notificationType}
                                                  onChange={(e) => handleFormChange(index, "notificationType", e.target.value)}
                                                >
                                                  {notificationTypes.map((option) => (
                                                    <option key={option} value={option}>
                                                      {option}
                                                    </option>
                                                  ))}
                                                </Form.Select>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            {form.notificationType === "Email" && (
                                              <Col md={4} className='pt-1 mt-2'>
                                                <Form.Group controlId={`emailField-${index}`}>
                                                  <Form.Label>
                                                    {t("uploadDocFlow.email")} <span className='required'>*</span>
                                                  </Form.Label>
                                                  <Form.Control type='email' value={form.email} onChange={(e) => handleFormChange(index, "email", e.target.value)} />
                                                  {validationErrors[index]?.email && <div className='error-message'>{validationErrors[index].email}</div>}
                                                </Form.Group>
                                              </Col>
                                            )}

                                            {form.notificationType === "SMS" && (
                                              <>
                                                <Col md={4} className='pt-1 mt-2'>
                                                  <Form.Group controlId={`countryCodeField-${index}`}>
                                                    <Form.Label>
                                                      {t("uploadDocFlow.country")} <span className='required'>*</span>
                                                    </Form.Label>
                                                    <Form.Control as='select' value={form.countryCode || ""} onChange={(e) => handleFormChange(index, "countryCode", e.target.value)}>
                                                      <option value='' disabled>
                                                        {t("uploadDocFlow.selectCode")}
                                                      </option>
                                                      {countryCodes.map((code) => (
                                                        <option key={code} value={code}>
                                                          {code}
                                                        </option>
                                                      ))}
                                                    </Form.Control>
                                                    {validationErrors[index]?.countryCode && <div className='error-message'>{validationErrors[index].countryCode}</div>}
                                                  </Form.Group>
                                                </Col>
                                                <Col md={4} className='pt-1 mt-2'>
                                                  <Form.Group controlId={`phoneField-${index}`}>
                                                    <Form.Label>
                                                      {t("uploadDocFlow.Phone")}
                                                      <span className='required'>*</span>
                                                    </Form.Label>
                                                    <Form.Control type='tel' value={form.phone} onChange={(e) => handleFormChange(index, "phone", e.target.value)} />
                                                    {validationErrors[index]?.phone && <div className='error-message'>{validationErrors[index].phone}</div>}
                                                  </Form.Group>
                                                </Col>
                                              </>
                                            )}
                                            {form.notificationType === "Email" ||
                                              (form.notificationType === "All" && (
                                                <>
                                                  <Col md={4} className='pt-1 mt-2'>
                                                    <Form.Group controlId={`countryCodeField-${index}`}>
                                                      <Form.Label>
                                                        {t("uploadDocFlow.country")} <span className='required'>*</span>
                                                      </Form.Label>
                                                      <Form.Control as='select' value={form.countryCode || ""} onChange={(e) => handleFormChange(index, "countryCode", e.target.value)}>
                                                        <option value='' disabled>
                                                          {t("uploadDocFlow.selectCode")}
                                                        </option>
                                                        {countryCodes.map((code) => (
                                                          <option key={code} value={code}>
                                                            {code}
                                                          </option>
                                                        ))}
                                                      </Form.Control>
                                                      {validationErrors[index]?.countryCode && <div className='error-message'>{validationErrors[index].countryCode}</div>}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col md={4} className='pt-1 mt-2'>
                                                    <Form.Group controlId={`phoneField-${index}`}>
                                                      <Form.Label>
                                                        {t("uploadDocFlow.Phone")}
                                                        <span className='required'>*</span>
                                                      </Form.Label>
                                                      <Form.Control type='tel' value={form.phone} onChange={(e) => handleFormChange(index, "phone", e.target.value)} />
                                                      {validationErrors[index]?.phone && <div className='error-message'>{validationErrors[index].phone}</div>}
                                                    </Form.Group>
                                                  </Col>

                                                  <Col md={4} className='pt-1 mt-2'>
                                                    <Form.Group controlId={`emailField-${index}`}>
                                                      <Form.Label>
                                                        {t("uploadDocFlow.email")} <span className='required'>*</span>
                                                      </Form.Label>
                                                      <Form.Control
                                                        type='email'
                                                        value={form.email}
                                                        onChange={(e) => handleFormChange(index, "email", e.target.value)}
                                                      />
                                                      {validationErrors[index]?.email && <div className='error-message'>{validationErrors[index].email}</div>}
                                                    </Form.Group>
                                                  </Col>
                                                </>
                                              ))}

                                            <Col md={4} className='checkboxClass gap-3 d-flex align-items-center'>
                                              <Form.Group controlId={`expiryCheckboxField`}>
                                                <Form.Check
                                                  type='checkbox'
                                                  label={t("uploadDocFlow.setExpiryDate")}
                                                  checked={form.isExpiring}
                                                  onChange={(e) => handleFormChange(index, "isExpiring", e.target.checked)}
                                                />
                                              </Form.Group>
                                            </Col>

                                            {form.isExpiring && (
                                              <Col md={3} className='pt-1 mt-2'>
                                                <Form.Group controlId={`expiryDateField-${index}`}>
                                                  <Form.Label>
                                                    {t("uploadDocFlow.setExpiryDate")} <span className='required'>*</span>
                                                  </Form.Label>
                                                  <Form.Control type='date' value={form.expiryDateFormatted} onChange={(e) => handleFormChange(index, "expiryDate", e.target.value)} />
                                                  {validationErrors[index]?.expiryDate && <div className='error-message'>{validationErrors[index].expiryDate}</div>}
                                                </Form.Group>
                                              </Col>
                                            )}

                                            {userAccess.passProtectDocs === true ? (
                                              <>
                                                <Col md={4} className='checkboxClass d-flex  align-items-center'>
                                                  <label className='password-protected-label d-flex align-items-center'>
                                                    <span className='mx-2 password-protected'>{t("uploadDocFlow.passwordProtected")}</span>
                                                    <ReactSwitch
                                                      checked={form.isPasswordProtected}
                                                      onChange={(newChecked) => handleFormChange(index, "isPasswordProtected", newChecked)}
                                                      onColor='#0c9ad7'
                                                      onHandleColor='#ffffff'
                                                      handleDiameter={20}
                                                      uncheckedIcon={false}
                                                      checkedIcon={false}
                                                      height={25}
                                                      width={50}
                                                    />
                                                  </label>
                                                </Col>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </Row>
                                          <Row className='mt-3 d-flex align-items-center'>
                                            <Col md={7}>
                                              <div className='radio-group'>
                                                {signatureTypes.map((option) => (
                                                  <label className='particles-checkbox-container' key={option}>
                                                    <input
                                                      type='radio'
                                                      name={`optionField-${index}`}
                                                      value={option}
                                                      checked={recipientForms[index].signatureType === option}
                                                      onChange={() => handleFormChange(index, "signatureType", option)}
                                                      className='temp-particles-checkbox'
                                                    />
                                                    <span>{option}</span>
                                                  </label>
                                                ))}
                                              </div>
                                            </Col>
                                            <Col md={6} className='recipient-form-buttons mt-3 d-flex flex-row '>
                                              {form.signatoriesID ? (
                                                <>
                                                  <Button variant='' className='applyBtn m-0 mx-2 px-3' onClick={() => handleEditRecipient(form.signatoriesID, index)}>
                                                    {t("uploadDocFlow.save")}
                                                  </Button>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </Col>
                                          </Row>
                                        </Row>
                                      </Col>
                                    </div>
                                  </Form>

                                  {/* Create Signatory Button */}

                                  {!form.signatoriesID && (
                                    <Button variant='none' onClick={() => handleCreateRecipient(index)} className='createBtn m-0'>
                                      <CiCirclePlus size={25} />
                                      {t("uploadDocFlow.AddNewSigner")}
                                    </Button>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            {/* delete signatory Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
              <Modal.Header closeButton>
                <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.sureToDelete")}</Modal.Body>
              <Modal.Footer>
                <Button variant='' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                  {t("uploadDocFlow.cancel")}
                </Button>
                <Button className='applyBtn' variant='none' onClick={() => handleDeleteRecipient(deletingSignatoryID, deletingIndex)}>
                  {t("uploadDocFlow.delete")}
                </Button>
              </Modal.Footer>
            </Modal>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default AddRecipient;
