import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import smsIcon from "../../assets/images/sms-tracking.svg";

const SignatoryOrientation = ({ onOrientationChange, isSerial }) => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    setSelectedOption(isSerial ? "serial" : "parallel");
  }, [isSerial])

  // console.log("selectedOption", selectedOption)

  const handleOptionChange = (event) => {
    const value = event.target.value;
    // console.log('value', value)
    setSelectedOption(value);
    onOrientationChange(value === "serial");
  };

  return (
    <div className='form-group_upload container mb-3 '>
      <div className='d-flex align-items-center gap-3'>
        <img src={smsIcon} alt='IconLoading' />
        <h2 className='m-0' style={{ fontSize: "25px" }}>
          {t("uploadDocFlow.signatoriesOrientation")}
        </h2>
      </div>
      <div className='signatory-orientation'>
        <label>{t("uploadDocFlow.sendEmail")}</label>
        <div className='form-check mx-4 particles-checkbox-container'>
          <input type='radio' className=' temp-particles-checkbox' id='serialRadioButton' name='sendEmailOption' value='serial' checked={selectedOption === "serial"} onChange={handleOptionChange} />
          <label className='form-check-label ms-2' htmlFor='serialRadioButton'>
            {t("uploadDocFlow.serial")}
          </label>
        </div>
        <div className='form-check particles-checkbox-container'>
          <input
            type='radio'
            className=' temp-particles-checkbox'
            id='parallelRadioButton'
            name='sendEmailOption'
            value='parallel'
            checked={selectedOption === "parallel"}
            onChange={handleOptionChange}
          />
          <label className='form-check-label ms-2' htmlFor='parallelRadioButton'>
            {t("uploadDocFlow.parallel")}
          </label>
        </div>
      </div>
    </div>
  );
};

export default SignatoryOrientation;
