import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import JoditEditor from "jodit-react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import rectangleBox from "../../assets/images/rectangle.png";
import { GoDownload } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import Loader from "../../Components/Loader/Loader";

function ManuallyGenerateComponent() {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOPenAILoading, setisOPenAILoading] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const { user, userAccess } = useUser("");

  const [fieldErrors, setFieldErrors] = useState({
    DocumentType: false,
    Name: false,
    ExtraDetails: false,
    CustomDocumentType: false,
  });

  const validateFields = () => {
    const errors = {
      Name: !formData.Name,
    };

    setFieldErrors(errors);

    return !Object.values(errors).some((error) => error);
  };

  const [formData, setFormData] = useState({
    CustomDocumentType: "",
    DocumentType: "",
    ExtraDetails: "",
    Name: "",
  });

  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);
  const location = useLocation();

  const token = sessionStorage.getItem("token");

  const handleCancel = () => {
    setFormData({
      CustomDocumentType: "",
      DocumentType: "",
      ExtraDetails: "",
      Name: "",
    });
    setFieldErrors({});
  };

  const handleUpdateCancel = () => {
    handleCancel();
    setEditorContent("");
    setIsUpdating(false);
    setFieldErrors({});
    setShowDownloadButton(false);
  };

  //update the data
  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const updatedFormData = {
        ...formData,
        IsAIDoc: false,
        AIGeneratedText: editorRef.current.value,
      };

      const response = await axios.post("/v1.0/api/GenerateAIPDFDocAndSave", updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        handleUpdateCancel();
        toast.success(t("macAi.macAiUpdate"));
      }
      setEditorContent("");
    } catch (error) {
      console.error("Error updating document:", error);
      toast.error(t("macAi.macUpdateErr"));
    } finally {
      setIsLoading(false);
    }
  };

  //handle Show data in fields
  useEffect(() => {
    if (location.state && location.state.row) {
      const row = location.state.row;
      setFormData({
        CustomDocumentType: row.documentType === "Other" ? row.documentType : "",
        DocumentType: row.documentType,
        ExtraDetails: row.extraDetails,
        Name: row.name,
        AITextID: row.aiTextID,
        AIGeneratedText: row.aiGeneratedText,
      });
      setEditorContent(row.aiGeneratedText);
      setIsUpdating(true);
    }
  }, [location.state]);

  //textEditor cancel
  const handleEditorCancel = () => {
    setEditorContent("");
    handleCancel();
    setShowDownloadButton(false);
  };

  //save and downlaod call
  const handleSave = async (e) => {
    if (!validateFields()) return;
    if (!editorContent.trim()) {
      toast.info(t("macAi.TextEditorContent"));
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post(
        "/v1.0/api/GenerateAIPDFDocAndSave",
        {
          AITextID: null,
          AIGeneratedText: editorRef.current.value,
          CustomDocumentType: formData.CustomDocumentType,
          DocumentType: formData.DocumentType,
          ExtraDetails: formData.ExtraDetails,
          Name: formData.Name,
          IsAIDoc: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        setFieldErrors("");
        setShowDownloadButton(true);
        // setFormData((prevFormData) => ({ ...prevFormData, AITextID: response.data.response.aiTextID }));
        setFormData((prevFormData) => ({ ...prevFormData, AITextID: response.data.response.aiTextID, generatedDocumentPath: response.data.response.generatedDocumentPath })); // Save the aiTextID and generatedDocumentPath in the form data

        toast.success(t("macAi.generatedDocSave"));
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  //Update the current Text
  const handleUpdateText = async () => {
    try {
      setIsLoading(true);
      const updatedFormData = {
        ...formData,
        AIGeneratedText: editorRef.current.value,
      };

      const response = await axios.post("/v1.0/api/GenerateAIPDFDocAndSave", updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("macAi.macAiUpdate"));
      }
    } catch (error) {
      console.error("Error updating document:", error);
      toast.error(t("macAi.macUpdateErr"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!formData.generatedDocumentPath) {
      toast.error(t("macAi.notDownload"));
      return;
    }
    setIsLoading(true);

    const fileName = formData.Name || getFileNameFromUrl(formData.generatedDocumentPath);
    const downloadedBlob = await downloadURL(formData.generatedDocumentPath);
    const url = URL.createObjectURL(downloadedBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    handleUpdateCancel();
    setIsLoading(false);
    toast.success(t("macAi.downloadSuccess"));
  };

  async function downloadURL(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`${"Error"} ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  function getFileNameFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  return (
    <>
      {user.AccountType === "B" && user.RoleID === 1 && userAccess.remainingDays > 0 ? (
        <>
          <div className='container my-3'>
            {isLoading && (
              <div className='loader-overlay'>
                <Loader />
              </div>
            )}
            {isOPenAILoading && (
              <div className='loader-overlay'>
                <div className='OpenAiloader'></div>
              </div>
            )}
            <Row className='my-3 gap-4 justify-content-center'>
              <Col md={3}>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img src={rectangleBox} alt='Rectangle' />
                  <div className='MACACIIMAGE'>
                    <div>
                      <label>{t("macAi.generatingYourDoc")}</label>
                      <label>{t("macAi.seeHistory")}</label>{" "}
                      <Link to='/manual-generated-documents'>
                        <label className='AidocumentBtn'>{t("macAi.gotoList")}</label>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className='userProfile-form'>
              <>
                <Row className='mt-2'>
                  <Col>
                    <Form.Group className='mt-2' controlId='name'>
                      <Form.Label>{t("macAi.Name")}</Form.Label>
                      <Form.Control type='text' value={formData.Name} onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, Name: e.target.value }))} />
                    </Form.Group>
                    {fieldErrors.Name && <div className='text-danger'>{t("macAi.nameRequired")}</div>}
                  </Col>
                  <Col>
                    <Form.Group className='mt-2' controlId='extraDetail'>
                      <Form.Label>
                        <Form.Label>{t("macAi.ExtraDetail")}</Form.Label>
                      </Form.Label>
                      <Form.Control type='text' value={formData.ExtraDetails} onChange={(e) => setFormData((prevFormData) => ({ ...prevFormData, ExtraDetails: e.target.value }))} />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            </div>

            <div className='userProfile-form mt-3'>
              <JoditEditor
                ref={editorRef}
                value={editorContent}
                config={{
                  placeholder: t("macAi.enterText"),
                  readonly: false,
                  toolbar: true,
                  speechRecognize: false,
                  source: false,
                  fullsize: false,
                  print: false,
                  about: false,
                  height: 300,
                }}
                tabIndex={1}
                onBlur={(newContent) => setEditorContent(newContent)}
              />
            </div>

            {isUpdating ? (
              <div className='d-flex gap-3 mt-3'>
                <Button variant='none' className='ImageDoneBtn' onClick={handleUpdate}>
                  {t("macAi.update")}
                </Button>
                <Button variant='none' className='ImageCancelBtn' onClick={handleUpdateCancel}>
                  {t("macAi.Cancel")}
                </Button>
              </div>
            ) : (
              <>
                <div className='d-flex  gap-3 mt-3'>
                  {showDownloadButton ? (
                    <>
                      <Button variant='none' className='ImageDoneBtn' onClick={handleUpdateText}>
                        {t("macAi.Updatetext")}
                      </Button>
                      <Button variant='none' className='ImageCancelBtn' onClick={handleUpdateCancel}>
                        {t("macAi.Cancel")}
                      </Button>
                      <Button variant='none' className='ImageCancelBtn' onClick={handleDownload}>
                        {t("macAi.docDownload")}
                        <GoDownload className='mx-2' size={22} />
                      </Button>
                    </>
                  ) : (
                    <>
                      {!isUpdating && (formData.Name || formData.ExtraDetails) && (
                        <>
                          <Button className='ImageDoneBtn' onClick={handleSave}>
                            {t("macAi.save")}
                          </Button>
                          <Button className='ImageCancelBtn' onClick={handleEditorCancel}>
                            {t("macAi.clearText")}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default ManuallyGenerateComponent;
