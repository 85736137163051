import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TfiWorld } from "react-icons/tfi";
import NavDropdown from "react-bootstrap/NavDropdown";

function LanguageSelector() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    // Set the direction based on the selected language

    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
  };

  return (
    <Dropdown className='user_settings'>
      <Dropdown.Toggle style={{ padding: "0px", border: "none" }} variant='none' id='dropdownMenuButton4'>
        <div className=''>
          <TfiWorld size={20} />
          <span className='mx-2'>{t("header.language")}</span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage("en")}>{t("languageSelector.english")}</Dropdown.Item>
        <NavDropdown.Divider />
        <Dropdown.Item onClick={() => changeLanguage("ar")}>{t("languageSelector.arabic")}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSelector;
