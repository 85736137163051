import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import "./Home.css";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardCard from "../../Components/DashboardStatuses/DashboardCard";
import RecentDocsHome from "../../Components/DashboardStatuses/Dashboard/RecentDocsHome";
import { useUser } from "../../Context/UserContext";
import amicoimage from "../../assets/images/amico.svg";
import macAihomeicon from "../../assets/images/macAihomeicon.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/profileImage.svg";

const Home = () => {
  const [color, setColor] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const { accountId, user, userAccess } = useUser("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getRecords, setGetRecords] = useState([]);

  const token = sessionStorage.getItem("token");

  const closeViewModal = () => {
    setViewModal(false);
  };

  const fetchCoBrandingData = async () => {
    try {
      if (!accountId) {
        return;
      }
      const response = await axios.get(`/v1.0/api/GetCoBranding?AccountID=${accountId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        setColor(response.data.response.colorTheme || "");
      }
    } catch (error) {
      console.error("Error fetching co-branding data:", error);
    }
  };

  useEffect(() => {
    fetchCoBrandingData();
  }, [accountId, user]);

  useEffect(() => {
    const UserProfile = async () => {
      try {
        const response = await axios.get("Account/UserProfile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        if (error.response.data.statusCode === "2" || error.response.data.statusCode === "4") {
          sessionStorage.removeItem("token");
          localStorage.clear();
          navigate("/");
          toast.info("session expired");
        }
        console.log(error);
      }
    };

    UserProfile();
  }, [token]);

  useEffect(() => {
    const GetRecordCounts = async () => {
      try {
        const response = await axios.get("/v1.0/api/GetRecordCount", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.statusCode === "1") {
          setGetRecords(response.data.response);
        }
      } catch (error) {}
    };

    GetRecordCounts();
  }, []);

  const startButton = () => {
    localStorage.clear();
    navigate("/uploadDoc");
  };

  const viewAccount = () => {
    localStorage.clear();
    setViewModal(true);
  };

  const handleMacAi = () => {
    navigate("/mac-ai");
  };

  const sidebarBackgroundColor = color || "#0979a9";

  return (
    <div className='my-3 gap-3 d-flex container align-items-start'>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* parent container */}

          <div className='main-start-div'>
            {/* main Container */}

            <div className='start-div '>
              <div className='contentDiv'>
                <h3 style={{ color: sidebarBackgroundColor }}>
                  {t("menuButtons.Welcome")} {user.fullName}!
                </h3>
                <p className='start-text'>
                  {t("home.StartSigningDocuments")} <span className='fw-bold'>IamSigner</span>
                </p>

                {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
                  <>
                    <Button style={{ background: sidebarBackgroundColor }} className='btn-start' onClick={viewAccount}>
                      {t("home.StartNow")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button style={{ background: sidebarBackgroundColor }} className='btn-start' onClick={startButton}>
                      {t("home.StartNow")}
                    </Button>
                  </>
                )}
              </div>

              <img src={amicoimage} alt='HomeImage' className='amiconImage' />
            </div>

            {/* homeDesc card ui  */}
            <div className='template-card'>
              <div className='text-center' style={{ padding: "14px 12px" }}>
                <img src={avatar} alt='ImageLoading' />
                <h3 className='truncated-text mb-0'>{user.fullName || "Avatar Loading"}</h3>
              </div>
              <hr className='m-0' />

              <div className='templateSection'>
                {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
                  <>
                    <div>
                      <p className='m-0'> {t("home.Documents")}</p>
                      <h3 className='m-0'>{getRecords.masterDocCount || "0"}</h3>
                    </div>
                    <div>
                      <p className='m-0'> {t("home.Template")}</p>
                      <h3 className='m-0'>{getRecords.templateCount || "0"}</h3>
                    </div>
                    <div>
                      <p className='m-0'> {t("home.Folder")}</p>
                      <h3 className='m-0'>{getRecords.folderCount || "0"}</h3>
                    </div>
                  </>
                ) : (
                  <>
                    <Link to='/recentdocuments' style={{ textDecoration: "none", color: "black" }}>
                      <div>
                        <p className='m-0'> {t("home.Documents")}</p>
                        <h3 className='m-0'>{getRecords.masterDocCount || "0"}</h3>
                      </div>
                    </Link>
                    <Link to='/templatesPage' style={{ textDecoration: "none", color: "black" }}>
                      <div>
                        <p className='m-0'> {t("home.Template")}</p>
                        <h3 className='m-0'>{getRecords.templateCount || "0"}</h3>
                      </div>
                    </Link>
                    <Link to='/all-folders' style={{ textDecoration: "none", color: "black" }}>
                      <div>
                        <p className='m-0'> {t("home.Folder")}</p>
                        <h3 className='m-0'>{getRecords.folderCount || "0"}</h3>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>

          <DashboardCard />
        </div>

        <div className='showGenerateAi'>
          <div className='macAIHomeConditional'>
            <div className='content'>
              <div className='d-flex mb-1 align-items-center gap-3'>
                <img src={macAihomeicon} alt='AIiconLoading' style={{ height: "40px", width: "40px" }} />
                <p style={{ fontSize: "20px" }}>{t("home.macAi")}</p>
              </div>
              <p>
                {t("home.needHelp")} <br /> {t("home.yourDocument")}
              </p>

              {(user.AccountType === "B" && user.RoleID === 3) || (user.AccountType === "I" && user.RoleID === 1) || userAccess.remainingDays <= 0 ? null : (
                <Button as={Link} to='/mac-ai' variant='none' className='MacAiButton'>
                  {t("home.generate")}
                </Button>
              )}
            </div>
          </div>
        </div>

        <RecentDocsHome />
      </div>

      <Modal show={viewModal} onHide={closeViewModal} centered dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("home.activateUser")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center p-2'>
          <p>{t("home.OnlyView")}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Home;
