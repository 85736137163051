
// import { createContext, useContext, useState, useEffect } from "react";
// import axios from "../Api/api";
// import userImg from "../assets/images/defaultImg.svg";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

// const UserContext = createContext();

// export const useUser = () => {
//   return useContext(UserContext);
// };

// export const UserProvider = ({ children }) => {
//   const navigate = useNavigate();
//   const [loading, setIsLoading] = useState(true);
//   const [accountId, setAccountId] = useState(null);
//   const [profile, setProfile] = useState(userImg);
//   const [emailId, setEmailId] = useState();
//   const [accountType, setAccountType] = useState();
//   const [user, setUser] = useState({
//     fullName: "",

//     email: "",
//     profilePic: "",
//     AccountID: "",
//     RoleID: "",
//     isDropbox: false,
//     isGoogleDrive: false,
//     isOneDrive: false,
//   });
//   const [userAccess, setUserAccess] = useState("");

//   const token = sessionStorage.getItem("token");

//   const GetUserAccess = async (AccountID) => {
//     try {
//       if (AccountID) {
//         return;
//       }
//       const response = await axios.get(`/v1.0/api/GetUserAccess?AccountID=${AccountID}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log("data==>", response.data.response);
//       if (response.data.statusCode === "1") {
//         setUserAccess(response.data.response);
//       }
//     } catch (error) {}
//   };

//   const fetchUserProfile = async () => {
//     try {
//       const response = await axios.get("/Account/UserProfile", {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (response.data.statusCode === "1") {
//         const { fullName, emailID, userPhotoPath, userPhoto, isDropbox, isGoogleDrive, isOneDrive, accountID, roleID, accountType } = response.data.response;
//         setAccountId(accountID);
//         setUser((prevUser) => ({
//           ...prevUser,
//           fullName,
//           email: emailID,
//           profilePic: userPhotoPath,
//           isDropbox: isDropbox,
//           isGoogleDrive: isGoogleDrive,
//           isOneDrive: isOneDrive,
//           AccountID: accountID,
//           RoleID: roleID,
//           AccountType: accountType,
//         }));

//         const ProfileImage = userPhoto ? userPhotoPath : userImg;
//         setProfile(ProfileImage);
//         setAccountType(accountType);

//         setAccountId(accountID);
//         setIsLoading(false);
//         setEmailId(emailID);
//       }
//     } catch (error) {
//       if ((error.response.data && error.response.data.statusCode === "2") || (error.response.data && error.response.data.statusCode === "4")) {
//         sessionStorage.removeItem("token");
//         localStorage.clear();
//         setIsLoading(false);
//         navigate("/");
//         toast.info("session expired");
//       }
//       console.error("Error fetching user profile:", error);
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     GetUserAccess();
//     fetchUserProfile();
//   }, [token]);

//   return <UserContext.Provider value={{ user, setUser, loading, accountId, profile, emailId, accountType }}>{children}</UserContext.Provider>;
// };

import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "../Api/api";
import userImg from "../assets/images/defaultImg.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const [accountId, setAccountId] = useState(null);
  const [profile, setProfile] = useState(userImg);
  const [emailId, setEmailId] = useState();
  const [accountType, setAccountType] = useState();
  const [user, setUser] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    profilePic: "",
    AccountID: "",
    RoleID: "",
    isDropbox: false,
    isGoogleDrive: false,
    isOneDrive: false,
  });
  const [userAccess, setUserAccess] = useState("");
  const token = sessionStorage.getItem("token");

  const GetUserAccess = async (AccountID) => {
    if (!AccountID) {
      return;
    }
    try {
      const response = await axios.get(`/v1.0/api/GetUserAccess?AccountID=${AccountID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        setUserAccess(response.data.response);
      }
    } catch (error) {
      console.error("Error fetching user access:", error);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get("/Account/UserProfile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const { fullName, countryCode, mobileNumber, emailID, userPhotoPath, userPhoto, isDropbox, isGoogleDrive, isOneDrive, accountID, roleID, accountType } = response.data.response;
        const mobile = `${countryCode}-${mobileNumber}`;
        setUser((prevUser) => ({
          ...prevUser,
          fullName,
          mobileNumber: mobile,
          email: emailID,
          profilePic: userPhoto ? userPhotoPath : userImg,
          isDropbox,
          isGoogleDrive,
          isOneDrive,
          AccountID: accountID,
          RoleID: roleID,
          AccountType: accountType,
        }));

        const ProfileImage = userPhoto ? userPhotoPath : userImg;
        setProfile(ProfileImage);
        setAccountType(accountType);
        setAccountId(accountID);
        setIsLoading(false);
        setEmailId(emailID);

        // After setting accountId, fetch user access
        GetUserAccess(accountID);
      }
    } catch (error) {
      if (error.response && (error.response.data.statusCode === "2" || error.response.data.statusCode === "4")) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      }
      console.error("Error fetching user profile:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [token]);

  return <UserContext.Provider value={{ user, setUser, loading, accountId, profile, emailId, accountType, userAccess }}>{children}</UserContext.Provider>;
};
