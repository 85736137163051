import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TemplatesConfirmationModal = ({ show, handleClose, handleConfirm, title, button }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose} dialogClassName='custom-modal-width' backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3 py-2'>{t("templateConfirmationModal.proceed")}</Modal.Body>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "10px 0px" }}>
        <Button variant='none' className='modalCancelButton' onClick={handleClose}>
          {t("templateConfirmationModal.cancel")}
        </Button>
        <Button variant='none' className='applyBtn' onClick={handleConfirm}>
          {t("templateConfirmationModal.yes")}, {button}
        </Button>
      </div>
    </Modal>
  );
};

export default TemplatesConfirmationModal;
