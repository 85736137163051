import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuditTrail from "./AuditTrail";

const AuditTrailModal = ({ show, handleClose, auditTrailURL }) => {
  const { t } = useTranslation();

  useEffect(() => {
    //console.log(targetRef);
  });

  return (
    <Modal show={show} onHide={handleClose} className="overflow-hidden">
      <Modal.Header closeButton className='btn'>
        <Modal.Title>{t("auditTrailModal.auditTrail")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: "auto", background: "white" }}>
        <iframe title={t("auditTrailModal.auditTrail")} src={auditTrailURL} width='100%' height="800px" className="bg-white " />
      </Modal.Body>
    </Modal>
  );
};

export default AuditTrailModal;
