import React, { useState } from "react";
import axios from "../../Api/api";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./templates.css";
import Loader from "../../Components/Loader/Loader";

const EditTemplateNameModal = ({ originalTemplateName, handleClose, templateID, onSave }) => {
  const { t } = useTranslation();
  const [editedTemplateName, setEditedTemplateName] = useState(originalTemplateName);
  const [isLoading, setIsLoading] = useState(false);

  const token = sessionStorage.getItem("token");

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/UpdateTemplate",
        {
          TemplateID: templateID,
          TemplateName: editedTemplateName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("editTemplateNameModal.updateTempNameSuccess"));
        onSave(editedTemplateName);
        handleClose();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(t("editTemplateNameModal.errUpdateTempNameSuccess"));
    }
  };
  const isDisabled = editedTemplateName.trim() === ""; // Check if the editedTemplateName is empty

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
         <Loader />
        </div>
      )}

      <Modal show={true} onHide={handleClose} backdrop='static' keyboard={false} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("editTemplateNameModal.editTemplateName")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}>
          <Form.Group controlId='formEditedTemplateName'>
            <Form.Label>{t("editTemplateNameModal.templateName")} : </Form.Label>
            <Form.Control type='text' value={editedTemplateName} onChange={(e) => setEditedTemplateName(e.target.value)} />
          </Form.Group>

          {/* <Modal.Footer> */}
          <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "10px 0px" }}>
            <Button variant='none' className='modalCancelButton' onClick={handleClose}>
              {t("editTemplateNameModal.cancel")}
            </Button>
            <Button variant='none' className='applyBtn' disabled={isDisabled} onClick={handleSave}>
              {t("editTemplateNameModal.save")}
            </Button>
          </div>
        </Modal.Body>
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default EditTemplateNameModal;
