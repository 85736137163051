import React, { useState, useEffect } from "react";
import { AiOutlineDelete, AiOutlineDownload, AiOutlineEdit, AiOutlineFileText } from "react-icons/ai";
import UploadDocIcon from "../../assets/images/Paper Plus.svg";
import { BiErrorCircle } from "react-icons/bi";
import { Button, Col, Container, Form, InputGroup, ListGroup, Modal, Row, Dropdown } from "react-bootstrap";
import { useUser } from "../../Context/UserContext";
import "./Thirdstep.css";
import { toast } from "react-toastify";
import axios from "../../Api/api";
import PeopleImage from "../../assets/images/people.png";
import { useNavigate } from "react-router-dom";
import CCEmail from "./CCEmail";
import { useTranslation } from "react-i18next";
import uploadLocalIcon from "../../assets/images/monitorUpload.svg";
import ViewFileIcon from "../../assets/images/Show.png";
import DeleteIcon from "../../assets/images/Delete.svg";
import editIcon from "../../assets/images/Edit.svg";
import pdfFileIcon from "../../assets/images/pdfFile.svg";
import commentIcon from "../../assets/images/sendIcon.svg";
import CustomToggle from "../CustomDots/CustomToggle";
import Loader from "../Loader/Loader";

const Thirdstep = ({ setActiveStep, setCompletedSteps }) => {
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");
  const storedRegisterSignID = localStorage.getItem("registerSignID");
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedText, setEditedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [docCommentsIDs, setDocCommentsIDs] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [commentToDeleteIndex, setCommentToDeleteIndex] = useState(null);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [supportingDocIDs, setSupportingDocIDs] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletingSupportingDocID, setDeletingSupportingDocID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [documentPath, setDocumentPath] = useState(null);
  const [isSupported, setIsSupported] = useState();
  const { user, userAccess } = useUser();

  console.log(user.profilePic)

  const handleFileUpload = async (event) => {
    const allowedFormats = [
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/plain",
    ];

    const newFiles = Array.from(event.target.files);

    const allowedFiles = newFiles.filter((file) => allowedFormats.includes(file.type));

    if (allowedFiles.length === 0) {
      setErrorMessage(t("uploadDocFlow.fileFormatSupport"));
      setIsLoading(false);
    } else {
      setErrorMessage("");
      const base64Files = [];
      for (const file of allowedFiles) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const base64Data = e.target.result.split(",")[1];
          base64Files.push({
            name: file.name,
            base64: base64Data,
            MasterDocID: null,
          });

          if (base64Files.length === allowedFiles.length) {
            const uploadedFileNames = uploadedFiles.map((fileName) => fileName);

            const duplicateFiles = base64Files.filter((allowedfile) => uploadedFileNames.includes(allowedfile.name));

            if (duplicateFiles.length > 0) {
              setErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
              toast.error(t("uploadDocFlow.filewithSameNameExits"));
              setIsLoading(false);
            } else {
              const fileExtension = file.name.split(".").pop();

              const requestBody = {
                CreatedBy: user.email,
                registerSignID: storedRegisterSignID,
                MasterDocID: null,
                SupportingDocName: file.name,
                SupportingDocExtension: fileExtension,
                SupportingDocFile: base64Data,
              };

              //console.log(requestBody);

              setIsLoading(true);
              try {
                const response = await axios.post("/v1.0/api/UploadSupportngDoc", requestBody, {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                });

                if (response.data.successStatus) {
                  setUploadedFiles([...uploadedFiles, ...base64Files.map((allowFile) => allowFile.name)]);

                  setSupportingDocIDs((prevIDs) => [...prevIDs, response.data.response.supportingDocID]);
                  toast.success(t("uploadDocFlow.DocUploadedsuccess"));
                  setIsLoading(false);
                } else {
                  toast.error(t("uploadDocFlow.failedUpload"));
                  setIsLoading(false);
                }
              } catch (error) {
                if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
                  sessionStorage.removeItem("token");
                  localStorage.clear();
                  setIsLoading(false);
                  navigate("/");
                  toast.info(t("uploadDocFlow.sessionExpire"));
                } else {
                  toast.error(t("uploadDocFlow.failedUpload"));
                }
                setIsLoading(false);
              }
            }
          }
        };

        reader.readAsDataURL(file);

        reader.onerror = (error) => {
          isLoading(false);
        };
      }
    }
  };

  useEffect(() => { }, [uploadedFiles, supportingDocIDs, userAccess]);

  useEffect(() => {
    const apiUrl = `/v1.0/api/GetSupportingDocs?RegisterSignID=${storedRegisterSignID}`;
    setIsLoading(true);
    try {
      axios
        .get(apiUrl, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (Array.isArray(response.data.response)) {
              const uploadedDocs = response.data.response.map((doc) => doc.supportingDocName);
              setUploadedFiles(uploadedDocs);

              const supportingID = response.data.response.map((doc) => doc.supportingDocID);
              setSupportingDocIDs(supportingID);
            }
          }
        });
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFileview = async (supportingDocID) => {
    try {
      const response = await axios.get(`/v1.0/api/GetSingleSupportingDoc?SupportingDocID=${supportingDocID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setDocumentPath(response.data.response.supportingDocPath);
      const documentExtension = response.data.response.supportingDocPath.split(".").pop(); // Extract the extension

      if (documentExtension === "docx" || documentExtension === "xlsx" || documentExtension === "ppt") {
        setIsSupported(false);
      } else {
        setIsSupported(true);
      }

      setShowModal(true);
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.filecantView"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDownload = (file) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = file;
    downloadLink.download = "file";
    downloadLink.click();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDocumentPath("");
  };

  const DeleteFileModal = (supportingDocID, index) => {
    setDeletingSupportingDocID(supportingDocID);
    setDeletingIndex(index);
    setFileModal(true);
  };

  useEffect(() => { }, [deletingIndex, deletingSupportingDocID]);

  const handleFileCloseModal = () => {
    setFileModal(false);
  };

  const handleFileDelete = async (supportingDocID, index) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteSupportingDoc",
        {
          SupportingDocID: supportingDocID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        // Remove the file at the specified index from the frontend state
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles.splice(index, 1);
        setUploadedFiles(updatedUploadedFiles);

        // Remove the corresponding masterDocID from the frontend state
        const updatedsupportingDocIDs = [...supportingDocIDs];
        updatedsupportingDocIDs.splice(index, 1);
        setSupportingDocIDs(updatedsupportingDocIDs);

        setIsLoading(false);
      }

      toast.success(t("uploadDocFlow.fileDeleted"));
    } catch (error) {
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
      toast.error(t("uploadDocFlow.errdeleteFile"));
      setIsLoading(false);
    } finally {
      handleFileCloseModal();
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // comment section
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);

    if (e.key === "Enter") {
      if (!e.target.value) {
        // setErrorMessage(t("uploadDocFlow.enterComment"));
        toast.error(t("uploadDocFlow.enterComment"));
        return;
      }
      setErrorMessage("");

      addComment(e);
    }
  };

  const addComment = async () => {
    if (!newComment) {
      // setErrorMessage(t("uploadDocFlow.enterComment"));
      toast.error(t("uploadDocFlow.enterComment"));
      return;
    }

    setErrorMessage("");
    const requestBody = {
      Comments: newComment,
      AdhockUserID: "3c9a2b68-f820-4fd2-a32a-5eec2e5f00cd",
      CommentPostedBy: user.fullName,
      CreatedBy: user.email,
      MasterDocID: null,
      RegisterSignID: storedRegisterSignID,
    };

    setIsLoading(true);

    try {
      const response = await axios.post("/v1.0/api/CreateDocComments", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status === 200) {
        const currentDate = new Date();
        setComments([
          ...comments,
          {
            text: newComment,
            time: `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`,
            date: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
            edited: false,
          },
        ]);
        setDocCommentsIDs([...docCommentsIDs, response.data.response]);

        setNewComment("");
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentAdded"));
      } else {
        setIsLoading(false);
        toast.error(t("uploadDocFlow.failedToAdd"));
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.failedToAdd"));
      }
    }
  };

  const handleDeleteComment = async (docCommentsID, index) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteDocComments",
        { DocCommentsID: docCommentsID },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.status === 200) {
        const updatedComments = [...comments];
        updatedComments.splice(index, 1);
        const updatedDocCommentsIDs = [...docCommentsIDs];
        updatedDocCommentsIDs.splice(index, 1);
        setComments(updatedComments);
        setDocCommentsIDs(updatedDocCommentsIDs);
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentDeleted"));
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      } else {
        toast.error(t("uploadDocFlow.failedTodelete"));
      }
    } finally {
      setShowConfirmModal(false);
    }
  };

  const toggleEdit = (index) => {
    setEditingIndex(index);
    setEditedText(comments[index].text);
  };

  const saveEditedComment = async (index) => {
    const docCommentsID = docCommentsIDs[index];
    const updatedText = editedText;

    const requestBody = {
      DocCommentsID: docCommentsID,
      Comments: updatedText,
    };

    setIsLoading(true);
    try {
      const response = await axios.put("/v1.0/api/UpdateDocComments", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 200) {
        const updatedComments = [...comments];
        updatedComments[index].text = updatedText;
        updatedComments[index].edited = true;
        updatedComments[index].timestamp = new Date().toLocaleString();
        setComments(updatedComments);
        setEditingIndex(-1);
        setIsLoading(false);
        toast.success(t("uploadDocFlow.commentUpdate"));
      } else {
        toast.error(t("uploadDocFlow.failedToUpdate"));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      } else {
        toast.error(t("uploadDocFlow.failedToUpdate"));
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      axios
        .get(`/v1.0/api/GetDocComments?RegisterSignID=${storedRegisterSignID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data.response;
            const allComments = responseData.map((comment) => ({
              text: comment.comments,
              date: comment.createdDate,
              time: comment.createdTime,
              edited: comment.modifiedOn ? true : false,
            }));
            setComments(allComments);
            setDocCommentsIDs(responseData.map((comment) => comment.docCommentsID));
          }
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  // comments delete confirmation modal
  const handleShowConfirmModal = (docCommentsID, index) => {
    setDeletingCommentId(docCommentsID);
    setCommentToDeleteIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  // send and sign button modalss

  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    localStorage.clear();
    navigate("/home");
  };

  const handleYesButton = async () => {
    closeConfirmationModal();
    setIsLoading(true);

    const requestBody = {
      RegisterSignID: storedRegisterSignID,
      MasterDocID: "e00a548a-d73d-4a00-8263-7e71664bb75c",
      SignatoriesID: "474386bf-dac6-4957-932f-80185c12493e",
      ActivityStatus: "GenerateLink",
      Remarks: "Remarks",
    };

    try {
      const response = await axios.post("/v1.0/api/SendAndSign", requestBody, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        openSuccessModal();
        setIsLoading(false);
      }

      if (response.data.response.isSuccess) {
        const StepData = {
          SignWorkFlowActivityName: "EmailSent",
          SignWorkFlowActivityRef: "",
          SignWorkFlowActivityClass: "RED",
          RegisterSignID: storedRegisterSignID,
          IsMobile: false,
        };

        try {
          setIsLoading(true);
          const response = await axios.post("/v1.0/api/CreateWorkFlowActivity", StepData, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          setCompletedSteps(prevSteps => [...prevSteps, 3]);

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
            sessionStorage.removeItem("token");
            localStorage.clear();
            setIsLoading(false);
            navigate("/");
            toast.info(t("uploadDocFlow.sessionExpire"));
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something wrong. Try later");

      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    }
  };

  const handleBack = async () => {
    const StepData = {
      SignWorkFlowActivityName: "Place Signatories",
      SignWorkFlowActivityRef: "2",
      SignWorkFlowActivityClass: "RED",
      RegisterSignID: storedRegisterSignID,
      IsMobile: false,
    };

    try {
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateWorkFlowActivity", StepData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setIsLoading(false);

      if (response.data.successStatus) {
        setActiveStep(2);
        setCompletedSteps([1]);
      } else {
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.statusCode == 2 || error.response.data.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    }
  };

  return (
    <Container className='upload-container-thirdStep'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='upload_body' id='part_one'>
        <Container>
          {userAccess.supportDocs === true ? (
            <>
              <div style={{ padding: "15px", backgroundColor: "#FFF", borderRadius: "10px", marginTop: "1rem" }}>
                <div className='d-flex align-items-center gap-3 my-3'>
                  <img src={UploadDocIcon} alt='iconLoading' />
                  <h4 className='m-0'>{t("uploadDocFlow.supportingDocuments")}</h4>
                </div>
                <Row>
                  <Col md={4}>
                    <div className='uplad_box'>
                      <Form.Group controlId='file-input-add' className='file-upload_temp'>
                        <div className='upload_trigger_bx' onClick={() => document.querySelector(".temp_file-input-additional").click()}>
                          <img alt='iconLoading' src={uploadLocalIcon} />
                          <br />
                          <Form.Label>{t("uploadDocFlow.supportedDoc")}</Form.Label>
                          <Form.Control
                            className='temp_file-input-additional d-none'
                            type='file'
                            name='file'
                            accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
                            onChange={handleFileUpload}
                            multiple
                            style={{ display: "none" }}
                          />
                          <div className='upload_doc_part'>
                            <p>{t("uploadDocFlow.selectFilesToUpload")}</p>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>

                <br />
                {errorMessage && (
                  <p className='error-message'>
                    <BiErrorCircle className='mb-1 mx-2' />
                    {errorMessage}
                  </p>
                )}
                <div className='upload_file_box' id='upload_file_bx '>
                  {uploadedFiles.map((fileName, index, file) => (
                    <div key={index} className='uploaded-file d-flex align-items-center justify-content-between px-3 border-bottom '>
                      <p className='m-0 d-flex align-items-center gap-2'>
                        <img src={pdfFileIcon} alt='iconLoading' />
                        {fileName}
                      </p>
                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle}>
                          <Dropdown.Menu>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileview(supportingDocIDs[index])}>
                              <img src={ViewFileIcon} alt='iconLoading' />
                              {t("uploadDocFlow.view")}
                            </Dropdown.Item>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => DeleteFileModal(supportingDocIDs[index], index)}>
                              <img src={DeleteIcon} alt='iconLoading' />
                              {t("uploadDocFlow.delete")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Toggle>
                      </Dropdown>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* file delete modal */}
          <Modal show={fileModal} onHide={handleFileCloseModal} dialogClassName='custom-modal-width'>
            <Modal.Header closeButton>
              <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteFile")}</Modal.Body>
            <Modal.Footer>
              <Button variant='' onClick={handleFileCloseModal} className='modalCancelButton'>
                {t("uploadDocFlow.cancel")}
              </Button>
              <Button variant='' className='applyBtn' onClick={() => handleFileDelete(deletingSupportingDocID, deletingIndex)}>
                {t("uploadDocFlow.delete")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t("uploadDocFlow.viewFile")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isSupported ? (
                <iframe title='Document Viewer' src={documentPath} alt='cant preview file' width='100%' height='500' className='overflow-x-none'></iframe>
              ) : (
                <p>
                  {t("uploadDocFlow.previewfile")}
                  <Button className='applyBtn' variant='none' onClick={() => handleFileDownload(documentPath)}>
                    <AiOutlineDownload size={18} />
                  </Button>
                </p>
              )}
            </Modal.Body>
          </Modal>

          <CCEmail />

          {/* Comment Input */}

          <div className='ThirdpaginationDivs '>
            <Container className='comments-container'>
              <h2 className='ms-0'>{t("uploadDocFlow.comment")}</h2>

              <InputGroup className='add-Comment mb-3'>
                <Form.Control type='text' placeholder={t("uploadDocFlow.addComment")} value={newComment} onKeyDown={handleCommentChange} onChange={handleCommentChange} />
                <img src={commentIcon} alt='iconLoading' className='comment-button' onClick={addComment} />
              </InputGroup>
              {/* {errorMessage && (
                <p className='error-message'>
                  {" "}
                  <BiErrorCircle className='mb-1 mx-2' />
                  {errorMessage}
                </p>
              )} */}

              {/* List of Comments */}
              <ListGroup>
                {comments.map((comment, index) => (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col lg={1} sm={2} className='pe-0'>
                        <img src={user.profilePic ? user.profilePic : PeopleImage} roundedCircle className='avatar' alt="User's avatar" />
                      </Col>
                      <Col lg={8} md={6} className='ps-0 '>
                        {editingIndex === index ? (
                          // Editing mode
                          <>
                            <Form.Control type='text' value={editedText} onChange={(e) => setEditedText(e.target.value)} />
                            <Button variant='' className='applyBtn mt-3' onClick={() => saveEditedComment(index)}>
                              {t("uploadDocFlow.save")}
                            </Button>
                          </>
                        ) : (
                          // Viewing mode
                          <>
                            <div className='comment-body'>
                              <div className='comment-header'>
                                {user.fullName}
                                <span className='timestamp'>
                                  {comment.date}
                                  <span className='mx-2'></span>
                                  {comment.time}
                                </span>
                                {comment.edited && <span className='edited-label mx-2 fw-normal'>edited</span>}
                              </div>
                              <div className='comment-text'>{comment.text}</div>
                            </div>
                          </>
                        )}
                      </Col>
                      <Col md={2}>
                        {editingIndex !== index ? (
                          // Not in editing mode
                          <>
                            <Button variant='' className='edit-button' onClick={() => toggleEdit(index)}>
                              <img src={editIcon} alt='iconLoading' />
                            </Button>
                            <Button variant='' className='' onClick={() => handleShowConfirmModal(docCommentsIDs[index], index)}>
                              <img src={DeleteIcon} alt='iconLoading' />
                            </Button>
                          </>
                        ) : // In editing mode
                          null}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
                <Modal.Header closeButton>
                  <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteComment")}</Modal.Body>
                <Modal.Footer>
                  <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                    {t("uploadDocFlow.cancel")}
                  </Button>
                  <Button variant='none' className='applyBtn' onClick={() => handleDeleteComment(deletingCommentId, commentToDeleteIndex)}>
                    {t("uploadDocFlow.delete")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Container>
          </div>

          <div className=' fixed-button-container'>
            <Button className='backBtn' id='hide_part_one' onClick={handleBack}>
              {t("uploadDocFlow.back")}
            </Button>
            <Button className='Continue-btn' id='hide_part_one' onClick={openConfirmationModal}>
              {t("uploadDocFlow.sendAndSign")}
            </Button>
          </div>

          {/* Confirmation Modal */}
          <Modal show={isConfirmationModalOpen} onHide={closeConfirmationModal} dialogClassName='custom-modal-width'>
            <Modal.Header closeButton>
              <Modal.Title>{t("uploadDocFlow.confirmSend")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.proceed")}</Modal.Body>
            <Modal.Footer>
              <Button onClick={closeConfirmationModal} variant='' className='modalCancelButton'>
                {t("uploadDocFlow.no")}
              </Button>
              <Button onClick={handleYesButton} className='px-4 applyBtn'>
                {t("uploadDocFlow.yes")}
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Success Modal */}
          <Modal show={isSuccessModalOpen} onHide={closeSuccessModal} dialogClassName='custom-modal-width'>
            <Modal.Header closeButton>
              <Modal.Title>{t("uploadDocFlow.requestSent")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.requestProceed")}</Modal.Body>
            <Modal.Footer>
              <Button onClick={closeSuccessModal} className='applyBtn'>
                {t("uploadDocFlow.close")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>


    </Container>
  );
};

export default Thirdstep;
